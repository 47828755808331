import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { isEmpty } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import EmployeesContainer from '../Employees/EmployeesContainer';
import Wrapper from '../Core/Wrapper'
import SelectInput from '../Core/SelectInput'
import TimeTracker from '../Core/TimeTracker'
import { getTodaysRunningTimesheets } from '../utils/calls'
import { getStartAndEndOfDay } from '../utils/helperFunctions'
import { Form } from 'react-final-form';
import styled from 'styled-components'
import CallToAction from '../Core/CallToAction'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import TasksContainer from '../Tasks/TasksContainer';
import ClientsContainer from '../Clients/ClientsContainer';
import ProposalsContainer from '../Proposals/ProposalsContainer';
import useScreenSize from '../context/useScreenSize';
import ExpandedContainer from '../Expanded/ExpandedContainer';
import RunningTimeSheets from './RunningTimeSheets'

const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    form {
        align-self: flex-end;
        .TimeTracker {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            .time {
                font-family: ${colors.roboto};
            }
        }
    }
`;


const WelcomeAdminContainer = ({
    projects,
    company,
    user,
    projectId,
    employees,
    employeesIsLoading,
    fetchCompanyEmployees,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    setInventoryColumns,
    initialProject,
    handleChange,
    isRunning,
    fetchUserDetails,
    isCompanyPremium,
    handleToggleChat,
    showHelp,
    handleSendMessage,
    setInventory,
    userRole,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchAllClients,
    clients,
    clientsIsLoading,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    fetchCompanyProjects
}) => {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [expanded, setExpanded] = useState('');
    const [isOpen, setIsOpen] = useState(false);


    const [runningTimesheets, setRunningTimesheets] = useState([])

    const fetchRunningTimesheet = async () => {
        try {
            const { startOfToday, endOfToday } = getStartAndEndOfDay();

            const res = await getTodaysRunningTimesheets({
                startOfToday,
                endOfToday
            });
            if (res.status === 200) {
                setRunningTimesheets(res.data?.timesheets)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        // Fetch initially
        fetchRunningTimesheet();

        // Set up the interval to run every minute (60000 milliseconds)
        const intervalId = setInterval(() => {
            fetchRunningTimesheet();
        }, 60000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <StyledDiv
            className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}
        >
            <Wrapper
                width={isDesktop ? '50%' : '100%'}
                flex={'1'}
                padding={isDesktop && '0 15px 0 0'}
                borderRight={isDesktop && `
                ${theme === 'dark' ? colors.backgroundSecondary : colors.black} 1px solid
            `
                }>
                <EmployeesContainer
                    employees={employees}
                    userRole={userRole}
                    user={user}
                    projects={projects}
                    company={company}
                    fetchCompanyEmployees={fetchCompanyEmployees}
                    title={text?.dashboard?.employees}
                    isDashboard
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

                <hr />
                <TasksContainer
                    projects={projects}
                    adminProjects={projects}
                    user={user}
                    employees={employees}
                    tasks={tasks}
                    tasksIsLoading={tasksIsLoading}
                    fetchProjectTasks={fetchProjectTasks}
                    title={text?.dashboard?.tasks}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

                <hr />

                <ProposalsContainer
                    title={text?.homeLandingContainer?.services?.proposals?.title}
                    adminProjects={projects}
                    projects={projects}
                    user={user}
                    clients={clients}
                    proposals={proposals}
                    proposalsIsLoading={proposalsIsLoading}
                    fetchAllProposals={fetchAllProposals}
                    isAdmin
                    setExpanded={setExpanded}
                    expanded={expanded}
                />
                <hr />
            </Wrapper>

            <Wrapper
                width={isDesktop ? '50%' : '100%'}
                flex={'1'}
                padding={isDesktop && '0 0 0 15px'}
            >
                <ConditionalRender renderIf={isDesktop}>

                    {/* Time logs */}
                    <Wrapper
                        className='mt-md'
                        title={text?.dashboard?.timeClock}
                        button={
                            <CallToAction
                                type="button"
                                onClick={() => navigate(`/logs`)}
                                text={text?.timeTracker?.manageAllLogs}
                            />
                        }
                    >
                        {/* Time Tracker */}
                        <Form
                            onSubmit={() => null}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div style={{
                                        minWidth: '400px'
                                    }}>
                                        <p className='mb-sm flex'>
                                            {text?.logs?.selectProject}
                                        </p>
                                        <SelectInput
                                            name='selectProject'
                                            isClearable={false}
                                            isValidNewOption={() => false}
                                            isSearchable
                                            initialValue={initialProject}
                                            options={projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                                return {
                                                    label: x?.projectName,
                                                    value: x?._id
                                                }
                                            })}
                                            onChange={(e) => handleChange(e)}
                                            disabled={isRunning}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                        <TimeTracker
                                            disabled={!projectId}
                                            projectId={projectId}
                                            fetchInventoryProducts={fetchInventoryProducts}
                                            inventory={inventory}
                                            inventorysLoading={inventorysLoading}
                                            projects={projects}
                                            user={user}
                                        />
                                    </div>
                                </form>
                            )}
                        />
                        <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                            <CallToAction
                                className='mt-md'
                                onClick={() => setIsOpen(!isOpen)}
                                text={text?.dashboard?.viewRunning}
                                style={{
                                    alignSelf: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                                btnStyle={{
                                    color: 'white',
                                }}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={isEmpty(runningTimesheets)}>
                            <p className='mb-md'>
                                {text?.dashboard?.noEmployees}
                            </p>
                        </ConditionalRender>
                        <RunningTimeSheets
                            runningTimesheets={runningTimesheets}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                        />
                    </Wrapper>

                    <hr />
                </ConditionalRender>
                {/* Inventory */}
                <InventoryHomeContainer
                    user={user}
                    fetchUserDetails={fetchUserDetails}
                    isCompanyPremium={isCompanyPremium}
                    inventory={inventory}
                    fetchInventoryProducts={fetchInventoryProducts}
                    inventorysLoading={inventorysLoading}
                    setInventory={setInventory}
                    inventoryColumns={inventoryColumns}
                    setInventoryColumns={setInventoryColumns}
                    fetchAttributes={fetchAttributes}
                    projects={projects}
                    employees={employees}
                    title={text?.dashboard?.inventory}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

                <hr />

                <ClientsContainer
                    user={user}
                    fetchAllClients={fetchAllClients}
                    clients={clients}
                    clientsIsLoading={clientsIsLoading}
                    title={text?.client?.create?.clients}
                    fetchCompanyProjects={fetchCompanyProjects}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />
                <hr />
            </Wrapper>

            {/* Expanded Components */}
            <ExpandedContainer
                expanded={expanded}
                setExpanded={setExpanded}
                projects={projects}
                user={user}
                clients={clients}
                proposals={proposals}
                proposalsIsLoading={proposalsIsLoading}
                fetchAllProposals={fetchAllProposals}

                fetchAllClients={fetchAllClients}
                clientsIsLoading={clientsIsLoading}
                fetchCompanyProjects={fetchCompanyProjects}

                employees={employees}
                tasks={tasks}
                tasksIsLoading={tasksIsLoading}
                fetchProjectTasks={fetchProjectTasks}

                userRole={userRole}
                company={company}
                fetchCompanyEmployees={fetchCompanyEmployees}
                title={text?.dashboard?.employees}
                isDashboard

                fetchUserDetails={fetchUserDetails}
                isCompanyPremium={isCompanyPremium}
                inventory={inventory}
                fetchInventoryProducts={fetchInventoryProducts}
                inventorysLoading={inventorysLoading}
                setInventory={setInventory}
                inventoryColumns={inventoryColumns}
                setInventoryColumns={setInventoryColumns}
                fetchAttributes={fetchAttributes}


            />
        </StyledDiv>
    )
}

export default WelcomeAdminContainer