import request from '../request';

/**
 * Sends a user registration request to the API.
 * @param {String} projectId - the projectId from the url param
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const updateHistory = async (projectId, action) => {
    try {
        const response = await request('PUT', `/project/update-history/${projectId}`, { action });
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }

        // Initialize the boolean as true
        let isUpdated = true;

        // Change the boolean to false after one second
        setTimeout(() => {
            isUpdated = false;
        }, 1000);

        // Return the result and the boolean
        return { ...result, isUpdated };
    } catch (error) {
        throw new Error(`Failed to update project history: ${error.message}`);
    }
};

