import requestNoTimeOut from '../requestNoTimeOut';

/**
 * Sends a resources request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const createResources = async (values) => {
    try {
        const { data, status } = await requestNoTimeOut('POST', '/resources/create', values);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to create resources: ${error.message}`);
    }
};

export default createResources;
