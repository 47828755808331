import request from '../request';

/**
 * Sends a request to download a project file from the API.
 * @param {Object} params - The parameters for the request.
 * @param {string} params.projectId - The ID of the project.
 * @param {Object} params.file - The file object containing the URL, original name, and s3Tag.
 * @returns {Promise<Blob>} - A Promise that resolves to the downloaded file data as a Blob.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const downloadProjectFile = async ({ projectId, file, companyId = null }) => {
    try {
        const config = {
            responseType: 'blob', // Ensure the response is a Blob for file download
        };
        const res = await request('POST', '/project/download-file', { projectId, file, companyId }, config);
        return res;
    } catch (error) {
        throw new Error(`Failed to download project file: ${error.message}`);
    }
};

export default downloadProjectFile;
