import React from 'react'
import ConditionalRender from '../Core/ConditionalRender'
import ProposalContainerExpanded from './ProposalContainerExpanded'
import ClientsContainerExpanded from './ClientsContainerExpanded'
import TasksContainerExpanded from './TasksContainerExpanded'
import EmployeesContainerExpanded from './EmployeesContainerExpanded'
import InventoryHomeContainerExpanded from './InventoryHomeContainerExpanded'
import ConfirmModal from '../Core/ConfirmModal'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import { useLanguage } from '../context/LanguageContext'
import useScreenSize from '../context/useScreenSize'

const ExpandedContainer = ({
    expanded,
    setExpanded,
    projects,
    user,
    clients,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,

    fetchAllClients,
    clientsIsLoading,
    fetchCompanyProjects,
    employees,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    company,
    userRole,
    fetchCompanyEmployees,
    selectedEmployees,
    EmployeeIsAdmin,
    projectIsComplete,
    openEmployeeModal,
    employeeModalIsOpen,
    id,
    handleGetProject,
    employeesIsLoading,
    isDetailsProject = false,
    isDashboard = false,
    isProject = false,
    isAdmin = false,
    isCompanyPremium,
    fetchUserDetails,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    setInventory,
    inventoryColumns,
    setInventoryColumns,
    fetchAttributes,
    ...props
}) => {
    const { text } = useLanguage()
    const { isDesktop, loading } = useScreenSize()
    return (
        <div>
            <ConditionalRender renderIf={!isEmpty(expanded)} isLoading={loading}>
                <ConfirmModal
                    isOpen={!isEmpty(expanded)}
                    toggle={() => setExpanded('')}
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                >
                    <div className={classnames({
                        'w-90': isDesktop,
                        'w-100': !isDesktop
                    })}>
                        <ConditionalRender renderIf={expanded === 'proposals'}>
                            <ProposalContainerExpanded
                                title={text?.homeLandingContainer?.services?.proposals?.title}
                                adminProjects={projects}
                                projects={projects}
                                user={user}
                                clients={clients}
                                proposals={proposals}
                                proposalsIsLoading={proposalsIsLoading}
                                fetchAllProposals={fetchAllProposals}
                                isAdmin={isAdmin}
                                isProject={isProject}
                                {...props}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={expanded === 'clients'}>
                            <ClientsContainerExpanded
                                user={user}
                                fetchAllClients={fetchAllClients}
                                clients={clients}
                                clientsIsLoading={clientsIsLoading}
                                title={text?.client?.create?.clients}
                                fetchCompanyProjects={fetchCompanyProjects}
                                isProject={isProject}
                                {...props}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={expanded === 'tasks'}>
                            <TasksContainerExpanded
                                projects={projects}
                                user={user}
                                employees={employees}
                                tasks={tasks}
                                tasksIsLoading={tasksIsLoading}
                                fetchProjectTasks={fetchProjectTasks}
                                title={text?.dashboard?.tasks}
                                isProject={isProject}
                                {...props}
                            />
                        </ConditionalRender>

                        <ConditionalRender renderIf={expanded === 'employees'}>
                            <EmployeesContainerExpanded
                                employees={employees}
                                userRole={userRole}
                                user={user}
                                projects={projects}
                                company={company}
                                fetchCompanyEmployees={fetchCompanyEmployees}
                                title={text?.dashboard?.employees}

                                filteredEmployees={employees?.filter((x) => x?._id !== user?._id && selectedEmployees?.includes(x._id))}
                                employeesIsLoading={employeesIsLoading}
                                checkboxSelection={false}
                                isDeashboard={isDashboard}
                                isProject={isProject}
                                isDetailsProject={isDetailsProject}
                                EmployeeIsAdmin={EmployeeIsAdmin}
                                projectIsComplete={projectIsComplete}
                                openEmployeeModal={openEmployeeModal}
                                employeeModalIsOpen={employeeModalIsOpen}
                                id={id}
                                handleGetProject={handleGetProject}

                                {...props}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={expanded === 'inventory'}>
                            <InventoryHomeContainerExpanded
                                user={user}
                                fetchUserDetails={fetchUserDetails}
                                isCompanyPremium={isCompanyPremium}
                                inventory={inventory}
                                fetchInventoryProducts={fetchInventoryProducts}
                                inventorysLoading={inventorysLoading}
                                setInventory={setInventory}
                                inventoryColumns={inventoryColumns}
                                setInventoryColumns={setInventoryColumns}
                                fetchAttributes={fetchAttributes}
                                projects={projects}
                                employees={employees}
                                isProject={isProject}
                                {...props}
                            />
                        </ConditionalRender>
                    </div>
                </ConfirmModal>
            </ConditionalRender>
        </div>
    )
}

export default ExpandedContainer
