import React, { useContext, useEffect } from 'react'
import { Field } from 'react-final-form';
import CheckBox from '../Core/Inputs/CheckBox';
import { GlobalContext } from '../context/GlobalContext';
import { findFieldByName } from '../utils/helperFunctions'
import SelectInput from '../Core/SelectInput';
import { isEmpty } from 'lodash'
import { useLanguage } from '../context/LanguageContext'

import colors from '../globalStyles.scss'

const Filters = ({
    stringData,
    numberData,
    formattedData,
    dataCheckedKeys,
    setDataCheckedKeys,
    handleCombineChange,
    initialGroupBy,
    setChartIsEditing,
    formRef

}) => {
    const { text } = useLanguage()
    const { fieldNames } = useContext(GlobalContext)

    const advanceFieldOptionsSet = new Set();

    stringData?.forEach(data => {
        if (data.advanceFields) {
            Object.keys(data.advanceFields).forEach(key => {
                advanceFieldOptionsSet.add(key);
            });
        }
    });

    const advanceFieldOptions = Array.from(advanceFieldOptionsSet)?.map(key => ({
        value: `${key}`,
        label: findFieldByName(key, fieldNames, text)?.label
    }));

    useEffect(() => {
        // if (!dataCheckedKeys || !formattedData) return;

        const updatedKeys = dataCheckedKeys.filter((key) => {
            return formattedData.some((item) => {
                const isInAdvanceFields = Object.keys(item?.advanceFields || {}).some((advanceKey) => {
                    return advanceKey === key;
                });
                const isAtParentLevel = Object.keys(item || {}).includes(key);
                return isInAdvanceFields || isAtParentLevel;
            });
        });

        const updatedObject = updatedKeys.reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});

        if (JSON.stringify(updatedKeys) !== JSON.stringify(dataCheckedKeys)) {
            setDataCheckedKeys(updatedKeys);
        }

        formRef?.current?.change('dataCheckedKeys', updatedObject);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCheckedKeys]); // Empty dependency array to run only once


    const handleCheckboxChange = (key, input) => (e) => {
        if (!key) return;

        setDataCheckedKeys(prevKeys => {
            const updatedKeys = e.target.checked
                ? [...prevKeys, key] // Add the key if the checkbox is checked
                : prevKeys.filter(prevKey => prevKey !== key); // Remove the key if the checkbox is unchecked

            // Set chartIsEditing based on whether there are any keys left in the updatedKeys array
            setChartIsEditing(updatedKeys.length > 0);

            return updatedKeys;
        });

        // Update the input value for form handling
        input.onChange(e.target.checked);
    };

    // Create unique keys list from data
    const numberUniqueKeys = Array.from(new Set(formattedData?.flatMap(item => [...Object.keys(item), ...Object.keys(item.advanceFields || {})])));
    console.log('numberUniqueKeys', numberUniqueKeys, formattedData)

    // Filter out the keys in numberUniqueKeys if they are found in stringsAdvanceFieldsKeys
    const filteredNumberUniqueKeys = numberUniqueKeys.filter(key => {
        const findField = findFieldByName(key, fieldNames);
        return findField?.inputType !== 'phone' && findField?.inputType !== 'date' && findField?.inputType !== 'link' && findField?.inputType !== 'text' && findField?.inputType !== 'textarea' && findField?.inputType !== 'volume' && findField?.inputType !== 'length' && findField?.inputType !== 'weight' && findField?.inputType !== 'location'
    });

    // filter out checks by field type, that won't display anything in the chart
    const barChartDataCheckBoxes = filteredNumberUniqueKeys
        .filter(key =>
            // filter out unwanted fields that don't add up to anything
            key !== "inventoryName" &&
            key !== "_id" &&
            key !== "id" &&
            key !== "__v" &&
            key !== 'advanceFields' &&
            key !== 'projectName' &&
            key !== 'isPriority' &&
            key !== 'productId' &&
            key !== 'projectId' &&
            key !== 'images' &&
            key !== 'inventoryCategory' &&
            key !== 'projectStatus'
        )
        ?.map(key => {
            const findField = findFieldByName(key, fieldNames, text);
            return (
                <div key={key} className='checkbox-item mt-md' style={{
                    display: 'flex',
                }}>
                    <Field name={`dataCheckedKeys[${key}]`}>
                        {({ input, meta }) => (
                            <CheckBox
                                name={`dataCheckedKeys[${key}]`}
                                type="checkbox"
                                checked={dataCheckedKeys?.includes(key)}
                                onChange={handleCheckboxChange(key, input)}
                                label={findField?.label}
                                checkStyle={{
                                    background: colors.blue
                                }}
                            />
                        )}
                    </Field>
                </div>
            )
        })

    return (
        <>
            <div className='mt-md mb-md w-50'>
                <SelectInput
                    name={`groupBy`}
                    initialValue={initialGroupBy}
                    label={text?.analytics?.groupBy}
                    isClearable={false}
                    options={[
                        { value: 'inventoryName', label: text?.analytics?.name },
                        { value: 'inventoryCategory', label: text?.analytics?.category },
                        ...advanceFieldOptions
                    ]
                    }
                    onChange={(e) => {
                        handleCombineChange(e?.value, numberData)
                        if (!isEmpty(dataCheckedKeys)) {
                            setChartIsEditing(true)
                        }
                    }}
                    isValidNewOption={() => false}
                    style={{
                        width: '100%'
                    }}

                />
                <div>
                    <p className='flex mt-md'>
                        {text?.analytics?.checkInfo}
                    </p>
                    {barChartDataCheckBoxes}
                </div>
            </div>
        </>
    )
}

export default Filters
