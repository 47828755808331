import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import CallToAction from '../Core/CallToAction'
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import SearchInput from '../Core/Inputs/SearchInput';
import { isEmpty, set } from 'lodash'
import NavLink from '../Navigation/NavLink';
import SelectableBox from '../Core/SelectableBox';
import { useLanguage } from '../context/LanguageContext';
import PaginatedData from '../Core/PaginatedData';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import HoverPopup from '../Core/HoverPopup';
import useScreenSize from '../context/useScreenSize';

const StyledDiv = styled.div`
    width: 100%;
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`


const LandingProjectContainer = ({
    projects,
    user,
    setCollapsed
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [projectList, setProjectList] = useState(projects);
    const [completedProjectList, setCompletedProjectList] = useState(projects);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchCompletedQuery, setSearchCompletedQuery] = useState('');
    const [showCompleted, setShowCompleted] = useState(false);
    const [showInProgress, setShowInProgress] = useState(false);
    const [showFilters, setShowFilters] = useState(false);


    const completedProjects = completedProjectList?.filter(x => x?.projectStatus === 'completed');
    const activeProjects = projectList?.filter(x => x?.projectStatus !== 'completed');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchCompletedChange = (e) => {
        setSearchCompletedQuery(e.target.value);
    }

    useEffect(() => {
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            const filteredProjects = projects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setProjectList(filteredProjects);
        } else {
            setProjectList(projects);
        }
    }, [searchQuery, projects]);

    useEffect(() => {
        if (searchCompletedQuery) {
            const query = searchCompletedQuery.toLowerCase();
            const filteredProjects = completedProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setCompletedProjectList(filteredProjects);
        } else {
            setCompletedProjectList(projects);
        }

    }, [searchCompletedQuery, projects])

    return (
        <StyledDiv className='flex flex-column'>
            <div className='flex flex-column'>
                <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                    <CallToAction onClick={() => {
                        navigate('/project/create')
                        if (!isDesktop) {
                            setCollapsed(true)
                        }
                    }}
                        style={{
                            alignSelf: 'center',
                            marginTop: '1rem',
                            display: 'flex',
                            width: '100%',
                        }}
                        text={text?.projects?.landing?.createNew}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'completed')}>
                    <CallToAction
                        className='mt-md'
                        onClick={() => setShowCompleted(!showCompleted)}
                        text={text?.projects?.landing?.showCompleted}
                        style={{
                            alignSelf: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                        btnStyle={{
                            color: 'white',
                        }}
                    />
                </ConditionalRender>
            </div>
            <ConditionalRender renderIf={!isEmpty(activeProjects)}>
                <CallToAction
                    className='mt-md'
                    onClick={() => setShowInProgress(!showInProgress)}
                    text={text?.projects?.landing?.showInProgress}
                    style={{
                        alignSelf: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                    btnStyle={{
                        color: 'white',
                    }}
                />
                <div className='flex flex-column align-start mt-md '>
                    <h5 className='mb-sm'>{text?.projects?.landing?.lastUpdated}</h5>
                    <ul className='w-100'>
                        {
                            projects
                                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by updatedAt date in descending order
                                ?.slice(0, 1)
                                ?.map?.((x) => {
                                    return (
                                        <NavLink
                                            className='w-100'
                                            key={x?.id}
                                            to={`/project/details?id=${x?.id}`}
                                            onClick={() => {
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                            }}>
                                            <SelectableBox
                                                padding='0.5em'
                                                key={x?._id}
                                            >
                                                {x?.projectName}
                                            </SelectableBox>
                                        </NavLink>
                                    )
                                })
                        }
                    </ul>
                </div>
            </ConditionalRender>
            <ConditionalRender renderIf={showCompleted}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setShowCompleted(!showCompleted)
                        setSearchCompletedQuery('')
                        setShowFilters(false)
                    }}
                    isOpen={showCompleted}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <>
                        <div className='flex flex-column mt-md w-100'>
                            <h4 className='mb-md'>{text?.projects?.landing?.completed}</h4>
                            <div className='flex flex-column'>
                                <HoverPopup
                                    style={{
                                        marginRight: '1rem',
                                        alignSelf: 'flex-start'
                                    }}
                                    onClick={() => setShowFilters(!showFilters)}
                                    placement={'top'}
                                    id='toggle-filters-popup-employee'
                                    text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                                >
                                    <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                                </HoverPopup>

                                <ConditionalRender renderIf={showFilters}>
                                    <div className='flex flex-column filters mt-md'>
                                        <SearchInput
                                            style={{
                                                marginBottom: '1rem'
                                            }}
                                            value={searchCompletedQuery}
                                            onChange={handleSearchCompletedChange}
                                            placeHolder={text?.employees?.landing?.search}
                                        />

                                    </div>
                                </ConditionalRender>
                            </div>
                            <PaginatedData
                                className='mt-md'
                                data={completedProjects}
                                itemsPerPage={5}
                                noDataText={text?.employees?.landing?.notFound}
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className='flex justify-space-between'
                                            showLightColors
                                            onClick={() => {
                                                navigate(`/project/details?id=${item?.id}`)
                                                setShowCompleted(!showCompleted)
                                                setSearchCompletedQuery('')
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                                setShowFilters(false)
                                            }}
                                        >
                                            <p>
                                                {item?.projectName}
                                            </p>
                                            <p>
                                                {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                            </p>
                                        </SelectableBox>
                                    )
                                }}
                            />
                        </div>
                    </>
                </ConfirmModal>
            </ConditionalRender>

            <ConditionalRender renderIf={showInProgress}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setShowInProgress(!showInProgress)
                        setSearchQuery('')
                        setShowFilters(false)
                    }}
                    isOpen={showInProgress}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <>
                        <div className='flex flex-column mt-md w-100'>
                            <h4 className='mb-md'>{text?.projects?.landing?.showInProgress}</h4>
                            <div className='flex flex-column'>
                                <HoverPopup
                                    style={{
                                        marginRight: '1rem',
                                        alignSelf: 'flex-start'
                                    }}
                                    onClick={() => setShowFilters(!showFilters)}
                                    placement={'top'}
                                    id='toggle-filters-popup-employee'
                                    text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                                >
                                    <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                                </HoverPopup>

                                <ConditionalRender renderIf={showFilters}>
                                    <div className='flex flex-column filters mt-md'>
                                        <SearchInput
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            placeHolder={text?.projects?.landing?.search}
                                        />

                                    </div>
                                </ConditionalRender>
                            </div>
                            <PaginatedData
                                className='mt-md'
                                data={activeProjects}
                                itemsPerPage={5}
                                noDataText={text?.employees?.landing?.notFound}
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className={'flex justify-space-between'}
                                            showLightColors
                                            onClick={() => {
                                                navigate(`/project/details?id=${item?.id}`)
                                                setShowInProgress(!showInProgress)
                                                setSearchQuery('')
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                                setShowFilters(false)
                                            }}
                                        >
                                            <p>
                                                {item?.projectName}
                                            </p>
                                            <p>
                                                {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                            </p>
                                        </SelectableBox>
                                    )
                                }}
                            />
                        </div>
                    </>
                </ConfirmModal>
            </ConditionalRender>
        </StyledDiv>
    )
}

export default LandingProjectContainer