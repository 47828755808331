import { Link } from "react-router-dom";
import { renderLengthLabel, renderWeightLabel, renderVolumeLabel, proposalStatus } from "../utils/helperFunctions";
import PriorityCheckbox from "./PriorityCheckbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from "../Core/ConditionalRender";
import HoverPopup from "../Core/HoverPopup";
import moment from 'moment'
import useScreenSize from "../context/useScreenSize";

export const columns = ({
    handlePriorityChange,
    inventoryColumns,
    theme,
    text,
    isProject,
    handleQuickView,
    navigate,
    isDesktop
}) => {
    if (!inventoryColumns) {
        return [
            { field: 'productId', headerName: 'productId', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.productId;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_inventory_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/inventory/details?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`view_inventory_${id}`}
                                text={isDesktop && text?.tasks?.home?.columns?.view}
                                onClick={() => handleQuickView(row)}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                />
                            </HoverPopup>

                        </div>

                    );
                },
            },
            {
                headerName: text?.inventory?.landing?.table?.columns?.name,
                field: 'inventoryName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.inventoryName}
                            </span>
                        </p>
                    );
                },
            },
        ]
    }
    const sortedColumns = inventoryColumns
        ?.filter(column => column.isActive && column.fieldName !== 'isPriority' && column.fieldName !== 'inventoryName')
        ?.sort((a, b) => parseInt(a.sortOrder, 10) - parseInt(b.sortOrder, 10));

    const columnsArray = sortedColumns?.map(column => {
        const isProjectColumn = column.fieldName === 'projectName';

        return {
            headerName: column.fieldLabel,
            field: column.fieldName,
            width: 100,
            hidable: isProjectColumn && isProject,
            sortOrder: parseInt(column.sortOrder, 10),
            renderCell: ({ row }) => {
                let fieldValue;
                if (row.advanceFields) {
                    Object.keys(row.advanceFields).forEach(key => {
                        const fieldType = key.split('-')[0];
                        const fieldName = key.split('-')[1];
                        if (fieldName === column.fieldName) {
                            fieldValue = row.advanceFields[key];
                        }
                        if (fieldName === column.fieldName && fieldType === 'amount') {
                            fieldValue = '$' + row.advanceFields[key];
                        }
                        if (fieldName === column.fieldName && fieldType === 'number') {
                            fieldValue = row.advanceFields[key].value;
                        }
                        if (fieldName === column.fieldName && fieldType === 'length') {
                            const lengthValue = fieldValue?.split('-')[0];
                            const lengthUnit = fieldValue?.split('-')[1];
                            const lengthLabel = renderLengthLabel(lengthUnit, text);
                            fieldValue = `${lengthValue} ${lengthLabel}`;
                        }
                        if (fieldName === column.fieldName && fieldType === 'weight') {
                            const weightValue = fieldValue?.split('-')[0];
                            const weightUnit = fieldValue?.split('-')[1];
                            const weightLabel = renderWeightLabel(weightUnit, text);
                            fieldValue = `${weightValue} ${weightLabel}`;
                        }
                        if (fieldName === column.fieldName && fieldType === 'volume') {
                            const volumeValue = fieldValue?.split('-')[0];
                            const volumeUnit = fieldValue?.split('-')[1];
                            const volumeLabel = renderVolumeLabel(volumeUnit, text);
                            fieldValue = `${volumeValue} ${volumeLabel}`;
                        }
                    });
                }
                if (column.fieldName === 'inventoryQuantity') {
                    if (!row?.inventoryQuantity?.value || row?.inventoryQuantity?.value === 0) {
                        fieldValue = '0';
                    } else {
                        fieldValue = row?.inventoryQuantity?.value;
                    }
                }
                if (column?.fieldName === 'projectName') { // Adjusted this line to check for specific field
                    if (row?.projectId && row?.projectId[column?.fieldName]) {
                        fieldValue = row?.projectId[column?.fieldName];
                    } else {
                        fieldValue = text?.inventory?.landing?.table?.columns?.unassigned;
                    }
                } else if (!fieldValue) { // Adjusted this to be an else if to avoid overwriting 'Unassigned'
                    fieldValue = row[column.fieldName];
                }

                let formattedValue;
                if (moment(fieldValue, moment.ISO_8601, true).isValid()) {
                    // It's a date
                    formattedValue = moment(fieldValue).format('MM-DD-YYYY');
                } else {
                    // It's neither a date nor a time in 24-hour format
                    formattedValue = fieldValue;
                }

                return (
                    <p>
                        <span style={{ maxWidth: "100px" }} className="truncate">
                            {formattedValue}
                        </span>
                    </p>
                );
            },
        };
    });

    // Check if "Priority" column should be added
    const priorityColumn = inventoryColumns?.find(column => column.fieldName === 'isPriority' && column.isActive);
    if (priorityColumn) {
        const priorityColumnIndex = priorityColumn.sortOrder; // Get the sortOrder value of the priority column
        columnsArray.splice(priorityColumnIndex, 0, {
            headerName: text?.inventory?.landing?.table?.columns?.priority,
            field: priorityColumn.fieldName,
            sortOrder: parseInt(priorityColumn.sortOrder, 10),
            width: 100,
            renderCell: ({ row }) => {
                const fieldValue = row[priorityColumn.fieldName];
                return (
                    <p>
                        <span style={{ maxWidth: "100px" }} className="truncate">
                            <PriorityCheckbox
                                handlePriorityChange={handlePriorityChange}
                                productId={row.productId}
                                initialValue={fieldValue}
                            />
                        </span>
                    </p>
                );
            },
        });
    }


    // Add the "Details" column at the beginning
    columnsArray?.unshift({
        headerName: '',
        field: '',
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
            const id = row?.productId;

            return (
                <div className='flex items-center'>
                    <HoverPopup
                        className='mr-sm'
                        placement={'top'}
                        id={`details_inventory_${id}`}
                        text={text?.tasks?.home?.columns?.details}
                        onClick={() => {
                            navigate(`/inventory/details?id=${id}`);
                        }}
                        style={{
                            background: 'transparent'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faEdit}
                        />
                    </HoverPopup>
                    <HoverPopup
                        className='mr-sm'
                        placement={'top'}
                        id={`view_inventory_${id}`}
                        text={isDesktop && text?.tasks?.home?.columns?.view}
                        onClick={() => handleQuickView(row)}
                        style={{
                            background: 'transparent'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                        />
                    </HoverPopup>

                </div>

            );
        },
    },
        {
            headerName: text?.inventory?.landing?.table?.columns?.name,
            field: 'inventoryName',
            width: 200,
            renderCell: ({ row }) => {
                const productId = row?.productId;
                return (
                    <HoverPopup style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'transparent',
                        border: 'none',
                        hoverBackground: 'transparent',

                    }}
                        placement={'right'}
                        id={`viewDetails_inventory_${productId}`}
                        text={text?.inventory?.landing?.table?.columns?.details}
                    >
                        <p>
                            <span
                                style={{ minWidth: "100px" }}
                                className="truncate details">
                                {row?.inventoryName}
                            </span>
                        </p>
                    </HoverPopup>
                );
            },
        },
    );
    columnsArray?.sort((a, b) => a.sortOrder - b.sortOrder);

    return columnsArray;
};

export const employeesColumns = ({ theme, text, navigate, userRole }) => {
    return userRole === 'Admin' ? (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 50,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.id;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_employee_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/settings/employee?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                        </div>

                    );
                },
            },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: 'View Logs',
                field: 'viewLogs',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                <Link
                                    to={`/logs?id=${row?._id}`}
                                >
                                    {text?.employees?.landing?.viewLogs}
                                </Link>
                            </span>
                        </p>
                    );
                },
            },
            {
                headerName: text?.projects?.create?.admin,
                field: 'roleName',
                width: 110,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.roleName === 'Admin' ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                            </span>
                        </p>
                    );
                },
            }
        ]
    ) : (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },

            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: 'Email',
                field: 'email',
                width: 300,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.email}
                            </span>
                        </p>
                    );
                },
            },
            {
                headerName: text?.projects?.create?.admin,
                field: 'roleName',
                width: 110,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.roleName === 'Admin' ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                            </span>
                        </p>
                    );
                },
            }
        ]
    )
}

export const tasksColumns = ({
    theme,
    text,
    status,
    priority,
    isProject,
    navigate,
    handleQuickView,
    isDesktop
}) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const id = row?._id;
                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`details_tasks_${id}`}
                            text={text?.tasks?.home?.columns?.details}
                            onClick={() => {
                                navigate(`/tasks/details?id=${id}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                            />
                        </HoverPopup>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`view_tasks_${id}`}
                            text={isDesktop && text?.tasks?.home?.columns?.view}
                            onClick={() => handleQuickView(row)}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                            />
                        </HoverPopup>

                    </div>

                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.title,
            field: 'taskTitle',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <div className='flex items-center'>
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.taskTitle}
                            </span>
                        </p>
                    </div>

                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.status,
            field: 'taskStatus',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {status(text).find(s => s.value === row?.taskStatus)?.label}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.priority,
            field: 'taskPriority',
            width: 100,
            formatter: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {priority(text).find(s => s.value === row?.taskPriority)?.label}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.project,
            field: 'taskProject',
            width: 150,
            hide: isProject,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            <Link
                                to={`/project/details?id=${row?.taskProject?.id}`}
                            >
                                {row?.taskProject?.projectName}
                            </Link>
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.deadline,
            field: 'taskDeadline',
            width: 200,
            renderCell: ({ row }) => {
                const id = row?._id;
                const deadlineDate = moment(row?.taskDeadline);
                const currentDate = moment();
                const diffInDays = deadlineDate.diff(currentDate, 'days');
                let color;
                let title;
                if (diffInDays >= 5) {
                    color = 'green';
                    title = `${text?.tasks?.home?.columns?.dueIn} ${diffInDays} ${text?.tasks?.home?.columns?.days}`;
                } else if (diffInDays >= 3) {
                    color = 'yellow';
                    title = `${text?.tasks?.home?.columns?.dueIn} ${diffInDays} ${text?.tasks?.home?.columns?.days}`;
                } else if (diffInDays >= 0) {
                    color = 'red';
                    title = `${text?.tasks?.home?.columns?.dueIn} ${diffInDays} ${text?.tasks?.home?.columns?.days}`;
                } else {
                    color = 'red';
                    title = text?.tasks?.home?.columns?.pastDue;
                }

                return (
                    <HoverPopup style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'transparent',
                        border: 'none',
                        hoverBackground: 'transparent',
                    }}
                        placement={'top'}
                        id={`viewDetails_tasks_pastDue_${id}`}
                        text={title}
                    >
                        <p>
                            <span
                                style={{
                                    minWidth: "50px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }} className="truncate details">
                                <p
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: color,
                                        marginRight: '10px'
                                    }}
                                />
                                <p>
                                    {moment(row?.taskDeadline).format('MM-DD-YYYY')}
                                </p>
                            </span>
                        </p>
                    </HoverPopup>

                );
            },
        },

    ]
}

export const clientsColumns = ({ theme, text, navigate }) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const id = row?.id;

                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`details_client_${id}`}
                            text={text?.tasks?.home?.columns?.details}
                            onClick={() => {
                                navigate(`/clients/details?id=${id}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                            />
                        </HoverPopup>
                    </div>

                );
            },
        },
        {
            headerName: text?.client?.create?.name,
            field: 'name',
            width: 200,
            renderCell: ({ row, rowIndex }) => {
                return (
                    <div>
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {`${row?.name}`}
                            </span>
                        </p>
                    </div>
                );
            }
        },
        {
            headerName: text?.client?.create?.email,
            field: 'email',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }}
                            className="truncate details"
                        >
                            <a
                                href={`mailto:${row?.email}`}
                                target="_blank" // Opens in a new tab/window (optional)
                                rel="noreferrer" // Security measure to prevent opening links from untrusted sources (optional)
                                style={{ textDecoration: 'none', color: 'inherit' }} // Optional: For email link styling
                            >
                                {row?.email}
                            </a>
                        </span>
                    </p>
                );
            },
        },


        {
            headerName: text?.client?.create?.phone,
            field: 'phone',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px",
                                cursor: 'pointer'
                            }} className="truncate details">
                            <a href={`tel:${row?.phone}`}>
                                {`${row?.phone || ''}`}
                            </a>
                        </span>
                    </p>
                );
            }
        },
        {
            headerName: text?.client?.create?.address,
            field: 'address',
            width: 350,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px",
                                cursor: 'pointer'
                            }} className="truncate details">
                            <a href={`https://www.google.com/maps/search/?api=1&query=${row?.address}`} target="_blank" rel="noreferrer">
                                {`${row?.address || ''}`}
                            </a>
                        </span>
                    </p>
                );
            }
        }
    ]
}

export const proposalColumns = ({
    theme,
    text,
    isProject,
    handleQuickView,
    navigate,
    isDesktop
}) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const id = row?._id;
                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`details_proposals_${id}`}
                            text={isDesktop && text?.tasks?.home?.columns?.details}
                            onClick={() => {
                                navigate(`/proposals/details?id=${id}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                            />
                        </HoverPopup>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`view_proposals_${id}`}
                            text={isDesktop && text?.tasks?.home?.columns?.view}
                            onClick={() => handleQuickView(row)}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                            />
                        </HoverPopup>

                    </div>

                );
            },
        },
        {
            headerName: '#',
            field: 'uuid',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <div>
                        <p>
                            <span>
                                {`#${row?.uuid}`}
                            </span>
                        </p>
                    </div>

                );
            }
        },
        {
            headerName: text?.tasks?.home?.columns?.title,
            field: 'title',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {row?.title}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.status,
            field: 'status',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {proposalStatus(text).find(s => s.value === row?.status)?.label}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.project,
            field: 'projectId',
            width: 100,
            hidable: isProject,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            <Link
                                to={`/project/details?id=${row?.projectId?.id}`}
                            >
                                {row?.projectId?.projectName}
                            </Link>
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.client,
            field: 'clientId',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            <Link
                                to={`/clients/details?id=${row?.clientId?.id}`}
                            >
                                {row?.clientId?.name}
                            </Link>
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.draft,
            field: 'isDraft',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            {row?.isDraft ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.submitted,
            field: 'isSubmitted',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            {row?.isSubmitted ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.updated,
            field: 'updatedAt',
            headerWidth: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            {moment(row?.updatedAt).format('MM-DD-YYYY')}
                        </span>
                    </p>
                );
            },
        }
    ]
}

export const timeLogsColumns = ({ theme, text, user, formatDate, setTimeLogIsOpen, timeLogIsOpen, setDailyLog }) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: text?.timeTracker?.name,
            field: 'name',
            width: 200,
            renderCell: ({ row }) => {
                const userId = row?.userId;
                const userName = `${userId?.firstName} ${userId?.lastName}`;
                const isAdmin = userId?.roleName === 'Admin';
                const isCurrentUser = user?.id === userId?._id;
                if (isAdmin && !isCurrentUser) {
                    return <span>{userName}</span>;
                } else if (!isAdmin && !isCurrentUser) {
                    return <Link to={`/logs?id=${userId?._id}`}>{userName}</Link>;
                } else {
                    return <span>{userName}</span>;
                }
            }
        },
        {
            headerName: text?.timeTracker?.clockIn,
            field: 'clockIn',
            width: 300,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {formatDate(row?.clockIn)}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.timeTracker?.dailyLog,
            field: 'dailyLog',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <p
                        className='pointer'
                        onClick={() => {
                            setTimeLogIsOpen(!timeLogIsOpen);
                            setDailyLog(row)
                        }}>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {text?.timeTracker?.viewLog || 'No Daily Log'}
                        </span>
                    </p>
                );
            },
        },
    ]
}

export const assignedEmployeesColumns = ({ theme, text, userRole, project, navigate }) => {
    return userRole === 'Admin' ? (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 50,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.id;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_employee_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/settings/employee?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                        </div>

                    );
                },
            },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: text?.projects?.create?.admin,
                field: 'isAdmin',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            {
                                project?.adminEmployees?.find((x) => x.id === row._id) ? (
                                    <span>{text?.proposals?.landing?.yes}</span>
                                ) : (
                                    <span>{text?.proposals?.landing?.no}</span>
                                )
                            }
                        </p>
                    );
                },
            },
            {
                headerName: text?.employees?.landing?.viewLogs,
                field: 'viewLogs',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <Link className='ml-md' to={`/logs?id=${row._id}`}>
                                {text?.employees?.landing?.viewLogs}
                            </Link>
                        </p>
                    );
                },
            }
        ]
    ) : (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: 'Email',
                field: 'email',
                width: 300,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.email}
                            </span>
                        </p>
                    );
                },
            },
            {
                headerName: 'Admin',
                field: 'isAdmin',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            {
                                project?.adminEmployees?.find((x) => x.id === row._id) ? (
                                    <span>{text?.proposals?.landing?.yes}</span>
                                ) : (
                                    <span>{text?.proposals?.landing?.no}</span>
                                )
                            }
                        </p>
                    );
                },
            }
        ]
    )

}
