import request from '../request';

/**
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getTimesheetByTaskId = async (values) => {
    try {
        const response = await request('POST', `/time/get-by-task-id`, values);
        let result = {}
        if (response?.data) {
            result.data = response?.data
        }
        if (response?.status) {
            result.status = response?.status
        }
        return result
    } catch (error) {
        throw new Error(`Failed to fetch time: ${error.message}`);
    }
};

export default getTimesheetByTaskId;
