import React from 'react';
import useScreenSize from '../context/useScreenSize'
import HomeLandingContainerDesktop from './HomeLandingContainerDesktop';
import HomeLandingContainerTablet from './HomeLandingContainerTablet';
import HomeLandingContainerPhone from './HomeLandingContainerPhone';
import ConditionalRender from '../Core/ConditionalRender';

const HomeLandingContainer = ({
    featuredResources,
    featuredResourcesIsLoading
}) => {
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    return (
        <>
            <ConditionalRender renderIf={isDesktop}>
                <HomeLandingContainerDesktop
                    featuredResourcesIsLoading={featuredResourcesIsLoading}
                    featuredResources={featuredResources}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isTablet}>
                <HomeLandingContainerTablet
                    featuredResourcesIsLoading={featuredResourcesIsLoading}
                    featuredResources={featuredResources}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isPhone}>
                <HomeLandingContainerPhone
                    featuredResourcesIsLoading={featuredResourcesIsLoading}
                    featuredResources={featuredResources}
                />
            </ConditionalRender>
        </>
    )
}

export default HomeLandingContainer