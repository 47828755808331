import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react'
import { GlobalContext } from '../context/GlobalContext';
import queryString from "query-string";
import styled from 'styled-components'
import { useLocation } from "react-router-dom";
import { isRequired, composeValidators } from '../utils/validators';
import { isEmpty } from 'lodash';
import { NumericFormat } from 'react-number-format';
import base64ToBlob from '../utils/base64ToBlob';
import { Form, FormSpy, Field } from 'react-final-form';
import CreatableMultiInput from '../Core/Inputs/CreatableMultiInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import RenderImages from '../Core/RenderImages';
import Wrapper from '../Core/Wrapper';
import arrayMutators from 'final-form-arrays';
import CallToAction from '../Core/CallToAction';
import FieldsWrapper from './FieldsWrapper';
import { proposalStatus } from '../utils/helperFunctions';
import FormActions from '../Core/FormActions';
import NotFound from '../Home/NotFound';
import MultiFields from '../Core/MultiFields';
import CreateNewClientContainer from '../Clients/CreateNewClientContainer';
import FieldWrapper from '../Core/FieldWrapper';
import ConditionalRender from '../Core/ConditionalRender';
import SelectInput from '../Core/SelectInput';
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext';
import moment from 'moment';

import CanvasSignature from '../QuickView/CanvasSignature';
import classnames from 'classnames';
import useScreenSize from '../context/useScreenSize';
import colors from '../globalStyles.scss'
import {
    getProposal,
    updateProposalImages,
    saveProposalAsDraft,
    createProposal,
    sendProposalEmails,
    exportPdfProposal,
    updateHistory
} from '../utils/calls';

const StyledDiv = styled.div`

    .image-uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dropzone {
            display: flex;
            flex-direction: column;
            margin: 0.5em 0;
            padding: 1em;
            background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                background: ${colors.lightGray};
            }
            span {
                font-family: ${colors.roboto};
                color: ${props => props.theme === 'dark' ? colors.black : colors.black};
            }
        }
    }

    .email_wrapper {
        background: ${props => props.theme === 'dark' ? colors.backgroundOverlay : colors.lightGray};
        padding: 1em;
    }
`

const ProposalDetailsContainer = ({
    projects,
    user,
    clients,
}) => {
    const { fetchAllClients } = useContext(GlobalContext);
    const formRef = useRef();


    const location = useLocation();
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();


    const [proposal, setProposal] = useState({});
    const [spyValues, setSpyValues] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    const [images, setImages] = useState([])
    const [newClient, setNewClient] = useState({});
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [proposalIsLoading, setProposalIsLoading] = useState(false)
    const [disabledProposalEdit, setDisabledproposalEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [initialProjectValue, setInitialProjectValue] = useState({
        label: proposal?.projectId?.projectName || text?.inventory?.details?.form?.unassigned,
        value: proposal?.projectId?._id || '000000000000000000000000',
    });

    const [initialStatusValue, setInitialStatusValue] = useState(proposalStatus(text).find(s => s.value === proposal?.status));

    const [initialClientValue, setInitialClientValue] = useState(() => {
        const selectedProject = spyValues?.values?.projectId && projects?.find((x) => x?._id === spyValues?.values?.projectId?.value);
        return proposal?.clientId ? {
            label: `${proposal?.clientId?.name} (${proposal?.clientId?.email})`,
            value: proposal?.clientId?._id
        } : selectedProject?.client && {
            label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
            value: selectedProject?.client?._id
        };

    })

    const [initialAdditionalEmails, setInitialAdditionalEmails] = useState(proposal?.additionalEmails?.map((email) => ({ label: email, value: email })));

    useEffect(() => {
        setInitialStatusValue(proposalStatus(text).find(s => s.value === proposal?.status))
        setInitialProjectValue({
            label: proposal?.projectId?.projectName || text?.inventory?.details?.form?.unassigned,
            value: proposal?.projectId?._id || '000000000000000000000000',
        });

    }, [proposal])

    useEffect(() => {
        if (newClient) {
            formRef?.current?.change('clientId', {
                label: `${newClient?.name} (${newClient?.email})`,
                value: newClient?._id
            }
            )
        }
    }, [newClient])

    useMemo(() => {
        if (initialProjectValue) {
            const selectedProject = projects?.find((x) => x?._id === spyValues?.values?.projectId?.value);
            setInitialClientValue(proposal?.clientId ? {
                label: `${proposal?.clientId?.name} (${proposal?.clientId?.email})`,
                value: proposal?.clientId?._id
            } : selectedProject?.client && {
                label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
                value: selectedProject?.client?._id
            });
        }
    }, [initialProjectValue])

    useMemo(() => {
        if (initialClientValue) {
            setInitialAdditionalEmails(proposal?.additionalEmails?.map((x) => ({ label: x, value: x })))
        }
    }, [initialClientValue])

    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (!isEmpty(spyValues?.values?.fields)) {
            const total = spyValues?.values?.fields?.reduce((acc, curr) => {
                if (curr.amount === undefined) return acc
                return acc + curr.amount
            }, 0)
            setTotal(total)
        }
    }, [spyValues?.values?.fields]);

    useMemo(() => {
        setImages(proposal.images)
    }, [proposal.images])

    const queryId = queryString.parse(location.search).id
    const [id, setId] = useState(queryId)

    useEffect(() => {
        setId(queryString.parse(location.search).id)
    }, [queryId])

    const processProjectImages = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData();
                images.forEach((image) => {
                    if (image.new) {
                        const imageBlob = base64ToBlob(image?.url);
                        formData.append('newImages', imageBlob, image?.originalName);
                    } else {
                        formData.append('oldImages', JSON.stringify(image));
                    }
                });

                formData.append('proposalId', id);

                // Send to the API
                const res = await updateProposalImages(formData);
                if (res.status === 200) {
                    console.log("Images updated");
                    handleGetProposal();
                    setProposalIsLoading(false);
                    resolve(res);
                } else {
                    setProposalIsLoading(false);
                    reject(new Error("Images update failed"));
                }
            } catch (error) {
                setProposalIsLoading(false);
                reject(error);
            }
        });
    };
    const onSubmitDraft = async () => {
        setProposalIsLoading(true)
        let values = {}
        values.proposalId = id
        if (spyValues?.values?.status) {
            values.status = spyValues?.values?.status?.value
        }
        if (spyValues?.values?.status?.value === 'Pending') {
            values.statusWasUpdated = false
        }
        if (spyValues?.values?.projectId) {
            values.projectId = spyValues?.values?.projectId?.value;
        }
        if (spyValues?.values?.clientId) {
            values.clientId = spyValues?.values?.clientId?.value;
            values.clientEmail = spyValues?.values?.clientId?.label.match(/\(([^)]+)\)/)?.[1] || '';
        }
        if (!isEmpty(spyValues?.values?.additionalEmails)) {
            values.additionalEmails = spyValues?.values?.additionalEmails.map(x => x.value)
        } else {
            values.additionalEmails = []
        }
        if (spyValues?.values?.emailSubject) {
            values.emailSubject = spyValues?.values?.emailSubject
        } else {
            values.emailSubject = ''
        }
        if (spyValues?.values?.title) {
            values.title = spyValues?.values?.title
        } else {
            values.title = ''
        }
        if (spyValues?.values?.emailMessage) {
            values.emailMessage = spyValues?.values?.emailMessage
        } else {
            values.emailMessage = ''
        }
        if (total > 0) {
            values.totalCost = total
        }
        if (proposal.signatureName) {
            values.signatureName = proposal.signatureName
        }
        if (proposal.signature) {
            values.signature = proposal.signature;
        }
        if (proposal.signatureDate) {
            values.signatureDate = proposal.signatureDate
        }
        if (!isEmpty(spyValues?.values?.fields)) {
            values.fields = spyValues?.values?.fields.map(x => {
                return {
                    description: x?.description || '',
                    amount: x?.amount || 0,
                    notes: x?.notes || ''
                }
            })
        } else {
            values.fields = []
        }
        try {
            const res = await saveProposalAsDraft(values)
            if (res.status === 200) {
                setIsEditing(false)
                if (values.projectId !== '000000000000000000000000') {
                    console.log('console: ', values.projectId)
                    updateHistory(values.projectId, `${text?.projects?.history?.proposalDraftUpdated} ${values.title}`)
                }
                notify(text?.notificationsUI?.proposal?.saved, "success")

                return processProjectImages()
            }
        } catch (error) {
            setProposalIsLoading(false)
            notify(`Error: ${error.message}`, "error")
            console.error(error)
        }
    }

    const onSubmit = async (values) => {
        setProposalIsLoading(true)

        let body = {}
        body.proposalId = id
        body.status = 'Pending';
        body.statusWasUpdated = false
        body.signatureName = '';
        body.signature = '';
        body.projectId = values?.projectId?.value;
        body.clientId = values?.clientId?.value;
        body.clientEmail = values?.clientId?.label.match(/\(([^)]+)\)/)?.[1] || '';
        body.yourEmail = user?.email
        if (!isEmpty(values?.additionalEmails)) {
            body.additionalEmails = values?.additionalEmails.map(x => x.value)
        } else {
            body.additionalEmails = []
        }
        if (values?.emailSubject) {
            body.emailSubject = values?.emailSubject
        } else {
            body.emailSubject = ''
        }
        if (values?.emailMessage) {
            body.emailMessage = values?.emailMessage
        } else {
            body.emailMessage = ''
        }
        body.fields = values?.fields?.map(x => {
            return {
                description: x?.description || '',
                amount: x?.amount || 0,
                notes: x?.notes || ''
            }
        })
        body.totalCost = total

        try {
            const res = await createProposal(body)
            if (res.status === 200) {
                setIsEditing(false)
                processProjectImages().then(() => {
                    if (values?.projectId?.value !== '000000000000000000000000') {
                        updateHistory(values?.projectId?.value, `${text?.projects?.history?.proposalSubmitted} ${values.title}`)
                    }
                    notify(text?.notificationsUI?.proposal?.submitted, "success")
                    return sendProposalEmails(id)
                })
            }
        } catch (error) {
            console.error(error)
            notify(`Error: ${error.message}`, "error")
            setProposalIsLoading(false)
        }
    }

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageError, setImageError] = useState('')

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const removeImage = (imageIndex) => {
        setImages((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
        setIsEditing(true)
    };

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file =>
            file.type.startsWith('image/')
        );
        if (imageFiles.length !== acceptedFiles.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages)
        } else {
            const newUploadedImages = await Promise.all(
                acceptedFiles?.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = async () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        if (file) {
                            reader.readAsDataURL(file);
                        } else {
                            reject(new Error("No file provided"));
                        }
                    });
                })
            );
            setImages((prevImages) => {
                const updatedImages = [
                    ...prevImages,
                    ...newUploadedImages?.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                ];

                return updatedImages;
            });
            setIsEditing(true)
            setImageError('')
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true,
    });


    const handleGetProposal = async () => {
        setProposalIsLoading(true)
        try {
            const res = await getProposal(id)
            if (res.status === 200) {
                setProposalIsLoading(false)
                setProposal(res.data)
                setIsDisabled(res.data.status === 'Approved')
                setDisabledproposalEdit(res.data.isSubmitted)
            }
        } catch (error) {
            setProposalIsLoading(false)
            setProposal({})
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetProposal()
    }, [id])

    const handleSendProposal = async () => {
        // this function does not export but it send an email with the pdf attached
        // TODO: RENAME
        try {
            const { status } = await exportPdfProposal(id);
            if (status === 200) {
                console.log('Exported PDF');
                notify(text?.notificationsUI?.proposal?.sent, "success")
            } else {
                notify(text?.notificationsUI?.proposal?.error, "error")
                console.error('Error sending proposal:', status);
            }
        } catch (error) {
            notify(text?.notificationsUI?.proposal?.error, "error")
            console.error('Error sending proposal:', error);
        }
    };

    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });

    const [addClient, setAddClient] = useState(false)

    return !proposalIsLoading && isEmpty(proposal) ? <NotFound /> :
        (
            <StyledDiv theme={theme} >
                <div className='mb-lg tx-left'>
                    <h4>
                        {text?.projects?.details?.details}
                    </h4>
                    <hr />
                </div>
                <ConditionalRender
                    count={2}
                    customStyled={{
                        height: '100vh',
                        display: 'flex'
                    }}
                    renderIf={true}
                    isLoading={proposalIsLoading}
                >
                    <div className='flex flex-column'>
                        <ConditionalRender renderIf={disabledProposalEdit}>
                            <div className={'flex mb-md'}>
                                <p>
                                    {text?.proposals?.details?.submitted}
                                </p>
                            </div>
                        </ConditionalRender>
                        <FieldWrapper
                            name={'#'}
                            label={`${proposal?.uuid}`}
                        />
                    </div>
                    <ConditionalRender renderIf={true}>
                        <Form
                            onSubmit={(values) => onSubmit(values)}
                            mutators={{
                                ...arrayMutators,
                            }}
                            render={({ handleSubmit, form }) => {
                                formRef.current = form;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FormSpy subscription={{ values: true }} onChange={(values) => setSpyValues(values)} />
                                        <div className='flex'>
                                            <FieldWrapper
                                                name={text?.proposals?.details?.status}
                                                label={initialStatusValue?.label}
                                            />
                                            <ConditionalRender renderIf={spyValues?.values?.status?.value === 'Approved'}>
                                                <FontAwesomeIcon
                                                    style={{
                                                        color: theme === 'dark' ? colors.secondary : colors.green,
                                                        marginLeft: '1em'
                                                    }}
                                                    icon={faCheck}
                                                />
                                            </ConditionalRender>
                                            <ConditionalRender renderIf={spyValues?.values?.status?.value === 'Rejected'}>
                                                <FontAwesomeIcon
                                                    style={{
                                                        color: colors.red,
                                                        marginLeft: '1em'
                                                    }}
                                                    icon={faClose}
                                                />
                                            </ConditionalRender>
                                        </div>
                                        <SelectInput
                                            className='field-style'
                                            isValidNewOption={() => false}
                                            isClearable={false}
                                            isSearchable={false}
                                            name='status'
                                            label={text?.proposals?.details?.status}
                                            options={proposalStatus(text)}
                                            setIsEditing={setIsEditing}
                                            initialValue={initialStatusValue}
                                            disabled
                                            style={{
                                                display: 'none'
                                            }}
                                        />

                                        <FormSpy subscription={{ values: true }}>
                                            {({ form, values }) => {
                                                return (
                                                    <>
                                                        <SelectInput
                                                            className='field-style'
                                                            menuPlacement='bottom'
                                                            name='projectId'
                                                            isClearable={false}
                                                            label={text?.proposals?.details?.project}
                                                            setIsEditing={setIsEditing}
                                                            validate={required}
                                                            initialValue={initialProjectValue}
                                                            placeholder={text?.proposals?.details?.project}
                                                            isSearchable
                                                            isValidNewOption={() => false}
                                                            controlWidth='100%'
                                                            options={[
                                                                ...(user?.roleName === 'Admin' ? [{ label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' }] : []),
                                                                ...(adminProjects ? adminProjects?.filter((x) => x.projectStatus !== 'completed')?.map((x) => ({
                                                                    label: x?.projectName,
                                                                    value: x?.id
                                                                })) : [])
                                                            ]}
                                                            onChange={(selectedOption) => {
                                                                const selectedProject = projects?.find((x) => x?._id === selectedOption.value);
                                                                const newClientValue = selectedProject?.client && {
                                                                    label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
                                                                    value: selectedProject?.client?._id
                                                                };
                                                                form.change('clientId', newClientValue);
                                                            }}
                                                        />

                                                        <div className='email_wrapper'>
                                                            <FormSpy subscription={{ values: true }}>
                                                                {({ values }) => {
                                                                    const selectedProject = projects?.find((x) => x?._id === values?.projectId?.value);

                                                                    return (
                                                                        <ConditionalRender renderIf={true}>
                                                                            <p className='small flex mb-sm'>
                                                                                {text?.proposals?.details?.textThree}
                                                                            </p>
                                                                            <p className='small flex'>
                                                                                {text?.proposals?.details?.textFour}
                                                                            </p>
                                                                            <div className={classnames({
                                                                                'flex': isDesktop || isTablet,
                                                                                'mt-md': true,
                                                                            })}>
                                                                                <SelectInput
                                                                                    menuPlacement='bottom'
                                                                                    label={text?.projects?.create?.client}
                                                                                    initialValue={initialClientValue}
                                                                                    name='clientId'
                                                                                    setIsEditing={setIsEditing}
                                                                                    isClearable={false}
                                                                                    validate={required}
                                                                                    placeholder={text?.proposals?.details?.client}
                                                                                    isSearchable
                                                                                    disabled={isDisabled}
                                                                                    isValidNewOption={() => false}
                                                                                    controlWidth='100%'
                                                                                    options={clients?.map((client) => ({
                                                                                        label: `${client.name} (${client.email})`,
                                                                                        value: client?._id
                                                                                    }))}
                                                                                />
                                                                                <ConditionalRender renderIf={!isDisabled}>
                                                                                    <CallToAction
                                                                                        className={classnames({
                                                                                            'align-self-end': isDesktop || isTablet,

                                                                                        })}
                                                                                        text={text?.client?.create?.title}
                                                                                        type='button'
                                                                                        onClick={() => setAddClient(!addClient)}
                                                                                        style={{
                                                                                            marginLeft: isDesktop || isTablet ? '1em' : 0,
                                                                                            marginTop: isDesktop || isTablet ? 0 : '1em'
                                                                                        }}
                                                                                    />
                                                                                </ConditionalRender>
                                                                            </div>
                                                                        </ConditionalRender>
                                                                    );
                                                                }}
                                                            </FormSpy>
                                                            <FormSpy subscription={{ values: true }}>
                                                                {({ values }) => {
                                                                    return (
                                                                        <ConditionalRender renderIf={!isEmpty(values?.clientId)}>
                                                                            <Field name="additionalEmails">
                                                                                {({ input, meta }) => (
                                                                                    <CreatableMultiInput
                                                                                        className='field-style'
                                                                                        input={input}
                                                                                        initialValue={initialAdditionalEmails}
                                                                                        meta={meta}
                                                                                        isClearable={true}
                                                                                        placeHolder={text?.proposals?.details?.additionalEmails}
                                                                                        width={'30%'}
                                                                                        setIsEditing={setIsEditing}
                                                                                        disabled={isDisabled}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </ConditionalRender>
                                                                    );
                                                                }}
                                                            </FormSpy>

                                                            <MultiFields
                                                                className='field-style'
                                                                name="emailSubject"
                                                                component="text"
                                                                type="text"
                                                                label={text?.proposals?.create?.emailSubject}
                                                                setIsEditing={setIsEditing}
                                                                initialValue={proposal?.emailSubject}
                                                                block
                                                                disabled={isDisabled}
                                                            />
                                                            <MultiFields
                                                                className='field-style'
                                                                name="emailMessage"
                                                                component="textarea"
                                                                type="text"
                                                                label={text?.proposals?.create?.emailMessage}
                                                                setIsEditing={setIsEditing}
                                                                initialValue={proposal?.emailMessage}
                                                                block
                                                                disabled={isDisabled}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        </FormSpy>

                                        <MultiFields
                                            className='field-style'
                                            name="title"
                                            component="text"
                                            type="text"
                                            label={text?.proposals?.details?.title}
                                            validate={required}
                                            setIsEditing={setIsEditing}
                                            initialValue={proposal?.title}
                                            block
                                        />
                                        <FieldsWrapper
                                            name={'fields'}
                                            validate={required}
                                            initialValue={proposal?.fields}
                                            setIsEditing={setIsEditing}
                                            disabled={isDisabled}
                                        />

                                        <ConditionalRender renderIf={!isEmpty(spyValues?.values?.fields)}>
                                            <div className='field-style'>
                                                <label className='mb-sm b flex'>
                                                    {text?.proposals?.details?.total}
                                                </label>
                                                <div className='custom-field flex'>
                                                    <NumericFormat
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        isNumericString={true}
                                                        name={'total'}
                                                        value={total}
                                                        placeholder={'$'}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </ConditionalRender>

                                        <Wrapper
                                            title={text?.projects?.details?.designs}
                                            button={
                                                <ConditionalRender renderIf={!isDisabled}>
                                                    <div className='image-uploader'>
                                                        {
                                                            true && (
                                                                <div
                                                                    className='dropzone'
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <span>
                                                                        {text?.inventory?.details?.uploadImages}
                                                                    </span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </ConditionalRender>
                                            }
                                        >
                                            <ConditionalRender renderIf={!isEmpty(images)}>
                                                <RenderImages
                                                    images={images}
                                                    roleName={user?.roleName}
                                                    isEditing={isEditing}
                                                    removeImage={removeImage}
                                                    openModal={openCarousel}
                                                    closeModal={closeModal}
                                                    setActiveIndex={setActiveIndex}
                                                    activeIndex={activeIndex}
                                                    isModalOpen={isModalOpen}
                                                    disabled={isDisabled}
                                                />
                                            </ConditionalRender>

                                            <ConditionalRender renderIf={isEmpty(images)}>
                                                <p className='mb-md'>
                                                    {text?.proposals?.details?.noImages}
                                                </p>
                                            </ConditionalRender>

                                            <ConditionalRender renderIf={proposal?.statusWasUpdated}>
                                                <hr />
                                                <FieldWrapper
                                                    name={text?.client?.create?.signature}
                                                    label={proposal?.signatureName}
                                                />
                                                <FieldWrapper
                                                    name={text?.client?.create?.signatureDate}
                                                    label={moment(proposal?.signatureDate).format('MM/DD/YYYY hh:mm A')}
                                                />
                                                <ConditionalRender renderIf={!isEmpty(proposal?.signature)}>
                                                    <FieldWrapper

                                                        noHr
                                                        label={
                                                            <CanvasSignature
                                                                showWhiteSignature={theme === 'dark'}
                                                                points={proposal?.signature && JSON.parse(proposal?.signature)}

                                                            />
                                                        }
                                                    />
                                                </ConditionalRender>
                                            </ConditionalRender>

                                            {
                                                !isEmpty(imageError) && (
                                                    <span style={{
                                                        color: colors.red
                                                    }}>
                                                        {imageError}
                                                    </span>
                                                )
                                            }
                                        </Wrapper>

                                        {
                                            (true) && (
                                                <FormActions
                                                    form={form}
                                                    type={'submit'}
                                                    onClick={onSubmitDraft}
                                                    secondaryText={spyValues?.values?.title && text?.proposals?.details?.save}
                                                    submitText={!isDisabled && text?.proposals?.details?.send}
                                                    btnStyle={{
                                                        color: colors.white
                                                    }}
                                                />
                                            )
                                        }
                                    </form>
                                )
                            }}
                        />
                        <ConditionalRender renderIf={addClient}>
                            <CreateNewClientContainer
                                isOpen={addClient}
                                toggle={() => setAddClient(false)}
                                user={user}
                                fetchAllClients={fetchAllClients}
                                setNewClient={setNewClient}
                                clients={clients}
                            />
                        </ConditionalRender>
                    </ConditionalRender>
                </ConditionalRender>
            </StyledDiv>
        )
}

export default ProposalDetailsContainer