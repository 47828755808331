import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";

import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarLogs from '../Core/CalendarLogs'
import CalendarLogsSmall from '../Core/CalendarLogsSmall'
import useScreenSize from '../context/useScreenSize'
import ConditionalRender from '../Core/ConditionalRender'
import queryString from "query-string";


const LogDetails = ({
    projects,
    fetchTimeLogs,
    timeLogs,
    logsIsLoading,
    user,
    tasks,
    fetchProjectTasks
}) => {
    const location = useLocation();

    const { id } = queryString.parse(location.search);

    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const assignedProjects = projects?.filter(project => {
        // Check if the user is an admin
        const isAdmin = project.assignedEmployees?.some(employee => employee?.id === user?.id);
        const isProjectCompleted = project?.projectStatus === 'completed';

        return (isAdmin || user?.roleName === 'Admin') && !isProjectCompleted;
    });

    const getTitleFromMilliseconds = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let hoursText = hours === 1 ? 'hour' : 'hours';
        let minutesText = remainingMinutes === 1 ? 'minute' : 'minutes';

        if (hours > 0) {
            if (remainingMinutes > 0) {
                // Both hours and minutes
                return `${hours} ${hoursText} and ${remainingMinutes} ${minutesText}`;
            } else {
                // Only whole hours
                return `${hours} ${hoursText}`;
            }
        } else {
            // Only minutes
            return `${minutes} ${minutesText}`;
        }
    }


    const transformTimesToEvents = (times) => {
        // Helper function to get the start of the day in UTC
        const getStartOfDayInUTC = (dateStr) => {
            const date = new Date(dateStr);
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();
        };

        // Create an object to hold the groups
        const groupedByDay = times?.reduce((acc, current) => {
            if (current && current?.taskTimes?.length > 0) {
                // Extract the day part of the clockIn timestamp as the key
                const date = new Date(current.clockIn);
                const dayKey = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();

                // If the key doesn't exist in the accumulator, initialize it
                if (!acc[dayKey]) {
                    acc[dayKey] = {
                        totalTime: 0,
                        isRunning: false,
                        dailyLog: current.dailyLog,
                        tasks: [], // Store tasks individually
                        totalTimeText: '', // Added a key for the text representation of the total time
                        clockIn: getStartOfDayInUTC(dayKey), // Initialize using getStartOfDayInUTC
                    };
                }

                // Iterate over taskTimes
                current.taskTimes.forEach((task) => {
                    acc[dayKey].totalTime += task.timeSpent; // Sum total time for the day
                    acc[dayKey].totalTimeText = getTitleFromMilliseconds(acc[dayKey].totalTime); // Update totalTimeText
                    if (task.isRunning) {
                        acc[dayKey].isRunning = true; // Update isRunning if any task is running
                    }

                    // Add task details to the tasks array
                    acc[dayKey].tasks.push({
                        id: task.taskId?._id,
                        taskId: task.taskId?._id,
                        taskTitle: task.taskId?.taskTitle || 'Untitled Task',
                        isArchived: task.taskId?.isArchived || false,
                        projectId: task.projectId?._id,
                        projectName: task.projectId?.projectName || 'Untitled Project',
                        adminEmployees: task.projectId?.adminEmployees || [], // Include adminEmployees array
                        assignedEmployees: task.projectId?.assignedEmployees || [], // Include assignedEmployees array
                        timeSpent: task.timeSpent,
                        taskTotalTimeText: getTitleFromMilliseconds(task.timeSpent),
                        isRunning: task.isRunning,
                    });
                });
            }

            return acc;
        }, {});

        return groupedByDay;
    };


    useEffect(() => {
        fetchProjectTasks(false, false, id)
    }, [id])


    return (
        <>
            <ConditionalRender renderIf={isDesktop}>
                <CalendarLogs
                    fetchTimeLogs={fetchTimeLogs}
                    logsIsLoading={logsIsLoading}
                    timeLogs={timeLogs}
                    transformTimesToEvents={transformTimesToEvents}
                    getTitleFromMilliseconds={getTitleFromMilliseconds}
                    projects={assignedProjects}
                    user={user}
                    tasks={tasks}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isTablet || isPhone}>
                <CalendarLogsSmall
                    fetchTimeLogs={fetchTimeLogs}
                    logsIsLoading={logsIsLoading}
                    timeLogs={timeLogs}
                    transformTimesToEvents={transformTimesToEvents}
                    getTitleFromMilliseconds={getTitleFromMilliseconds}
                    projects={assignedProjects}
                    user={user}
                    tasks={tasks}
                />
            </ConditionalRender>
        </>
    )
}

export default LogDetails