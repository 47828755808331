import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import styled from 'styled-components';
import colors from '../globalStyles.scss';
import { btnUnderline } from '../mixins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from './HoverPopup';
import classnames from 'classnames'
import CallToAction from '../Core/CallToAction';
import { useTheme } from '../context/ThemeContext';
import useScreenSize from '../context/useScreenSize';
import ConditionalRender from './ConditionalRender';

const StyledButtons = styled.div`

display: flex;
justify-content: end;
position: ${({ floating }) => floating ? 'fixed' : 'static'};
z-index: ${({ zIndex }) => zIndex};
align-self: end;
button {
    display: flex;
    position: relative;
    font-family: ${colors.openSans};
    letter-spacing: ${colors.letterSpacing};
    color: ${props => props.theme === 'dark' ? colors.white : colors.black};
    ...${(props) => props.btnStyle}
}
svg {
    font-size: 1.5em;
}
    .primary-button, .secondary-button {
        padding: 0;
        padding-left: 0.5em;
    }
.secondary {
    padding-bottom: 5px;
    ${(props) => !props.disabled && btnUnderline(props.theme === 'dark' ? colors.white : colors.black)};

}
.primary {
        padding-bottom: 5px;
        color: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightGray) : (props.theme === 'dark' ? colors.white : colors.black)};
        ${(props) => !props.disabled && btnUnderline(props.theme === 'dark' ? colors.white : colors.black)};
    }
`

const FormActions = ({
    disabled,
    onClick,
    resetForm,
    form,
    submitText,
    callToActionText,
    secondaryText,
    type,
    btnStyle,
    floating,
    position = 'end',
    zIndex = 1
}) => {
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [errorFields, setErrorFields] = useState([]);

    const scrollToField = (fieldName) => {
        let fieldElement = null;

        // Escape square brackets for querySelector
        const safeFieldName = fieldName.replace(/\[/g, '\\[').replace(/\]/g, '\\]');

        // Try to find the field by name attribute
        fieldElement = document.querySelector(`[name="${safeFieldName}"], .${safeFieldName}`);

        // Scroll to the element if found
        if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: 'smooth' });
            fieldElement.focus();  // Optionally focus the element
        } else {
            console.error(`Could not find field with name "${safeFieldName}"`);
        }
    };

    useEffect(() => {
        if (Object.keys(errorFields).length > 0) {
            // Iterate over the error fields and scroll to the first invalid field
            for (const field in errorFields) {
                if (Array.isArray(errorFields[field])) {
                    // Handle array fields (e.g., fields[0].description)
                    errorFields[field].forEach((item, index) => {
                        for (const subField in item) {
                            // Build the name for the nested field (e.g., fields[0].description)
                            const fieldName = `${field}[${index}].${subField}`;
                            scrollToField(fieldName);
                            return; // Scroll to the first error and stop
                        }
                    });
                } else {
                    // Scroll to simple field (e.g., title, clientId)
                    scrollToField(field);
                    return; // Scroll to the first error and stop
                }
            }
        }
    }, [errorFields]);  // Run effect when errorFields change

    const handleConfirmClick = async () => {
        if (form) {
            const formState = form.getState();
            const { errors } = formState;

            if (Object.keys(errors).length > 0) {
                // Store the error field names in state
                setErrorFields(errors);
            } else {
                setErrorFields([]); // Clear error fields if no errors
            }

            // Submit the form if no errors
            form.submit();
        }
    };

    return (
        <div className={classnames({
            'flex items-center w-100 FormActions': true,
            'justify-end': position === 'end',
            'justify-center': position === 'center'
        })}>
            {floating ? (
                // Floating buttons
                <StyledButtons
                    disabled={disabled}
                    floating={floating}
                    theme={theme}
                    zIndex={zIndex}
                    className={isDesktop ? 'desktop  buttons' : isTablet ? 'tablet buttons' : 'phone buttons'}

                >
                    {secondaryText && (
                        <HoverPopup
                            className='popup'
                            btnClassName={'secondary-button'}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                background: 'transparent',
                                border: 'none',
                                hoverBackground: 'transparent',
                                color: colors.red,

                            }}
                            placement={'top'}
                            id={secondaryText}
                            text={isDesktop && secondaryText}
                        >
                            <Fab
                                variant="extended"
                                size={isDesktop ? 'large' : 'large'}
                                aria-label={secondaryText}
                                disabled={disabled}
                                onClick={(values) => {
                                    onClick && onClick(values);
                                    resetForm && resetForm();
                                }}
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </Fab>
                        </HoverPopup>
                    )}

                    {submitText && (
                        <HoverPopup
                            btnClassName={'primary-button'}
                            className='popup'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                background: 'transparent',
                                border: 'none',
                                hoverBackground: 'transparent',
                                color: colors.blue
                            }}
                            placement={'top'}
                            id={submitText}
                            text={isDesktop && submitText}
                            onClick={handleConfirmClick}
                        >
                            <Fab
                                variant="extended"
                                size={isDesktop ? 'large' : 'large'}
                                aria-label={submitText}
                                disabled={disabled}
                                type={type}
                                style={btnStyle}
                            >
                                <FontAwesomeIcon icon={faSave} />
                            </Fab>
                        </HoverPopup>
                    )}
                </StyledButtons>
            ) : (
                // Original buttons
                <StyledButtons disabled={disabled} btnStyle={btnStyle} theme={theme}>
                    <div className='flex items-center'>
                        {secondaryText && (
                            <button
                                type="button"
                                onClick={(values) => {
                                    onClick && onClick(values);
                                    resetForm && resetForm();
                                }}
                                className='secondary'
                                disabled={disabled}
                            >
                                {secondaryText}
                            </button>
                        )}
                        {submitText && (
                            <>
                                <ConditionalRender renderIf={secondaryText}>
                                    <span>
                                        {' | '}
                                    </span>
                                </ConditionalRender>
                                <button
                                    type={type}
                                    disabled={disabled}
                                    className='primary'
                                    onClick={handleConfirmClick}
                                >
                                    {submitText}
                                </button>
                            </>
                        )}
                        {
                            callToActionText && (
                                <CallToAction
                                    btnWidth='auto'
                                    btnHeight={'auto'}
                                    type={type}
                                    text={callToActionText}
                                />
                            )
                        }
                    </div>
                </StyledButtons>
            )}
        </div>
    );
};

export default FormActions;
