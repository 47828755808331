import React from 'react'
import { isEmpty } from 'lodash'
import ConditionalRender from '../Core/ConditionalRender'
import CheckBox from '../Core/Inputs/CheckBox'
import SelectInput from '../Core/SelectInput';
import { useLanguage } from '../context/LanguageContext';


const InventoryModalMessage = ({
    type,
    projects,
    id,
    inventory,
    deleteInventory,
    setDeleteInventory,
    assignedProject,
    setAssignedProject,
    showAssignedProject,
    setShowAssignedProject
}) => {
    const { text } = useLanguage();

    // Conditionally filter projects if type is 'delete'
    const filteredProjects = type === 'delete'
        ? projects?.filter(x => x.id !== id && x?.projectStatus !== 'completed' && x?.features?.inventory)
        : projects?.filter(x => x?.projectStatus !== 'completed' && x?.features?.inventory);

    // Construct options array outside of the JSX to simplify the expression
    const options = [
        { label: text?.projects?.details?.inventoryModal?.unassigned, value: '000000000000000000000000' },
        ...(filteredProjects?.map(x => ({
            label: x?.projectName,
            value: x?.id
        })) || [])
    ];
    return (
        <ConditionalRender renderIf={true}>
            <div className='w-100'>
                <ConditionalRender renderIf={type !== 'delete'}>
                    <h3>
                        {text?.projects?.details?.modal?.title}
                    </h3>
                    <p className='mt-md'>
                        {text?.projects?.details?.modal?.textone}
                    </p>
                    <p>
                        {text?.projects?.details?.modal?.texttwo}
                    </p>
                    <p>
                        {text?.projects?.details?.modal?.textthree}
                    </p>
                </ConditionalRender>
                <ConditionalRender renderIf={type === 'delete'}>
                    <h3 className='mb-md'>
                        {text?.projects?.details?.deleteModal?.title}
                    </h3>
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(inventory)}>
                    <p>
                        {text?.projects?.details?.inventoryModal?.title}
                    </p>
                    <ul className='mt-sm mb-md'>
                        {inventory?.map((product) => (
                            <li key={product.productId}>
                                - {product.inventoryName}
                            </li>
                        ))}
                    </ul>
                    <ConditionalRender renderIf={type !== 'delete'}>
                        <p className='mb-sm'>
                            {text?.projects?.details?.inventoryModal?.text}
                        </p>
                    </ConditionalRender>
                    <CheckBox
                        checked={deleteInventory}
                        label={text?.projects?.details?.inventoryModal?.check}
                        onChange={() => {
                            setDeleteInventory((prev) => {
                                if (!prev) {
                                    if (type !== 'delete') {
                                        const project = projects.find(p => p.id === id);
                                        if (project) {
                                            setAssignedProject({
                                                value: project.id,
                                                label: project.projectName
                                            });
                                        }
                                    } else {
                                        setAssignedProject({
                                            value: '000000000000000000000000',
                                            label: text?.projects?.details?.inventoryModal?.unassigned
                                        })
                                    }
                                    setShowAssignedProject(true)
                                } else {
                                    setShowAssignedProject(false)
                                }
                                return !deleteInventory
                            })
                        }}
                    />
                    <ConditionalRender renderIf={!showAssignedProject}>
                        <label className='mt-md b'>
                            {text?.projects?.details?.inventoryModal?.projects}
                        </label>
                        <SelectInput
                            name='projectId'
                            placeholder={text?.projects?.details?.selectProject}
                            onChange={(e) => setAssignedProject(e)}
                            isValidNewOption={() => false}
                            initValue={assignedProject}
                            options={options}
                        />
                    </ConditionalRender>
                </ConditionalRender>
            </div>
        </ConditionalRender >
    )
}

export default InventoryModalMessage