import React from 'react';
import moment from 'moment';
import colors from '../../globalStyles.scss';
import ConditionalRender from '../../Core/ConditionalRender';
import { findFieldByName } from '../../utils/helperFunctions';

import { isEmpty } from 'lodash';

const CustomToolTip = ({
    payload,
    combineKey,
    dataCheckedKeys,
    numberData,
    showTotal,
    arithmetic,
    fieldNames,
    text
}) => {

    const advanceFieldName = combineKey
    const isDate = advanceFieldName?.split('-')[0] === 'date'
    const getArithmeticFunction = (operation) => {
        switch (operation) {
            case 'add':
                return (a, b) => a + b;
            case 'subtract':
                return (a, b) => a - b;
            case 'multiply':
                return (a, b) => a * b;
            case 'divide':
                return (a, b) => parseFloat((a / b).toFixed(2));
            default:
                return null;
        }
    };

    const getInitialValue = (operation, payload) => {
        switch (operation) {
            case 'add':
                return 0;
            case 'subtract':
            case 'divide':
                return payload?.length ? parseInt(payload[0].value) : 0;
            case 'multiply':
                return 1;
            default:
                return 0;
        }
    };

    let total;
    if (!arithmetic) {
        total = null;
    } else {
        const arithmeticFunc = getArithmeticFunction(arithmetic);
        const initialValue = getInitialValue(arithmetic, payload);

        if (arithmeticFunc) {
            if (arithmetic === 'subtract' || arithmetic === 'divide') {
                total = payload?.slice(1).reduce((result, entry) => arithmeticFunc(result, parseInt(entry.value)), initialValue);
            } else {
                total = payload?.reduce((result, entry) => arithmeticFunc(result, parseInt(entry.value)), initialValue);
            }
        } else {
            console.error(`Unknown arithmetic operation: ${arithmetic}`);
            total = null;
        }
    }


    if (payload && payload.length > 0) {
        // Find the associated string data using the productId
        const payloadKey = combineKey || 'inventoryName';
        const associatedStringData = numberData?.filter(item =>
            item[payloadKey]?.toLowerCase() === (payload[0]?.payload?.[payloadKey]?.toLowerCase())
        );
        const associatedAdvanceFieldStringData = numberData?.filter(item =>
        (
            item?.advanceFields?.[advanceFieldName]?.toLowerCase() === payload[0]?.payload?.advanceFields?.[advanceFieldName]?.toLowerCase() ||
            (isDate && moment.utc(item?.advanceFields?.[advanceFieldName]).format('MM-DD-YYYY') === moment.utc(payload[0]?.payload?.advanceFields?.[advanceFieldName]).format('MM-DD-YYYY'))
        )
        );

        const arithmeticResultWords = {
            add: 'Sum',
            subtract: 'Difference',
            multiply: 'Product',
            divide: 'Quotient'
        };

        if (associatedStringData?.length > 0 || associatedAdvanceFieldStringData?.length > 0) {
            return (
                <div style={{ backgroundColor: colors.backgroundSecondary, border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <div style={{
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        color: colors.white
                    }}>
                        {
                            payload[0].payload?.[combineKey || 'inventoryName']
                            || payload[0].payload?.advanceFields[advanceFieldName]
                        }
                    </div>
                    <ConditionalRender renderIf={dataCheckedKeys.length > 1 && showTotal && !isEmpty(arithmetic)}>
                        <span>
                            {`${arithmeticResultWords[arithmetic]} Result: ${total}`}
                        </span>
                    </ConditionalRender>


                    {
                        payload.map(entry => {
                            const dataKeyParts = entry?.dataKey?.split('.');
                            const field = findFieldByName(entry?.dataKey, fieldNames, text);


                            let renderKey;
                            if (dataKeyParts.length > 1 && dataKeyParts[0] === 'advanceFields') {
                                renderKey = dataKeyParts[1]?.split('-')[0];
                            } else {
                                renderKey = entry?.dataKey;
                            }
                            const displayValue = field?.inputType === 'amount'
                                ? `$${entry.value}`
                                : entry.value;

                            return (
                                <div key={renderKey}>
                                    <span style={{ color: colors.white }}>
                                        {field?.label}: {displayValue}
                                    </span>
                                </div>
                            );
                        })
                    }

                </div>
            );
        }
    }
    return null;
}

export default CustomToolTip