import React, { useEffect, useRef } from "react";

const validateAndParsePoints = (rawPoints) => {
    if (!Array.isArray(rawPoints)) {
        console.warn("Invalid points data: Not an array", rawPoints);
        return [];
    }

    // Flatten the raw points if they are stringified JSON
    const parsedPoints = rawPoints.flatMap(point => {
        try {
            // Parse if it's a string
            const parsed = typeof point === 'string' ? JSON.parse(point) : point;

            // Ensure parsed data is an array of [x, y]
            if (
                Array.isArray(parsed) &&
                parsed.every(p => Array.isArray(p) && p.length === 2 && p.every(coord => typeof coord === "number"))
            ) {
                return [parsed]; // Wrap parsed path to include in flatMap
            } else {
                console.warn("Invalid point skipped:", point);
                return [];
            }
        } catch (error) {
            console.error("Error parsing point:", point, error);
            return [];
        }
    });

    // Ensure result is an array of valid paths
    return parsedPoints.filter(path => path.length > 0);
};


const calculateBoundingBox = (points) => {
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

    points.forEach(path => {
        path.forEach(([x, y]) => {
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
        });
    });

    return { minX, minY, maxX, maxY };
};



const CanvasSignature = ({ points = [], padding = 20, showWhiteSignature = false }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Validate and parse points
        const validPoints = validateAndParsePoints(points);

        // Calculate bounding box
        const { minX, minY, maxX, maxY } = calculateBoundingBox(validPoints);

        // Adjust canvas size dynamically
        const width = maxX - minX + padding * 2;
        const height = maxY - minY + padding * 2;
        canvas.width = width;
        canvas.height = height;

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Set styles
        ctx.strokeStyle = showWhiteSignature ? "#fff" : "#000";
        ctx.lineWidth = 2;

        // Iterate through the validated paths
        validPoints.forEach((path) => {
            ctx.beginPath();
            path.forEach(([x, y], index) => {
                const adjustedX = x - minX + padding; // Adjust for padding
                const adjustedY = y - minY + padding; // Adjust for padding
                if (index === 0) {
                    ctx.moveTo(adjustedX, adjustedY); // Move to the starting point
                } else {
                    ctx.lineTo(adjustedX, adjustedY); // Draw line to the next point
                }
            });
            ctx.stroke(); // Render the path
        });
    }, [points, padding]);

    return (
        <div style={{ overflow: "auto" }}>
            <canvas
                ref={canvasRef}
                style={{
                    border: "1px solid #ddd",
                    display: "block",
                    margin: "0 auto",
                }}
            ></canvas>
        </div>
    );
};

export default CanvasSignature;