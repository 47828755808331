import React, { useMemo, useState, useCallback } from 'react'
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import colors from '../globalStyles.scss';

const ShowJustMap = ({
    location
}) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [autocompleteValue, setAutocompleteValue] = useState('');
    const [infoWindowOpen, setInfoWindowOpen] = useState(true);

    const handleSelectLocation = useCallback((place) => {
        if (place.formatted_address && place.geometry && place.geometry.location) {
            setSelectedLocation(place);
            setAutocompleteValue(place.formatted_address);
        }
    }, []);
    useMemo(() => {
        const fetchInitialLocation = (address) => {
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK') {
                    handleSelectLocation(results[0]);
                } else {
                    console.error('Geocode was not successful for the following reason: ' + status);
                }
            });
        };

        fetchInitialLocation(location);
    }, [handleSelectLocation, location]);

    const mapCenter = useMemo(() => {
        const lat = parseFloat(selectedLocation?.geometry?.location?.lat());
        const lng = parseFloat(selectedLocation?.geometry?.location?.lng());
        return {
            lat,
            lng
        };
    }, [selectedLocation]);

    const onLoad = useCallback((map) => {
        if (selectedLocation) {

            map.panTo(new window.google.maps.LatLng(mapCenter?.lat, mapCenter?.lng));
        }
    }, [mapCenter, selectedLocation]);

    const handleMarkerClick = () => {
        setInfoWindowOpen(!infoWindowOpen);
    };

    return (
        <GoogleMap
            mapContainerStyle={{
                width: '100%',
                height: '400px',
                marginTop: '15px',
                marginBottom: '15px'
            }}
            center={mapCenter}
            onLoad={onLoad}
            mapTypeId="hybrid"
            zoom={18}
            options={{
                mapTypeId: 'hybrid', // Explicitly set the map type here
                disableDefaultUI: false, // disable default map UI
                draggable: true, // disable dragging (panning)
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: false, // disable scaling (zooming in/out) control
                scrollwheel: false, // disable scrolling (zooming in/out) control
                zoomControl: true, // disable zoom control buttons
                fullscreenControl: false,
                streetViewControl: false,
                version: false,
                styles: [
                    {
                        featureType: 'all',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }] // Hide all labels
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [{ visibility: 'off' }] // Hide landscapes
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [{ visibility: 'off' }] // Hide points of interest
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [{ visibility: 'simplified' }] // Show roads, but not in full detail
                    },
                    {
                        featureType: 'road',
                        elementType: 'labels.text',  // Specify the road's text element to modify the road names separately
                        stylers: [{ visibility: 'on' }]  // Show road names
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ visibility: 'off' }] // Hide transit
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ visibility: 'off' }] // Hide water
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry',
                        stylers: [{ visibility: 'off' }] // Hide administrative buildings
                    },
                    {
                        featureType: 'landscape.man_made', // Show only man-made landscapes (e.g., your building)
                        elementType: 'geometry',
                        stylers: [{ visibility: 'on' }]
                    }
                ],
            }}
        >
            <MarkerF
                position={mapCenter}
                onClick={handleMarkerClick}

            >
                {infoWindowOpen && (
                    <InfoWindowF position={mapCenter} onCloseClick={() => setInfoWindowOpen(!infoWindowOpen)}>
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(autocompleteValue)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: colors.blue }}
                        >
                            {autocompleteValue}
                        </a>
                    </InfoWindowF>
                )}
            </MarkerF>
        </GoogleMap>
    )
}

export default ShowJustMap
