import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import ChartsContainer from './ChartsContainer'
import TimeAnalysisContainer from './TimeAnalysisContainer';
import TasksAnalysisContainer from './TasksAnalysisContainer';
import { isEmpty, set } from 'lodash'
import { Form, FormSpy } from 'react-final-form';
import FormActions from '../Core/FormActions'
import moment from 'moment'
import { separateData, combineData } from './utils/helperFunctions';
import {
    saveWidget,
    getAllAnalytics,
    getLogs
} from '../utils/calls'
import { renderLabel } from '../Dashboard/Charts/helperFunctions'
import ConfirmModal from '../Core/ConfirmModal'
import ConditionalRender from '../Core/ConditionalRender'
import colors from '../globalStyles.scss'
import FieldWrapper from '../Core/FieldWrapper'
import SelectInput from '../Core/SelectInput';
import { useLanguage } from '../context/LanguageContext'
import useScreenSize from '../context/useScreenSize';


const AnalyticsContainer = ({
    inventory,
    inventoryColumns,
    projects,
    fetchInventoryProducts,
    employees,
    user,
    tasks,
    tasksIsLoading,
    attributes,
    fetchAttributes,
}) => {
    const formRef = useRef();
    const { text } = useLanguage()
    const { isDesktop } = useScreenSize();
    const [spyValues, setSpyValues] = useState();
    const [inventoryData, setInventoryData] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [combineKey, setCombineKey] = useState('');
    const [formattedData, setFormattedData] = useState([]);
    const [info, setInfo] = useState([]);
    const [infoIsOpen, setInfoIsOpen] = useState(false);
    const [chartIsEditing, setChartIsEditing] = useState(false);

    const [stringData, setStringData] = useState([]);
    const [numberData, setNumberData] = useState([]);
    const [dataCheckedKeys, setDataCheckedKeys] = useState([]);

    const [analyticsWidgets, setAnalyticsWidgets] = useState([]);
    const [analyticsWidgetsLoading, setAnalyticsWidgetsLoading] = useState(true);

    const [initialGroupBy, setInitialGroupBy] = useState({});
    const [initialProject, setInitialProject] = useState({});
    const [initialChartType, setInitialChartType] = useState({});
    const [initialEmployee, setInitialEmployee] = useState({});

    const [initialTasksGroupBy, setInitialTasksGroupBy] = useState({});

    const [allTimeLogs, setAllTimeLogs] = useState([]);
    const [allTimeLogsLoading, setAllTimeLogsLoading] = useState(true);

    const [timeFilters, setTimeFilters] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });

    const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM'));
    const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));


    const fetchAllTimeLogs = async (projectId, employeeId) => {
        setAllTimeLogsLoading(true);
        const showAllProjects = projectId === 'all';
        const showAllEmployees = employeeId === 'all';
        const projectFilterId = showAllProjects ? null : projectId;
        const employeeFilterId = showAllEmployees ? null : employeeId;

        try {
            const res = await getLogs('all', projectFilterId, showAllProjects);
            if (res.status === 200) {
                const filteredLogs = res.data?.filter(log => {
                    const matchesProject = showAllProjects || log.projectId?.id === projectFilterId;
                    const matchesEmployee = showAllEmployees || log.userId?.id === employeeFilterId;
                    return matchesProject && matchesEmployee && log.projectId && log.projectId?.projectStatus !== 'completed';
                });
                setAllTimeLogs(filteredLogs);
            }
        } catch (error) {
            console.error('Error in fetchAllTimeLogs', error);
        } finally {
            setAllTimeLogsLoading(false);
        }
    };


    useMemo(() => {
        const chartInitialData = analyticsWidgets.find(widget => widget.id === 'inventory');
        const timeInitialData = analyticsWidgets.find(widget => widget.id === 'timeTracker');
        const tasksInitialData = analyticsWidgets.find(widget => widget.id === 'tasks');
        const trueKeys = Object.keys(chartInitialData?.dataCheckedKeys || {}).filter(key => chartInitialData.dataCheckedKeys[key] === true);
        setInitialGroupBy({
            label: chartInitialData?.groupBy?.label || text?.analytics?.name,
            value: chartInitialData?.groupBy?.value || 'inventoryName'
        })
        setInitialProject({
            label: chartInitialData?.selectProject?.label || text?.analytics?.all,
            value: chartInitialData?.selectProject?.value || 'all'
        })
        fetchAllTimeLogs(chartInitialData?.selectProject?.value)

        setInitialChartType({
            value: chartInitialData?.chartType?.value || 'barChart',
            label: chartInitialData?.chartType?.label || 'Bar Chart'
        })

        setInitialEmployee({
            label: timeInitialData?.selectEmployee?.label || text?.analytics?.all,
            value: timeInitialData?.selectEmployee?.value || 'all'
        })

        setInitialTasksGroupBy({
            label: tasksInitialData?.tasksGroupBy?.label || 'Status',
            value: tasksInitialData?.tasksGroupBy?.value || 'taskStatus'
        })

        setTimeFilters({
            startDate: moment(timeInitialData?.dateRange?.startDate) || moment().startOf('month'),
            endDate: moment(timeInitialData?.dateRange?.endDate) || moment().endOf('month')
        })

        setCombineKey(chartInitialData?.groupBy?.value || 'inventoryName')
        formRef?.current?.change('groupBy', chartInitialData?.groupBy)
        formRef?.current?.change('selectProject', chartInitialData?.selectProject)
        formRef?.current?.change('chartType', chartInitialData?.chartType)
        formRef?.current?.change('selectEmployee', timeInitialData?.selectEmployee)
        formRef?.current?.change('selectYear', timeInitialData?.selectYear)
        formRef?.current?.change('selectMonth', timeInitialData?.selectMonth)
        formRef?.current?.change('tasksGroupBy', tasksInitialData?.tasksGroupBy)


    }, [analyticsWidgets])

    useEffect(() => {
        const chartInitialData = analyticsWidgets.find(widget => widget.id === 'inventory');
        const timeInitialData = analyticsWidgets.find(widget => widget.id === 'timeTracker');
        const tasksInitialData = analyticsWidgets.find(widget => widget.id === 'tasks');
        const trueKeys = Object.keys(chartInitialData?.dataCheckedKeys || {}).filter(key => chartInitialData.dataCheckedKeys[key] === true);

        setTimeout(() => {
            if (tasksInitialData?.dateStarted) formRef?.current?.change('dateStarted', moment(tasksInitialData?.dateStarted))
            if (tasksInitialData?.dateCompleted) formRef?.current?.change('dateCompleted', moment(tasksInitialData?.dateCompleted))
            formRef?.current?.change('dateRange.startDate', moment(timeInitialData?.dateRange?.startDate))
            formRef?.current?.change('dateRange.endDate', moment(timeInitialData?.dateRange?.endDate))
            formRef?.current?.change('dataCheckedKeys', chartInitialData?.dataCheckedKeys)
            setDataCheckedKeys(trueKeys)
        }, 100)
    }, [analyticsWidgets])

    useEffect(() => {
        fetchInventoryProducts()
        fetchAnalyticsWidgets()
    }, [])

    const fetchAnalyticsWidgets = async () => {
        setAnalyticsWidgetsLoading(true);
        try {
            const res = await getAllAnalytics();
            if (res.status === 200) {
                setAnalyticsWidgets(res.data?.analyticsWidgets);
                setAnalyticsWidgetsLoading(false);
            }
        } catch (error) {
            setAnalyticsWidgetsLoading(false);
            console.error('Error in fetchAnalyticsWidgets', error);
        }
    };

    useEffect(() => {
        const [stringsData, numbersData] = separateData(inventoryData);
        setStringData(stringsData);
        setNumberData(numbersData);
    }, [inventoryData])

    useEffect(() => {
        setFormattedData(numberData)
        handleCombineChange(combineKey, numberData)
    }, [numberData])

    const handleClear = (form) => {
        const [stringsData, numbersData] = separateData(inventoryData);
        setStringData(stringsData);
        setNumberData(numbersData);
        // setcombinedIsActive(false);
        setCombineKey('');
        form.change('groupBy', null);
    };

    const handleChartChange = (e, form) => {
        form.change('selectProject', { label: text?.analytics?.all, value: 'all' });
        setChartIsEditing(true);
    }

    const handleEmployeeChange = (e, form) => {
        form.change('selectEmployee', e);
        setChartIsEditing(true);
    }

    const handleSelectedDateChange = (e, form) => {
        form.change(e?.name, e?.value);
        setChartIsEditing(true);
    }

    const handleSave = async (values) => {
        console.log('console:   ', values)
        // Filter out false values from dataCheckedKeys
        const filteredDataCheckedKeys = Object.fromEntries(
            Object.entries(values.dataCheckedKeys).filter(([key, value]) => value === true)
        );

        const inventory = {
            id: 'inventory',
            chartType: values.chartType,
            selectProject: values.selectProject,
            dataCheckedKeys: filteredDataCheckedKeys, // Use the filtered object here
            groupBy: values.groupBy
        };

        const timeTracker = {
            id: 'timeTracker',
            selectProject: values.selectProject,
            selectEmployee: values.selectEmployee,
            selectYear: values.selectYear,
            selectMonth: values.selectMonth,
            dateRange: values.dateRange,
        }

        const tasks = {
            id: 'tasks',
            tasksGroupBy: values.tasksGroupBy,
            selectProject: values.selectProject,
            selectEmployee: values.selectEmployee,
            dateStarted: values.dateStarted && moment(values.dateStarted).toISOString(),
            dateCompleted: values.dateCompleted && moment(values.dateCompleted).toISOString()
        };

        try {
            const res = await saveWidget({ inventory, timeTracker, tasks });
            if (res.status === 200) {
                setChartIsEditing(false);
                fetchAnalyticsWidgets();
            }
        } catch (error) {
            console.error('Error in saveCharts', error);
        }
    };

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        setInventoryData(
            inventory.filter(x => {
                if (selectedProject === '000000000000000000000000') {
                    return x.projectId === null; // Return only items with projectId as null
                } else if (selectedProject === 'all') {
                    return true; // Return all items
                } else {
                    return x.projectId?.id === selectedProject;
                }
            })
        );
    }, [spyValues?.selectProject, inventory]);

    const adjustToLocalTime = (dateString) => {
        if (!dateString) {
            return null; // Return null if the dateString is invalid or missing
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return null; // Return null if the date is invalid
        }

        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString().split('T')[0];
    };

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        const selectedEmployee = spyValues?.selectEmployee?.value;
        const selectedDateStarted = spyValues?.dateStarted;
        const selectedDateCompleted = spyValues?.dateCompleted;

        setTaskData(
            tasks
                .filter(x => x?.taskProject?.projectStatus !== 'completed')
                .filter(x => {
                    if (selectedProject === 'all') {
                        return true;
                    } else {
                        return x.taskProject?.id === selectedProject;
                    }
                })
                .filter(x => {
                    if (selectedEmployee === '000000000000000000000000') {
                        return x.taskAssignedTo === null; // Return only items with projectId as null
                    } else if (selectedEmployee === 'all') {
                        return true;
                    } else {
                        return x.taskAssignedTo?.id === selectedEmployee;
                    }
                })
                .filter(x => {
                    // No date filters selected, include all tasks
                    if (!selectedDateStarted && !selectedDateCompleted) {
                        return true;
                    }

                    const taskDateStarted = adjustToLocalTime(x.dateStarted);
                    const taskDateCompleted = adjustToLocalTime(x.dateCompleted);
                    const selectedDateStartedString = selectedDateStarted?.format('YYYY-MM-DD');
                    const selectedDateCompletedString = selectedDateCompleted?.format('YYYY-MM-DD');

                    // Include tasks that match either dateStarted or dateCompleted or both
                    const matchesDateStarted = selectedDateStarted && taskDateStarted === selectedDateStartedString;
                    const matchesDateCompleted = selectedDateCompleted && taskDateCompleted === selectedDateCompletedString;

                    // Task is included if it matches either or both date filters
                    return matchesDateStarted || matchesDateCompleted;
                })
        );
    }, [spyValues?.selectProject, spyValues?.selectEmployee, spyValues?.dateStarted, spyValues?.dateCompleted, tasks]);

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        const selectedEmployee = spyValues?.selectEmployee?.value;
        if (selectedProject && selectedEmployee) {
            fetchAllTimeLogs(selectedProject, selectedEmployee);
        }
    }, [spyValues?.selectProject, spyValues?.selectEmployee]);

    useMemo(() => {
        const formattedData = inventory.map(item => {
            // Destructure to remove unwanted keys
            const { id, _id, images, __v, companyId, ...rest } = item;

            const obj = { ...rest };

            if (item.advanceFields) {
                let newAdvanceFields = {};
                Object.keys(item.advanceFields).forEach(key => {
                    if (key.startsWith('number-') && typeof item.advanceFields[key]?.value === 'number') {
                        newAdvanceFields[key] = { value: item.advanceFields[key].value };
                    } else {
                        newAdvanceFields[key] = item.advanceFields[key];
                    }
                });
                obj.advanceFields = newAdvanceFields;
            }

            // Handle other fields that may have the `.value` structure
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object' && obj[key]?.value !== undefined) {
                    obj[key] = { value: obj[key].value };
                }
            });

            return obj;
        });

        setInventoryData(formattedData);
    }, [inventory]);

    const handleCombineChange = useCallback((value, arr) => {
        if (value) {
            setCombineKey(value)
            let newData
            if (arr) {
                newData = combineData(value, arr);
            } else {
                newData = combineData(value, formattedData);
            }
            setFormattedData(newData);
        }

    }, [formattedData])

    useEffect(() => {
        const month = moment(selectedMonth, 'MMMM').month();
        const year = parseInt(selectedYear, 10);
        const startDate = moment({ year, month, day: 1 });
        const endDate = startDate.clone().endOf('month');
        setTimeFilters({ startDate, endDate });
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        const startDate = timeFilters.startDate;
        if (startDate) {
            setSelectedMonth(startDate.format('MMMM'));
            setSelectedYear(startDate.format('YYYY'));
            formRef?.current?.change('selectMonth', startDate.format('MMMM'));
            formRef?.current?.change('selectYear', startDate.format('YYYY'));
        }
    }, [timeFilters.startDate]);

    const handleDatesChange = ({ startDate, endDate }) => {
        setTimeFilters({ startDate, endDate });
        if (startDate) {
            setSelectedMonth(startDate.format('MMMM'));
            setSelectedYear(startDate.format('YYYY'));
        } else if (endDate) {
            setSelectedMonth(endDate.format('MMMM'));
            setSelectedYear(endDate.format('YYYY'));
        }
        formRef?.current?.change('selectMonth', startDate ? startDate.format('MMMM') : endDate.format('MMMM'));
        formRef?.current?.change('selectYear', startDate ? startDate.format('YYYY') : endDate.format('YYYY'));
        setChartIsEditing(true);
    };

    const handleMonthChange = (event) => {
        const month = event?.value;
        if (month) {
            setSelectedMonth(month);

            // Calculate the new start and end dates based on the selected month and current year
            const newStartDate = moment().month(month).year(selectedYear).startOf('month');
            const newEndDate = moment(newStartDate).endOf('month');

            // Update filters
            setTimeFilters({ startDate: newStartDate, endDate: newEndDate });

            // Update the form fields
            formRef?.current?.change('dateRange.startDate', newStartDate);
            formRef?.current?.change('dateRange.endDate', newEndDate);
            formRef?.current?.change('selectMonth', newStartDate.format('MMMM'));

            setChartIsEditing(true);
        }
    };

    const handleYearChange = (event) => {
        const year = event?.value;
        if (year) {
            setSelectedYear(year);

            // Calculate the new start and end dates based on the selected year and current month
            const newStartDate = moment().month(selectedMonth).year(year).startOf('month');
            const newEndDate = moment(newStartDate).endOf('month');

            // Update filters
            setTimeFilters({ startDate: newStartDate, endDate: newEndDate });

            // Update the form fields
            formRef?.current?.change('dateRange.startDate', newStartDate);
            formRef?.current?.change('dateRange.endDate', newEndDate);
            formRef?.current?.change('selectYear', year);

            setChartIsEditing(true);
        }
    };



    return (
        <ConditionalRender renderIf={true} isLoading={analyticsWidgetsLoading}>
            <Form
                onSubmit={handleSave}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy subscription={{ values: true }}>
                            {({ values, form }) => {
                                formRef.current = form;

                                setSpyValues(values);
                            }}
                        </FormSpy>
                        <p className='mb-md flex'>
                            {text?.analytics?.info}
                        </p>
                        <SelectInput
                            className='mb-md'
                            name='selectProject'
                            isClearable={false}
                            isValidNewOption={() => false}
                            isSearchable
                            label={text?.tasks?.home?.filters?.project}
                            placeholder={text?.projects?.details?.selectProject}
                            initialValue={initialProject}
                            options={
                                !isEmpty(projects) &&
                                [
                                    { label: text?.analytics?.all, value: 'all' },
                                    { label: text?.analytics?.unassigned, value: "000000000000000000000000" },
                                    ...projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                        return {
                                            label: x?.projectName,
                                            value: x?._id
                                        }

                                    })
                                ]

                            }
                            onChange={(e) => {
                                form.change('selectProject', e)
                                fetchAllTimeLogs(e?.value)
                                setChartIsEditing(true)

                            }}
                        />

                        <SelectInput
                            className='mb-md'
                            name={'selectEmployee'}
                            isValidNewOption={() => false}
                            isClearable={false}
                            label={text?.dashboard?.employees}
                            options={
                                !isEmpty(employees) &&
                                [
                                    { label: text?.analytics?.all, value: 'all' },
                                    { label: text?.analytics?.unassigned, value: "000000000000000000000000" },
                                    ...employees?.filter(((x) => x?.id !== user?.id))?.map(employee => ({
                                        value: employee.id,
                                        label: `${employee.firstName} ${employee.lastName}`
                                    }))]
                            }
                            initialValue={initialEmployee}
                            onChange={(e) => handleEmployeeChange(e, form)}

                        />


                        {/* Time Tracker */}
                        <TimeAnalysisContainer
                            analyticsWidgetsLoading={analyticsWidgetsLoading}
                            allTimeLogsLoading={allTimeLogsLoading}
                            timeLogs={allTimeLogs}
                            employees={employees}
                            user={user}
                            initialEmployee={initialEmployee}
                            handleEmployeeChange={handleEmployeeChange}
                            form={form}
                            setChartIsEditing={setChartIsEditing}
                            setTimeFilters={setTimeFilters}
                            filters={timeFilters}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            setSelectedMonth={setSelectedMonth}
                            setSelectedYear={setSelectedYear}
                            handleDatesChange={handleDatesChange}
                            handleMonthChange={handleMonthChange}
                            handleYearChange={handleYearChange}
                            spyValues={spyValues}
                        />

                        <hr />

                        <TasksAnalysisContainer
                            spyValues={spyValues}
                            tasks={taskData}
                            tasksGroupBy={initialTasksGroupBy}
                            setTasksGroupBy={setInitialTasksGroupBy}
                            setChartIsEditing={setChartIsEditing}
                            analyticsWidgetsLoading={analyticsWidgetsLoading}
                            tasksIsLoading={tasksIsLoading}
                            handleSelectedDateChange={handleSelectedDateChange}
                            form={form}
                        />

                        <hr />

                        {/* Inventory */}
                        <ChartsContainer
                            handleChartChange={handleChartChange}
                            spyValues={spyValues}
                            form={form}
                            projects={projects}
                            inventoryColumns={inventoryColumns}
                            data={inventoryData}
                            setFormattedData={setFormattedData}
                            formattedData={formattedData}
                            handleCombineChange={handleCombineChange}
                            setCombineKey={setCombineKey}
                            combineKey={combineKey}

                            setStringData={setStringData}
                            setNumberData={setNumberData}
                            stringData={stringData}
                            numberData={numberData}
                            setDataCheckedKeys={setDataCheckedKeys}
                            dataCheckedKeys={dataCheckedKeys}
                            handleClear={handleClear}
                            setInfo={setInfo}
                            setInitialGroupBy={setInitialGroupBy}
                            initialGroupBy={initialGroupBy}
                            setChartIsEditing={setChartIsEditing}
                            initialProject={initialProject}
                            initialChartType={initialChartType}
                            analyticsWidgetsLoading={analyticsWidgetsLoading}

                        />

                        <ConditionalRender renderIf={
                            !isEmpty(spyValues?.chartType) &&
                            !isEmpty(spyValues?.selectProject) &&
                            spyValues?.dataCheckedKeys &&
                            Object.values(spyValues.dataCheckedKeys).some(value => value === true) &&
                            chartIsEditing
                        }>
                            <FormActions
                                type="submit"
                                floating
                                submitText={'Save'}
                                btnStyle={{
                                    color: colors.blue
                                }}
                            />
                        </ConditionalRender>

                        <ConfirmModal
                            isOpen={!isEmpty(info)}
                            toggle={() => {
                                setInfoIsOpen(!infoIsOpen);
                                setInfo([])
                            }}
                            width={isDesktop ? '80%' : '100%'}
                            height={isDesktop ? '80%' : '100%'}
                        >
                            <div className='flex w-100'>
                                <div>
                                    <p className='mb-md'>
                                        {"* Don't forget to save your chart"}
                                    </p>
                                    <FieldWrapper
                                        noHr
                                        name={'Project'}
                                        label={spyValues?.selectProject?.label}
                                        className='mb-md'
                                    />
                                    {
                                        info.map((item, index) => {
                                            return (
                                                <div className='w-100' key={index}>
                                                    {
                                                        // Render inventoryName first if it exists
                                                        item.inventoryName && (
                                                            <FieldWrapper
                                                                noHr
                                                                goto={`/inventory/details?id=${item['productId']}`}
                                                                name={renderLabel(inventoryColumns, 'inventoryName')}
                                                                label={item.inventoryName}
                                                                key="inventoryName" // Ensure to use a unique key
                                                                className='b'
                                                            />
                                                        )
                                                    }
                                                    {
                                                        // Render the rest of the fields, excluding inventoryName
                                                        Object.entries(item)
                                                            .filter(([key, value]) => !['advanceFields', '__v', '_id', 'id', 'inventoryQuantity', 'projectName', 'inventoryName', 'projectId'].includes(key)) // Filter out the keys you want to exclude and also inventoryName since it's already handled
                                                            .map(([key, value]) => {
                                                                if (key === 'isPriority') {
                                                                    return (
                                                                        <FieldWrapper
                                                                            noHr
                                                                            name={renderLabel(inventoryColumns, key)}
                                                                            label={value === true ? 'Yes' : "No"}
                                                                            key={key} // Ensure to use a unique key for each FieldWrapper
                                                                        />
                                                                    )
                                                                }
                                                                if (key === 'productId') return '';
                                                                return (
                                                                    <FieldWrapper
                                                                        noHr
                                                                        name={renderLabel(inventoryColumns, key)}
                                                                        label={String(value)}
                                                                        key={key} // Ensure to use a unique key for each FieldWrapper
                                                                    />
                                                                );
                                                            })
                                                    }
                                                    <hr />
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        </ConfirmModal>
                    </form >
                )}
            />

        </ConditionalRender>
    )
}

export default AnalyticsContainer
