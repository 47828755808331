const inputTypeOptions = (text) => {
    return [
        { value: 'text', label: text?.inputTypes?.text },
        { value: 'textarea', label: text?.inputTypes?.textarea },
        { value: 'number', label: text?.inputTypes?.number },
        { value: 'phone', label: text?.inputTypes?.phone },
        { value: 'date', label: text?.inputTypes?.date },
        { value: 'amount', label: text?.inputTypes?.amount },
        { value: 'length', label: text?.inputTypes?.length },
        { value: 'weight', label: text?.inputTypes?.weight },
        { value: 'volume', label: text?.inputTypes?.volume },
        { value: 'location', label: text?.inputTypes?.location },
        { value: 'link', label: text?.inputTypes?.link },

    ]
}

module.exports = inputTypeOptions