import React from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import 'react-phone-input-2/lib/style.css';
import ConditionalRender from '../ConditionalRender';
import CallToAction from '../CallToAction';
import { useTheme } from '../../context/ThemeContext';
import colors from '../../globalStyles.scss';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledFormGroup = styled.div`
  .form-group {
    .custom-field {
      position: relative;
      display: flex;
      align-items: center;
      .react-tel-input {
        width: auto;
      }
    }

    input {
      border: none;
      border-bottom: ${(props) =>
        props.value ? '1px solid' + colors.blue : '1px solid black'};
      border-bottom-color: ${(props) =>
        props.theme === 'dark' ? colors.lightGray : colors.black};
      border-bottom-color: ${(props) => props.showLightColors && colors.black};
      background: ${(props) =>
        props.disabled
            ? props.theme === 'dark'
                ? colors.disabledDark
                : colors.lightGray
            : props.theme === 'dark'
                ? colors.backgroundSecondary
                : colors.white};
      background: ${(props) => props.showLightColors && colors.white};
      border-radius: 0;
      color: ${(props) =>
        props.theme === 'dark' ? colors.white : colors.black};
    }
  }

  input:focus,
  textarea:focus {
    outline: 0;
    border-bottom: 1px solid ${colors.blue};
    border-bottom-color: ${(props) =>
        props.theme === 'dark' ? colors.lightGray : colors.black};
    border-bottom-color: ${(props) => props.showLightColors && colors.black};
    box-shadow: none;
    color: ${(props) =>
        props.theme === 'dark' ? colors.white : colors.black};
    color: ${(props) => props.showLightColors && colors.black};
  }

  label {
    display: flex;
    align-items: center;
  }
  .react-tel-input .country-list .country {
    display: flex;
    color: ${colors.blue};
    align-items: center;
  }
  .react-tel-input .country-list .country-name {
    color: ${colors.blue};
  }
`;

const TelephoneInput = ({
    label,
    name,
    width,
    style,
    block,
    wrapperStyles,
    setIsEditing,
    info,
    onClick,
    btnText,
    showLightColors,
    className,
    ...rest
}) => {
    const { theme } = useTheme();

    return (
        <Field name={name} {...rest}>
            {({ input, meta }) => (
                <StyledFormGroup
                    showLightColors={showLightColors}
                    theme={theme}
                    value={input.value}
                    className={`FieldWithValidation ${className}`}
                    style={{
                        width: width ? `${width}%` : '100%',
                        display: !block ? 'flex' : 'block',
                        ...style,
                    }}
                >
                    <div className="form-group wide" style={wrapperStyles}>
                        <span className="input-wrapper">
                            <div className="flex items-center justify-start">
                                <label className="b mb-sm" htmlFor={name}>
                                    {label}
                                    <ConditionalRender renderIf={info}>
                                        <div className="info">{info}</div>
                                    </ConditionalRender>
                                </label>
                            </div>
                            <div className="custom-field ">
                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    country={'us'}
                                    value={input.value}
                                    onChange={(value) => {
                                        input.onChange(value);
                                        if (setIsEditing) {
                                            setIsEditing(true);
                                        }
                                    }}
                                    {...rest}
                                />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                <ConditionalRender renderIf={input.value}>
                                    <a
                                        href={`tel:${input.value}`}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <FontAwesomeIcon icon={faPhone} />
                                    </a>
                                </ConditionalRender>
                            </div>
                            <ConditionalRender renderIf={onClick}>
                                <CallToAction
                                    className="mt-md"
                                    text={btnText}
                                    onClick={onClick}
                                />
                            </ConditionalRender>
                        </span>
                    </div>
                </StyledFormGroup>
            )}
        </Field>
    );
};

export default TelephoneInput;
