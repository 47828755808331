import React, { useState, useEffect } from 'react';
import ConditionalRender from '../Core/ConditionalRender';
import CustomTable from '../Core/Tables/CustomTable';
import ConfirmModal from '../Core/ConfirmModal';
import SearchInput from '../Core/Inputs/SearchInput';
import HoverPopup from '../Core/HoverPopup';
import CustomSelect from '../Core/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext'
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import { tasksColumns } from '../Invetory/columns';
import { useNavigate } from 'react-router';
import { status, priority } from '../utils/helperFunctions';
import { removeTasks } from '../utils/calls';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import useScreenSize from '../context/useScreenSize';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isEmpty } from 'lodash';
import colors from '../globalStyles.scss';
import Wrapper from '../Core/Wrapper';
import TasksQuickView from '../QuickView/TasksQuickView';

const StyledDateRangePicker = styled.div`
.DateRangePickerInput {
    border: none;
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
    .DateInput_input__focused {
        border-bottom: 1px solid ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
    }
    .DateRangePickerInput_arrow {
        margin: 0 1em;
        .DateRangePickerInput_arrow_svg {
            fill: ${(props) => props.theme === 'dark' ? colors.white : colors.blue};
        }
    }
}
    .DateInput {
        background: ${colors.lightGray};
        .DateInput_input {
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .DateRangePickerInput_clearDates_default {
        margin: 0;
        right: 0;
        &:hover {
            background: transparent;
            svg {
                fill: ${colors.red};
            }
        }
        svg {
            fill: ${props => props.theme === 'dark' ? colors.white : colors.black};
        }
    }
    .CalendarDay__hovered_span,
    .CalendarDay__selected_span,
    .CalendarDay__selected {
        background: ${colors.blue};
        border: 1px double ${colors.blue};
        color: white;
    }
`;

const TasksContainerExpanded = ({
    projects,
    project,
    user,
    tasks,
    fetchProjectTasks,
    isProject,
    projectId,
    title = 'Tasks',
    tasksIsLoading,
}) => {
    const { text, formatDate } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [assignedEmployees, setAssignedEmployees] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [quickViewData, setQuickViewData] = useState(null);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

    const handleQuickView = (rowData) => {
        setQuickViewData(rowData);
        setIsQuickViewOpen(!isQuickViewOpen);
    };

    const getDeadlineStatus = (date) => {
        const deadlineDate = moment(date);
        const currentDate = moment();
        const diffInDays = deadlineDate.diff(currentDate, 'days');
        let color;
        let title;

        if (diffInDays >= 5) {
            color = 'green';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else if (diffInDays >= 3) {
            color = 'yellow';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else if (diffInDays >= 0) {
            color = 'red';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else {
            color = 'red';
            title = text?.tasks?.details?.pastDue;
        }

        return { color, title };
    };



    useEffect(() => {
        if (tasks?.length === 0) {
            setTableData([]);
        } else {
            setTableData(tasks);
        }
    }, [tasks]);

    useEffect(() => {
        const assignedEmployees = tasks
            ?.map(task => task?.taskAssignedTo)
            .flat()
            .filter(employee => employee);

        const uniqueEmployees = Array.from(
            new Set(assignedEmployees.map(employee => employee._id))
        ).map(id => assignedEmployees.find(employee => employee._id === id));

        const employeeOptions = uniqueEmployees?.map(employee => ({
            value: employee?._id,
            label: `${employee?.firstName} ${employee?.lastName}`
        }));

        setAssignedEmployees(employeeOptions);
    }, [tasks]);

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const handleSelectInputChange = (e, name) => {
        setFilters({ ...filters, [name]: e?.value });
    };

    const [focusedInput, setFocusedInput] = useState(null);
    const handleDatesChange = ({ startDate, endDate }) => {
        setFilters({
            ...filters,
            startDate,
            endDate
        });
    };

    const filterTableData = async () => {
        const filterOptions = {};

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        if (filters.taskStatus) {
            filterOptions.taskStatus = filters.taskStatus;
        }

        if (filters.taskPriority) {
            filterOptions.taskPriority = filters.taskPriority;
        }

        if (filters.taskProject) {
            filterOptions.taskProject = filters.taskProject;
        }

        if (filters.startDate && filters.endDate) {
            filterOptions.startDate = filters.startDate;
            filterOptions.endDate = filters.endDate;
        }

        if (filters.taskAssignedTo) {
            filterOptions.taskAssignedTo = filters.taskAssignedTo;
        }

        let filteredTasks = tasks;

        if (filterOptions.query) {
            filteredTasks = filteredTasks.filter(task =>
                task?.taskTitle.toLowerCase().includes(filterOptions.query)
            );
        }

        if (filterOptions.taskStatus) {
            filteredTasks = filteredTasks.filter(task => task?.taskStatus === filterOptions.taskStatus);
        }

        if (filterOptions.taskPriority) {
            filteredTasks = filteredTasks.filter(task => task?.taskPriority === filterOptions.taskPriority);
        }

        if (filterOptions.taskProject) {
            filteredTasks = filteredTasks.filter(task => task?.taskProject?.id === filterOptions.taskProject);
        }

        if (filterOptions.taskAssignedTo) {
            filteredTasks = filteredTasks.filter(task => task?.taskAssignedTo?._id === filterOptions.taskAssignedTo);
        }

        if (filterOptions.startDate && filterOptions.endDate) {
            filteredTasks = filteredTasks.filter(task =>
                moment(task.taskDeadline).isBetween(filterOptions.startDate, filterOptions.endDate, 'day', '[]')
            );
        }

        setTableData(filteredTasks);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, tasks]);

    const [addTaskIsOpen, setAddTaskIsOpen] = useState(false);

    const handleAddTaskToggle = () => {
        setAddTaskIsOpen(!addTaskIsOpen)
    }

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const [toggleModal, setToggleModal] = useState(false);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const handleDeleteSelection = async () => {
        try {
            const res = await removeTasks(selectedIds);
            if (res.status === 200) {
                setToggleModal(!toggleModal)
                fetchProjectTasks(projectId)
                setSelectedIds([])
                setSelectedItems([])
                notify(text?.notificationsUI?.task?.deleted, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    return (
        <Wrapper
            isLoading={tasksIsLoading}
        >
            <div className='TasksContainer mb-md'>
                <div className='flex flex-column'>
                    <h4 className='mb-md' style={{
                        fontSize: '1.5em',
                    }}>
                        {text?.dashboard?.tasks}
                    </h4>
                    <ConditionalRender renderIf={project?.projectStatus !== 'completed' && !isEmpty(tasks)}>
                        <HoverPopup
                            className='align-self-start'
                            style={{
                                marginRight: '1rem',
                            }}
                            onClick={() => setShowFilters(!showFilters)}
                            placement={'top'}
                            id='toggle-filters-popup-tasks'
                            text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                        >
                            <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                        </HoverPopup>
                    </ConditionalRender>
                    <ConditionalRender renderIf={showFilters}>
                        <div
                            className='filters mt-md'
                            style={{
                                flexWrap: 'wrap',
                            }}>
                            <SearchInput
                                className='mt-md'
                                value={filters.query}
                                onChange={handleSearchChange}
                                placeHolder={text?.tasks?.home?.filters?.search}
                            />
                            <CustomSelect
                                className='mt-md'
                                handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskStatus')}
                                options={status(text)}
                                name={'taskStatus'}
                                placeHolder={text?.tasks?.home?.filters?.status}
                            />
                            <CustomSelect
                                className='mt-md'
                                handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskPriority')}
                                options={priority(text)}
                                name={'taskPriority'}
                                placeHolder={text?.tasks?.home?.filters?.priority}
                            />
                            <ConditionalRender renderIf={!isProject}>
                                <CustomSelect
                                    className='mt-md'
                                    handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskProject')}
                                    options={projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => ({
                                        label: x?.projectName,
                                        value: x?._id
                                    }))}
                                    name={'taskProject'}
                                    placeHolder={text?.tasks?.home?.filters?.project}
                                />
                            </ConditionalRender>
                            <CustomSelect
                                className='mt-md'
                                handleSelectInputChange={(e) => handleSelectInputChange(e, 'taskAssignedTo')}
                                options={assignedEmployees}
                                name={'taskAssignedTo'}
                                placeHolder={text?.tasks?.details?.assignedTo}
                            />
                            <StyledDateRangePicker theme={theme} className='flex mt-md'>
                                <DateRangePicker
                                    startDate={filters.startDate}
                                    startDateId="start_date_id"
                                    endDate={filters.endDate}
                                    endDateId="end_date_id"
                                    onDatesChange={handleDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                    isOutsideRange={() => false}
                                    displayFormat={'MM-DD-YYYY'}
                                    showClearDates
                                    numberOfMonths={!isDesktop ? 1 : 2}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText={text?.tasks?.home?.filters?.startDate}
                                    endDatePlaceholderText={text?.tasks?.home?.filters?.endDate}
                                />
                            </StyledDateRangePicker>
                        </div>
                    </ConditionalRender>
                </div>
                <div className='flex flex-column align-start mt-md'>
                    <CustomTable
                        showLightColors
                        keyField="_id"
                        columns={tasksColumns({
                            theme,
                            text,
                            status,
                            priority,
                            isProject,
                            navigate,
                            handleQuickView,
                            isDesktop
                        })}
                        rows={tableData
                            ?.filter(item => item?.taskProject?.projectStatus !== 'completed')
                            ?.map(item => {
                                // Filter admin projects
                                const adminProjects = projects?.filter(project =>
                                    project.adminEmployees.some(employee => employee.id === user?.id)
                                );
                                // Add isAdmin flag to each inventory item
                                const isAdmin = adminProjects?.some(project => project?.id === item.taskProject?.id) || user?.roleName === 'Admin';
                                return { ...item, isAdmin };
                            })}
                        theme={theme}
                        noDataText={text?.tasks?.home?.noTasks}
                        onRowSelectionChange={handleRowSelectionChange}
                    />
                    <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                        <div style={{
                            color: colors.red,
                            fontFamily: colors.openSans,
                            cursor: 'pointer',
                            display: 'flex',
                            marginBottom: '5px'
                        }} onClick={() => setToggleModal(true)} >
                            {text?.tasks?.home?.deleteSelected}
                        </div>
                        <ConfirmModal
                            onClick={handleDeleteSelection}
                            text={text?.tasks?.home?.modal?.button}
                            toggle={() => setToggleModal(!toggleModal)}
                            isOpen={toggleModal}
                            btnStyles={{
                                color: colors.red
                            }}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}

                        >
                            <div>
                                <h4 style={{ marginBottom: '15px' }}>
                                    {text?.tasks?.home?.modal?.title}
                                </h4>
                                <ul>
                                    {selectedItems?.map((x) => {
                                        return (
                                            <li style={{ fontSize: '0.9rem' }}>
                                                - {x?.taskTitle}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </ConfirmModal>
                    </ConditionalRender>
                </div>

                {/* Quick View */}
                <TasksQuickView
                    isQuickViewOpen={isQuickViewOpen}
                    setIsQuickViewOpen={setIsQuickViewOpen}
                    quickViewData={quickViewData}
                    getDeadlineStatus={getDeadlineStatus}
                    noOverlay
                />
            </div>

        </Wrapper>
    )
}

export default TasksContainerExpanded