import React from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { renderLengthLabel, renderWeightLabel, renderVolumeLabel, } from '../utils/helperFunctions';
import RenderImages from '../Core/RenderImages';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';


const InventoryQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    noOverlay,
    fieldNames,
    openModal,
    closeModal,
    setActiveIndex,
    activeIndex,
    isModalOpen
}) => {
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();
    const renderFieldLabel = (key) => {
        return fieldNames?.find(field => key === `${field?.inputType}-${field?.name}`)?.label
    }

    return (
        <ConfirmModal
            toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
            isOpen={isQuickViewOpen}
            noOverlay={noOverlay}
            shaddow
            width={isDesktop ? '80%' : '100%'}
            height={isDesktop ? '80%' : '100%'}
        >
            <div className='w-100'>
                <h4 className='mb-md'>{quickViewData?.inventoryName}</h4>
                <ConditionalRender renderIf={!isEmpty(quickViewData?.projectId)}>
                    <FieldWrapper
                        name={text?.tasks?.home?.filters?.project}
                        label={quickViewData?.projectId?.projectName}
                    />
                </ConditionalRender>
                <FieldWrapper
                    name={text?.inventory?.details?.form?.category}
                    label={quickViewData?.inventoryCategory}
                />
                <FieldWrapper
                    name={text?.inventory?.details?.form?.quantity}
                    label={
                        <div className='flex flex-column'>
                            <span>
                                {quickViewData?.inventoryQuantity?.value}
                            </span>
                            <ConditionalRender renderIf={quickViewData?.inventoryQuantity?.notification?.isChecked}>
                                <FieldWrapper
                                    name={`${text?.inventory?.details?.form?.nodifyMe} ${text?.inventory?.details?.form?.numberBellow}`}
                                    label={quickViewData?.inventoryQuantity?.notification?.value}
                                    noHr
                                />
                            </ConditionalRender>
                        </div>
                    }
                />

                <ConditionalRender renderIf={!isEmpty(quickViewData?.advanceFields)}>
                    <h4 className='mb-md'>{text?.inventory?.details?.form?.title}</h4>
                    {
                        Object.entries(quickViewData?.advanceFields || {}).map(([key, value], index) => {
                            const keyName = key.split('-')[0];
                            const keyValue = key.split('-')[1];
                            console.log('keyName', keyName);
                            console.log('keyValue', keyValue);

                            if (keyName === 'number') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderFieldLabel(key)}
                                        label={
                                            <div className='flex flex-column'>
                                                <span>{value?.value}</span>
                                                <ConditionalRender renderIf={value?.notification?.isChecked}>
                                                    <FieldWrapper
                                                        name={`${text?.inventory?.details?.form?.nodifyMe} ${text?.inventory?.details?.form?.numberBellow}`}
                                                        label={value?.notification?.value}
                                                        noHr
                                                    />
                                                </ConditionalRender>
                                            </div>
                                        }
                                    />
                                );
                            } else if (keyName === 'length') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderFieldLabel(key)}
                                        label={`${value.split('-')[0]} ${renderLengthLabel(value.split('-')[1], text)}`}
                                    />
                                );
                            } else if (keyName === 'volume') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderFieldLabel(key)}
                                        label={`${value.split('-')[0]} ${renderVolumeLabel(value.split('-')[1], text)}`}
                                    />
                                );
                            } else if (keyName === 'weight') {
                                return (
                                    <FieldWrapper
                                        key={index}
                                        name={renderFieldLabel(key)}
                                        label={`${value.split('-')[0]} ${renderWeightLabel(value.split('-')[1], text)}`}
                                    />
                                );
                            }

                            return (
                                <FieldWrapper
                                    key={index}
                                    name={renderFieldLabel(key)}
                                    label={value}
                                />
                            );
                        })
                    }
                </ConditionalRender>




                <ConditionalRender renderIf={!isEmpty(quickViewData?.images)}>
                    <RenderImages
                        images={quickViewData?.images}
                        disabled={true}

                        openModal={openModal && openModal}
                        closeModal={closeModal && closeModal}
                        setActiveIndex={setActiveIndex && setActiveIndex}
                        activeIndex={activeIndex && activeIndex}
                        isModalOpen={isModalOpen && isModalOpen}
                    />
                </ConditionalRender>
            </div>
        </ConfirmModal>
    )
}

export default InventoryQuickView
