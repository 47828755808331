import React, { useEffect, useState, useMemo, useContext } from "react";
import ConditionalRender from "../Core/ConditionalRender";
import Navigation from "../Navigation/Navigation";
import SideMenu from "../Navigation/SideMenu";
import classnames from 'classnames'
import Footer from "../Footer/Footer";
import { useTheme } from '../context/ThemeContext'
import colors from '../globalStyles.scss'
import styled from 'styled-components'
import { updateCookies } from '../utils/calls/updateCookies'
import { useLanguage } from "../context/LanguageContext";
import useScreenSize from "../context/useScreenSize";
import { useNavigate } from "react-router-dom";

import HelpContainer from "../Help/HelpContainer";
import CallToAction from "../Core/CallToAction";
import HoverPopup from '../Core/HoverPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faMessage, faCircle, faClose, faClock, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-final-form';
import { isEmpty } from 'lodash';
import { TimerContext } from '../context/TimeContext';
import { getTodaysRunningTimesheets } from '../utils/calls'
import { getStartAndEndOfDay } from '../utils/helperFunctions'
import TimeTracker from "../Core/TimeTracker";
import { detect } from 'detect-browser';
import CustomDialog from "../Core/CustomDialog";
import RunningTimeSheets from "../Welcome/RunningTimeSheets";

import "./styles.scss";

const browser = detect();


const StyledDiv = styled.div`
    .main {
        background-color: ${(props) => props.theme === 'dark' ? colors.background : colors.white};
        width: 100%;
        position: relative;
    }
    a {
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
    }
    p, label, span, input, select, option, a, li, ul, ol, textarea, small {
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        font-size: 1em;
    }
    h1, h2, h3, h4, h5, h6, a {
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
    }
    svg {
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
    }
    hr {
        border-top: 1px solid ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
    }
    caret-color: ${props => props.theme === 'dark' ? colors.white : colors.black};
    .Mui-checked+.MuiSwitch-track{
        background-color: ${(props => props.theme === 'dark' ? colors.secondary : colors.backgroundSecondary)} !important;
    }
    .MuiSwitch-track {
        background-color: ${(props => props.theme === 'dark' ? colors.primary : colors.black)} !important;
    }
    .sort-caret {
        svg {
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.white};
        }
    }
            &.desktop {
                .LandingContainer__wrapper {
                     height: 90%;
                     position: fixed;
                     top: 5%;
                     width: 100%;
             }
                .CreatableInput {
                            width: 30%;
                     }

                .FormActions {
                    .buttons {
                        bottom: 10%;
                        right: 1%;
                    }
                }
            }
            &.tablet {
                .CreatableInput {
                        width: 50%;
                }
            }
            &.phone {
                .CreatableInput {
                        width: 100%;
                }
            }
        &.tablet, &.phone {
            h1, h2, h3, h4, h5, h6{
                font-size: 1em;
            }
            p, a, label, span, input, select, option, a, li, ul, ol, textarea, small, button {
                font-size: 1em;
            }
            
            .FormActions {
                .buttons {
                    top: 10%;
                    right: 5%;
                }
            }
            .LandingContainer__wrapper {
                height: 95%;
                position: fixed;
                top: 5%;
                width: 100%;
                z-index: 0;
                .time-tracker {
                    transform: ${({ timeIsOpen }) => timeIsOpen ? 'translateX(0)' : 'translateX(200%)'};
                    transition: transform 0.3s;
                    position: fixed;
                    z-index: 3;
                    width: 100%;
                    height: 100%;
                    padding-top: 5%;
                    bottom: 0;
                    background: ${(props) => props.theme === 'dark' ? colors.lightGray : colors.white};
                    box-shadow: 0px 0px 10px 0px ${(props) => props.theme === 'dark' ? 'rgba(255,255,255,0.75)' : 'rgba(0,0,0,0.75)'};
                    .close {
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        cursor: pointer;
                        font-size: 1rem;
                        background: ${colors.red};
                        padding: 15px;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            background: ${colors.red};
                            color: ${colors.white};
                            padding: 0.5em;

                        }
                    }
                }
            }    
                .icons_wrapper {
                    position: relative;

                    .icons {
                        transform: ${({ showIcons }) => showIcons ? 'translateX(0)' : 'translateX(500%)'};
                        transition: transform 0.3s;
                        display: flex;
                        flex-direction: column;
                        z-index: 2;
                        position: fixed;
                        right: 5%;
                        bottom: 15%;
                    }    
                    .toggle-side {
                        cursor: pointer;
                        margin: 0.5em 0;
                    }  
                    .toggle-time {
                        cursor: pointer;
                        margin: 0.5em 0;  
                    }  
                    .toggle-chat {
                        cursor: pointer;
                        margin: 0.5em 0;
                    }
                    .toggle-icons {
                        position: fixed;
                        z-index: 1;
                        right: 5%;
                        bottom: 5%;
                        cursor: pointer;
                        margin: 0.5em 0;
                    }
                }
.toggle-time, .toggle-side, .toggle-chat, .toggle-icons {
    filter: drop-shadow(2px 3px 2px ${({ theme }) => theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'});
    border-radius: 50%;
    background-color: ${({ theme }) => theme === 'dark' ? '#333' : '#fff'};
    border: 1px solid ${({ theme }) => theme === 'dark' ? '#444' : '#ddd'};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    svg {
        fill: ${({ theme }) => theme === 'dark' ? '#fff' : '#000'}; /* Icon color */
    }
}



`

export const LandingContainer = ({
    children,
    isLoggedIn,
    isLoggedInAsClient,
    logoutUser,
    clientLogout,
    isCompanyPremium,
    handleToggleChat,
    showHelp,
    typing,
    handleSendMessage,
    conversationHistory,
    thinking,
    chatErrorMessage,
    isSuperAdmin,
    accessGranted,
    projects,
    employees,
    userRole,
    user,
    company,
    fetchCompanyEmployees,
    employeesIsLoading,
    projectsIsLoading,
    fetchCompanyProjects,
    attributes,
    fetchAttributes,
    attributesIsLoading,
    fetchInventoryProducts,
    inventory,
    inventorysLoading,
    getRunningTimesheet,
    fetchFifteenMinutesEventsFromNow
}) => {


    const [isHomeAndNotPremium, setIsHomeAndNotPremium] = useState(false);
    const [toggleModal, setToggleModal] = useState(false);
    const { theme } = useTheme();
    const { text } = useLanguage();
    const navigate = useNavigate();

    const { isDesktop, isTablet, loading } = useScreenSize();
    const [menuActive, setMenuActive] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [showIcons, setShowIcons] = useState(false);
    const [timeIsOpen, setTimeIsOpen] = useState(false);
    const [projectId, setProjectId] = useState('');
    const [isOpen, setIsOpen] = useState(false);


    const [runningTimesheets, setRunningTimesheets] = useState([])

    const fetchRunningTimesheet = async () => {
        try {
            const { startOfToday, endOfToday } = getStartAndEndOfDay();
            const res = await getTodaysRunningTimesheets({
                startOfToday,
                endOfToday
            });
            if (res.status === 200) {
                setRunningTimesheets(res.data?.timesheets)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {

        if (!isLoggedInAsClient || isLoggedIn) {
            // Fetch initially
            fetchRunningTimesheet();

            // Set up the interval to run every minute (60000 milliseconds)
            const intervalId = setInterval(() => {
                fetchRunningTimesheet();
            }, 60000);

            // Clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, []);


    useEffect(() => {

        if (!isLoggedInAsClient || isLoggedIn) {

            // Set up the interval to run every minute (60000 milliseconds)
            const intervalId = setInterval(() => {
                fetchFifteenMinutesEventsFromNow();
            }, 900000);

            // Clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, []);

    const {
        isRunning,
    } = useContext(TimerContext);

    const [initialProject, setInitialProject] = useState();

    useEffect(() => {
        // const storedData = JSON.parse(localStorage.getItem('timeTracker'));
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};


        const fetchData = async () => {
            if (isEmpty(storedData)) {
                try {
                    const res = await getRunningTimesheet();
                    if (res.status === 200) {
                        const project = projects?.find(p => p._id === res.data?.projectId);
                        setInitialProject({
                            label: project?.projectName,
                            value: project?._id
                        });
                        setProjectId(project?._id);
                    } else if (res.status === 404) {
                        // Handle 404 status: set to first project if available
                        if (projects && projects?.length > 0) {
                            setInitialProject({
                                label: projects[0]?.projectName,
                                value: projects[0]?._id
                            });
                            setProjectId(projects[0]?._id);
                        }
                    }
                } catch (error) {
                    // Handle other errors or set default project
                    if (projects && projects?.length > 0) {
                        setInitialProject({
                            label: projects[0]?.projectName,
                            value: projects[0]?._id
                        });
                        setProjectId(projects[0]?._id);
                    }
                }
            }
        };

        fetchData();
    }, [projects]);

    useEffect(() => {
        // const storedData = JSON.parse(localStorage.getItem('timeTracker'));
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};


        if (storedData?.isRunning && !isEmpty(projects)) {
            const projectId = storedData?.projectId;
            setProjectId(projectId);
            const project = projects?.find(p => p._id === projectId);
            setInitialProject({
                label: project?.projectName,
                value: project?._id
            });
        } else {
            // In case there is no running project, but we still have the projectId stored
            const projectId = storedData?.projectId;
            const project = projects?.find(p => p._id === projectId);

            // If we find the project with the stored projectId, use that, otherwise set to empty
            if (project) {
                setInitialProject({
                    label: project.projectName,
                    value: project._id
                });
                setProjectId(project?._id);

            }
        }
    }, [projects]);

    const handleChange = (e) => {
        setProjectId(e?.value)
    }

    const formatMilliseconds = (milliseconds) => {
        let totalSeconds = Math.floor(milliseconds / 1000);
        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        let seconds = totalSeconds % 60;

        return `${hours} ${text?.dashboard?.hours}, ${minutes} ${text?.dashboard?.minutes}, ${seconds} ${text?.dashboard?.seconds}`;
    }

    useMemo(() => {
        if (!isDesktop) {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }, [isDesktop])

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    const handleLinkClick = () => {
        if (!isDesktop) {
            setMenuActive(false);
            setCollapsed(true);
        }
    };

    const handleShowIcons = () => {
        setShowIcons(!showIcons);
    }

    const handleTimeIsOpen = () => {
        setTimeIsOpen(!timeIsOpen);
    }


    useEffect(() => {
        setIsHomeAndNotPremium(!isLoggedIn);
    }, [isLoggedIn])

    const handleCookiesToggle = async (value) => {
        if (value) {
            try {
                const res = await updateCookies()
                if (res.status === 200) {
                    fetchAttributes()
                    setToggleModal(false)
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        if (!attributes?.cookiesApproval) {
            setToggleModal(true)
        }
    }, [attributes?.cookiesApproval])


    useEffect(() => {
        if (browser && (browser.os === 'Android' || browser.os === 'iOS')) {
            const handleKeyboardShow = () => {
                document.body.style.position = 'fixed';
                document.body.style.top = `-${window.scrollY}px`;
                document.body.style.width = '100%'; // Ensure the width is 100%
            };

            const handleKeyboardHide = () => {
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                document.body.style.width = ''; // Reset the width
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            };

            window.addEventListener('focusin', handleKeyboardShow); // keyboard appeared
            window.addEventListener('focusout', handleKeyboardHide); // keyboard disappeared

            return () => {
                window.removeEventListener('focusin', handleKeyboardShow);
                window.removeEventListener('focusout', handleKeyboardHide);
            };
        }
    }, []);



    return (
        <ConditionalRender renderIf={true} isLoading={loading} customStyled={{
            height: '100vh'
        }}>
            <StyledDiv
                theme={theme}
                className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}
                timeIsOpen={timeIsOpen}
                showIcons={showIcons}
            >
                <div style={{
                    overflowY: 'hidden'
                }} className={classnames({
                    'LandingContainer': true,
                    'scroll-container': !isHomeAndNotPremium,
                    [theme]: true
                })}>
                    <Navigation
                        accessGranted={accessGranted}
                        isLoggedIn={isLoggedIn}
                        isLoggedInAsClient={isLoggedInAsClient}
                        isCompanyPremium={isCompanyPremium}
                        logoutUser={logoutUser}
                        clientLogout={clientLogout}
                        user={user}
                        handleLinkClick={handleLinkClick}
                        toggleMenu={toggleMenu}
                        menuActive={menuActive}
                        handleToggleChat={handleToggleChat}
                        showHelp={showHelp}
                        handleSendMessage={handleSendMessage}
                        conversationHistory={conversationHistory}
                        thinking={thinking}
                        chatErrorMessage={chatErrorMessage}
                        isSuperAdmin={isSuperAdmin}
                        typing={typing}
                        setCollapsed={setCollapsed}
                    />
                    <div className="LandingContainer__wrapper">
                        <div
                            className={classnames({
                                'LandingContainer__wrapper-body': true,
                                'overflow-hidden': true
                            })} >
                            <ConditionalRender renderIf={isLoggedIn && isCompanyPremium}>
                                <SideMenu
                                    isLoggedIn={isLoggedIn}
                                    isCompanyPremium={isCompanyPremium}
                                    projects={projects}
                                    employee={employees?.filter((x) => x.id !== user?.id)}
                                    userRole={userRole}
                                    user={user}
                                    company={company}
                                    fetchCompanyEmployees={fetchCompanyEmployees}
                                    employeesIsLoading={employeesIsLoading}
                                    employees={employees}
                                    projectsIsLoading={projectsIsLoading}
                                    fetchCompanyProjects={fetchCompanyProjects}
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                />
                            </ConditionalRender>
                            <div className={classnames({
                                'main': true,
                                'scroll-container': !isHomeAndNotPremium,

                            })} style={{
                                height: '100%',
                                padding: ((isDesktop && isHomeAndNotPremium) || !accessGranted) ?
                                    '0' :
                                    (!isDesktop && isHomeAndNotPremium) ?
                                        '0' :
                                        (isDesktop && !isHomeAndNotPremium) ?
                                            '15px' :
                                            '15px'
                            }}>
                                {children}
                            </div>
                        </div>

                        {/* Tablet and Phone Stuff */}
                        <ConditionalRender renderIf={!isDesktop}>
                            {
                                (isCompanyPremium && isLoggedIn) && (
                                    <HelpContainer
                                        showHelp={showHelp}
                                        handleToggleChat={handleToggleChat}
                                        handleSendMessage={handleSendMessage}
                                        conversationHistory={conversationHistory}
                                        thinking={thinking}
                                        typing={typing}
                                        chatErrorMessage={chatErrorMessage}
                                    />
                                )
                            }
                            <ConditionalRender renderIf={isCompanyPremium && isLoggedIn}>
                                <div
                                    className='icons_wrapper items-center'
                                >
                                    <div className='icons'>
                                        <ConditionalRender renderIf={true}>
                                            <div className='toggle-time flex items-center' onClick={handleTimeIsOpen}>
                                                <HoverPopup
                                                    style={{
                                                        background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                                        padding: '0.5em',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    wrapperStyle={{
                                                        justifyContent: 'center'
                                                    }}
                                                    placement={'left'}
                                                    id='toggle-time-popup'
                                                >
                                                    <FontAwesomeIcon icon={faClock} />
                                                </HoverPopup>
                                            </div>
                                        </ConditionalRender>
                                        <ConditionalRender renderIf={true}>
                                            <div className='toggle-chat flex items-center' onClick={handleToggleChat}>
                                                <HoverPopup
                                                    style={{
                                                        background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                                        padding: '0.5em',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    wrapperStyle={{
                                                        justifyContent: 'center'
                                                    }}
                                                    placement={'left'}
                                                    id='toggle-chat-popup'
                                                >
                                                    <FontAwesomeIcon icon={faMessage} />
                                                </HoverPopup>
                                            </div>
                                        </ConditionalRender>
                                        <ConditionalRender renderIf={true}>
                                            <div className='toggle-side flex items-center' onClick={() => setCollapsed(!collapsed)}>
                                                <HoverPopup
                                                    style={{
                                                        background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                                        padding: '0.5em',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                    placement={'right'}
                                                    wrapperStyle={{
                                                        justifyContent: 'center'
                                                    }}
                                                    id='toggle-side-popup'
                                                >
                                                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                                                </HoverPopup>
                                            </div>
                                        </ConditionalRender>
                                    </div>
                                    <div className='toggle-icons flex items-center' onClick={handleShowIcons}>
                                        <HoverPopup
                                            style={{
                                                background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                                padding: '0.5em',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            wrapperStyle={{
                                                justifyContent: 'center'
                                            }}
                                            placement={'top'}
                                            id='toggle-side-popup'
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    showIcons ? faClose : faCircle
                                                }
                                            />
                                        </HoverPopup>
                                    </div>
                                </div>
                                {/* Time Tracker */}
                                <div className='time-tracker scroll-container'>
                                    {/* close button */}
                                    <HoverPopup
                                        style={{
                                            background: theme === 'dark' ? colors.secondary : colors.backgroundSecondary,
                                            position: 'absolute',
                                            right: '5%',
                                            bottom: '5%',
                                            padding: '0.5em',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        placement={'right'}
                                        onClick={handleTimeIsOpen}
                                        id='toggle-side-two-popup-time'
                                    >
                                        <FontAwesomeIcon
                                            icon={faArrowAltCircleLeft}
                                        />
                                    </HoverPopup>
                                    <div style={{
                                        padding: '0.5em'
                                    }} >
                                        <Form
                                            onSubmit={() => null}
                                            render={({ handleSubmit }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div>
                                                        <CallToAction
                                                            className='mb-md'
                                                            type="button"
                                                            onClick={() => {
                                                                navigate(`/logs`)
                                                                setTimeIsOpen(false)
                                                            }}
                                                            text={text?.timeTracker?.manageAllLogs}
                                                        />

                                                        <TimeTracker
                                                            disabled={!projectId}
                                                            projectId={projectId}
                                                            fetchInventoryProducts={fetchInventoryProducts}
                                                            inventory={inventory}
                                                            inventorysLoading={inventorysLoading}
                                                            projects={projects}
                                                            user={user}
                                                            showLightColors
                                                        />
                                                    </div>
                                                </form>
                                            )}
                                        />

                                        <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                                            <CallToAction
                                                className='mt-md'
                                                onClick={() => setIsOpen(!isOpen)}
                                                text={text?.dashboard?.viewRunning}
                                                style={{
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    width: '100%',
                                                }}
                                                btnStyle={{
                                                    color: 'white',
                                                }}
                                            />
                                        </ConditionalRender>
                                        <ConditionalRender renderIf={isEmpty(runningTimesheets)}>
                                            <p className='mb-md' style={{
                                                color: colors.blue
                                            }}>
                                                {text?.dashboard?.noEmployees}
                                            </p>
                                        </ConditionalRender>
                                        <RunningTimeSheets
                                            runningTimesheets={runningTimesheets}
                                            isOpen={isOpen}
                                            setIsOpen={setIsOpen}
                                        />
                                        {/* <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                                            <div className='mb-md'>
                                                <p className='black'>
                                                    {`${runningTimesheets?.length} ${text?.dashboard?.timeClockInfo}`}
                                                </p>
                                                {
                                                    runningTimesheets?.map((timesheet) => {
                                                        return (
                                                            <div className='flex' key={timesheet?._id}>
                                                                <p className='mr-sm black'>
                                                                    {`${timesheet?.userId?.firstName} ${timesheet?.userId?.lastName}`}
                                                                </p>
                                                                <p className='mr-sm black'>
                                                                    {`${text?.dashboard?.in} ${timesheet?.projectId?.projectName}`}
                                                                </p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </ConditionalRender>
                                        <ConditionalRender renderIf={isEmpty(runningTimesheets)}>
                                            <p className='mb-md black'>
                                                {text?.dashboard?.noEmployees}
                                            </p>
                                        </ConditionalRender> */}
                                    </div>
                                </div>
                            </ConditionalRender>
                        </ConditionalRender>
                    </div>

                    {/* Desktop Stuff */}
                    <ConditionalRender renderIf={isDesktop}>
                        <Footer
                            handleToggleChat={handleToggleChat}
                            showHelp={showHelp}
                            isCompanyPremium={isCompanyPremium}
                            isLoggedIn={isLoggedIn}
                            handleSendMessage={handleSendMessage}
                            conversationHistory={conversationHistory}
                            thinking={thinking}
                            chatErrorMessage={chatErrorMessage}
                            isSuperAdmin={isSuperAdmin}
                            typing={typing}
                            isAdmin={user?.roleName === 'Admin'}
                        />
                    </ConditionalRender>
                    <ConditionalRender isLoading={attributesIsLoading} renderIf={isLoggedIn && !attributes?.cookiesApproval}>
                        {/* <ConfirmModal
                        onClick={() => handleCookiesToggle(true)}
                        text={text?.approve}
                        toggle={() => setToggleModal(false)}
                        isOpen={toggleModal}
                    >
                        <CookiesPolicy />
                    </ConfirmModal> */}
                        <CustomDialog

                            isOpen={toggleModal}
                            onToggle={() => setToggleModal(false)}
                            dialogTitle="External Control"
                            dialogContent="This dialog is controlled externally."
                            agreeText="OK"
                            disagreeText="Cancel"
                            onAgree={() => handleCookiesToggle(true)}
                            onDisagree={() => setToggleModal(false)}
                        />
                    </ConditionalRender>
                </div>
            </StyledDiv>
        </ConditionalRender >
    );
};

export default LandingContainer;