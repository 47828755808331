import React, { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import SubmitModal from '../Core/SubmitModal';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { status, proposalStatus } from '../utils/helperFunctions';
import SelectInput from '../Core/SelectInput';
import { isRequired, composeValidators } from '../utils/validators';
import MultiFields from '../Core/MultiFields';
import RenderImages from '../Core/RenderImages';
import { isEmpty } from 'lodash';
import CallToAction from '../Core/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '../context/useScreenSize';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext';
import { updateStatusAndSignature } from '../utils/calls';
import Signature from '@uiw/react-signature';
import moment from 'moment';
import CanvasSignature from './CanvasSignature';


const ProposalQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    noOverlay,
    isClient,
    openModal,
    closeModal,
    setActiveIndex,
    activeIndex,
    isModalOpen,
    initialStatusValue,
    handleFetchSubmittedProposal,
    clientId
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop } = useScreenSize();
    const $svg = useRef(null);
    const [points, setPoints] = useState([])

    const handleClear = (evn) => {
        $svg.current?.clear();
        setPoints([])
    }
    const handlePoints = (data) => {
        if (data.length > 0) {
            setSignatureError('');
            setPoints([...points, JSON.stringify(data)]);
        }
    }
    const [signatureError, setSignatureError] = useState('');
    const onSubmit = async (values) => {
        if (isEmpty(points)) {
            setSignatureError(text?.client?.create?.signatureError);
            return;
        }
        try {
            const { data, status } = await updateStatusAndSignature({
                uuid: values?.uuid,
                status: values?.status?.value,
                signatureName: values?.signatureName,
                signatureDate: new Date().toISOString(),
                signature: points
            });
            if (status === 200) {
                setIsQuickViewOpen(false);
                handleFetchSubmittedProposal(clientId);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const required = composeValidators(
        isRequired(text?.tasks?.details?.validations?.required),
    )

    if (isClient && !quickViewData?.statusWasUpdated) {
        return (
            <SubmitModal
                onClick={onSubmit}
                text={text?.settings?.button}
                toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
                isOpen={isQuickViewOpen}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                btnStyles={{
                    color: colors.blue
                }}
            >
                <div className='w-100 mr-md'>
                    <h4 className='mb-md'>{quickViewData?.title}</h4>
                    <FieldWrapper
                        name={'#'}
                        label={`${quickViewData?.uuid}`}
                    />
                    <MultiFields
                        className='field-style'
                        name="uuid"
                        validate={required}
                        component="input"
                        type="text"
                        initialValue={quickViewData?.uuid}
                        label={'#'}
                        block
                        style={{
                            display: 'none'
                        }}
                    />
                    <SelectInput
                        className='field-style'
                        isValidNewOption={() => false}
                        isClearable={false}
                        isSearchable={false}
                        name='status'
                        validate={required}

                        label={text?.proposals?.details?.status}
                        options={proposalStatus(text).filter(({ value }) => value !== 'Pending')}
                        initialValue={initialStatusValue}
                        placeholder={text?.client?.create?.approveOrReject}
                    />
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.projectId)}>
                        <FieldWrapper
                            name={text?.analytics?.project}
                            label={quickViewData?.projectId?.projectName}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.clientId)}>
                        <FieldWrapper
                            name={text?.proposals?.details?.client}
                            label={quickViewData?.clientId?.name}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.additionalEmails)}>
                        <FieldWrapper
                            name={text?.proposals?.details?.additionalEmails}
                            label={quickViewData?.additionalEmails?.map(email => email).join(', ')}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.emailSubject)}>
                        <FieldWrapper
                            name={text?.proposals?.create?.emailSubject}
                            label={quickViewData?.emailSubject}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.emailMessage)}>
                        <FieldWrapper
                            name={text?.proposals?.create?.emailMessage}
                            label={quickViewData?.emailMessage}
                        />
                    </ConditionalRender>

                    <ConditionalRender renderIf={!isEmpty(quickViewData?.fields)}>
                        <table className="w-100 mb-md">
                            <thead className="b">
                                <tr style={{
                                    background: theme === 'dark' ? colors.black : colors.white
                                }}>
                                    <th style={{
                                        padding: '10px',
                                    }} className='pr-md'>{text?.proposals?.create?.descriptionTwo}</th>
                                    <th style={{
                                        padding: '10px',
                                    }} className='pr-md'>{text?.proposals?.create?.fields?.notes}</th>
                                    <th style={{
                                        padding: '10px',
                                    }} className='pr-md'>{text?.proposals?.create?.fields?.amount}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quickViewData?.fields?.map((field, index) => (
                                    <tr key={index} style={{
                                        marginTop: '10px',
                                        background: theme === 'dark' ? colors.backgroundSecondary : colors.white
                                    }}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #DDD' }}>{field?.description}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #DDD' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: field?.notes?.replace(/\n/g, '<br />') || '',
                                                }}
                                            />
                                        </td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #DDD' }}>${field?.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </ConditionalRender>



                    <FieldWrapper
                        name={text?.proposals?.details?.total}
                        label={`$${quickViewData?.totalCost}`}
                    />
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.images)}>
                        <RenderImages
                            images={quickViewData?.images}
                            disabled={true}

                            openModal={openModal && openModal}
                            closeModal={closeModal && closeModal}
                            setActiveIndex={setActiveIndex && setActiveIndex}
                            activeIndex={activeIndex && activeIndex}
                            isModalOpen={isModalOpen && isModalOpen}
                        />
                    </ConditionalRender>

                    <hr />
                    <MultiFields
                        className='field-style'
                        name="signatureName"
                        validate={required}
                        component="input"
                        type="text"
                        label={text?.client?.create?.signature}
                        block
                        info={text?.client?.create?.signatureInfo}
                    />
                    <Signature
                        ref={$svg}
                        onPointer={handlePoints}
                    />
                    <CallToAction
                        onClick={handleClear}
                        text={text?.client?.create?.clear}
                    />
                    <ConditionalRender renderIf={signatureError}>
                        <p style={{ color: colors.red }}>{signatureError}</p>
                    </ConditionalRender>
                </div>
            </SubmitModal>
        )
    } else {
        return (
            <ConfirmModal
                toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
                isOpen={isQuickViewOpen}
                noOverlay={noOverlay}
                shaddow
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
            >
                <div className='w-100 mr-md'>
                    <h4 className='mb-md'>{quickViewData?.title}</h4>
                    <FieldWrapper
                        name={'#'}
                        label={`${quickViewData?.uuid}`}
                    />
                    <div className='flex'>
                        <FieldWrapper
                            name={text?.proposals?.details?.status}
                            label={proposalStatus(text).find(s => s.value === quickViewData?.status)?.label}
                        />
                        <ConditionalRender renderIf={quickViewData?.status === 'Approved'}>
                            <FieldWrapper
                                label={<FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.primary : colors.green,
                                        marginLeft: '1em'
                                    }}
                                    icon={faCheck}
                                />}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={quickViewData?.status === 'Rejected'}>
                            <FieldWrapper
                                label={<FontAwesomeIcon
                                    style={{
                                        color: colors.red,
                                        marginLeft: '1em'
                                    }}
                                    icon={faClose}
                                />}
                            />
                        </ConditionalRender>
                    </div>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.projectId)}>
                        <FieldWrapper
                            name={text?.proposals?.details?.project}
                            label={quickViewData?.projectId?.projectName}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.clientId)}>
                        <FieldWrapper
                            name={text?.proposals?.details?.client}
                            label={quickViewData?.clientId?.name}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.additionalEmails)}>
                        <FieldWrapper
                            name={text?.proposals?.details?.additionalEmails}
                            label={quickViewData?.additionalEmails?.map(email => email).join(', ')}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.emailSubject)}>
                        <FieldWrapper
                            name={text?.proposals?.create?.emailSubject}
                            label={quickViewData?.emailSubject}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.emailMessage)}>
                        <FieldWrapper
                            name={text?.proposals?.create?.emailMessage}
                            label={quickViewData?.emailMessage}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.fields)}>
                        <table className="w-100 mb-md">
                            <thead className="b">
                                <tr style={{
                                    background: theme === 'dark' ? colors.black : colors.white
                                }}>
                                    <th style={{
                                        padding: '10px',
                                    }} className='pr-md'>{text?.proposals?.create?.descriptionTwo}</th>
                                    <th style={{
                                        padding: '10px',
                                    }} className='pr-md'>{text?.proposals?.create?.fields?.notes}</th>
                                    <th style={{
                                        padding: '10px',
                                    }} className='pr-md'>{text?.proposals?.create?.fields?.amount}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quickViewData?.fields?.map((field, index) => (
                                    <tr key={index} style={{
                                        marginTop: '10px',
                                        background: theme === 'dark' ? colors.backgroundSecondary : colors.white
                                    }}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #DDD' }}>{field?.description}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #DDD' }}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: field?.notes?.replace(/\n/g, '<br />') || '',
                                                }}
                                            />
                                        </td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #DDD' }}>${field?.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </ConditionalRender>

                    <FieldWrapper
                        name={text?.proposals?.details?.total}
                        label={`$${quickViewData?.totalCost}`}
                    />
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.images)}>
                        <RenderImages
                            images={quickViewData?.images}
                            disabled={true}
                            openModal={openModal && openModal}
                            closeModal={closeModal && closeModal}
                            setActiveIndex={setActiveIndex && setActiveIndex}
                            activeIndex={activeIndex && activeIndex}
                            isModalOpen={isModalOpen && isModalOpen}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={quickViewData?.statusWasUpdated}>
                        <hr />
                        <FieldWrapper
                            name={text?.client?.create?.signature}
                            label={quickViewData?.signatureName}
                        />
                        <FieldWrapper
                            name={text?.client?.create?.signatureDate}
                            label={moment(quickViewData?.signatureDate).format('MM/DD/YYYY hh:mm A')}
                        />
                        <ConditionalRender renderIf={!isEmpty(quickViewData?.signature)}>
                            <FieldWrapper
                                noHr
                                label={
                                    <CanvasSignature
                                        showWhiteSignature={theme === 'dark'}
                                        points={quickViewData?.signature &&
                                            JSON.parse(quickViewData?.signature)}
                                    />
                                }
                            />
                        </ConditionalRender>
                    </ConditionalRender>
                </div>
            </ConfirmModal>
        )
    }

}

export default ProposalQuickView
