import request from '../request';

/**
 * Fetches all tasks from the API based on the provided parameters.
 * @param {string} [projectId=null] - The ID of the project to filter tasks by.
 * @param {string} [userId=null] - The ID of the user to filter tasks by.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getAllTasks = async (projectId = null, userId = null) => {
    const queryParams = [];

    if (projectId) {
        queryParams.push(`projectId=${projectId}`);
    }
    if (userId) {
        queryParams.push(`userId=${userId}`);
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    const url = `/tasks/all${queryString}`;

    try {
        const { data, status } = await request('GET', url);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get tasks: ${error.message}`);
    }
};

export default getAllTasks;
