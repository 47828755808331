import React, { useState, useEffect } from 'react'
import Wrapper from '../Core/Wrapper'
import { useNavigate } from "react-router-dom";

import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import TasksContainer from '../Tasks/TasksContainer';
import SelectInput from '../Core/SelectInput'
import TimeTracker from '../Core/TimeTracker'
import { Form } from 'react-final-form';
import CallToAction from '../Core/CallToAction'
import EmployeesContainer from '../Employees/EmployeesContainer';

import ProposalsContainer from '../Proposals/ProposalsContainer'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import ConditionalRender from '../Core/ConditionalRender';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext'
import { getTodaysRunningTimesheets } from '../utils/calls'
import { getStartAndEndOfDay } from '../utils/helperFunctions'
import useScreenSize from '../context/useScreenSize';
import ExpandedContainer from '../Expanded/ExpandedContainer';
import RunningTimeSheets from './RunningTimeSheets'



const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    form {
        align-self: flex-end;
        .TimeTracker {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            .time {
                font-family: ${colors.roboto};
            }
        }
    }
`;

const WelcomeEmployeeContainer = ({
    projects,
    projectsIsLoading,
    company,
    user,
    projectId,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    setInventoryColumns,
    initialProject,
    handleChange,
    isRunning,
    fetchUserDetails,
    isCompanyPremium,
    handleToggleChat,
    showHelp,
    handleSendMessage,
    setInventory,
    fetchCompanyEmployees,
    employees,
    tasks,
    tasksIsLoading,
    userRole,
    fetchProjectTasks,
    clients,
    proposals,
    proposalsIsLoading,
    fetchAllProposals
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [runningTimesheets, setRunningTimesheets] = useState([])
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [expanded, setExpanded] = useState('');
    const [isOpen, setIsOpen] = useState(false);



    const fetchRunningTimesheet = async () => {
        try {
            const { startOfToday, endOfToday } = getStartAndEndOfDay();

            const res = await getTodaysRunningTimesheets({
                startOfToday,
                endOfToday
            });
            if (res.status === 200) {
                setRunningTimesheets(res.data?.timesheets)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        // Fetch initially
        fetchRunningTimesheet();

        // Set up the interval to run every minute (60000 milliseconds)
        const intervalId = setInterval(() => {
            fetchRunningTimesheet();
        }, 60000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const formatMilliseconds = (milliseconds) => {
        let totalSeconds = Math.floor(milliseconds / 1000);
        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        let seconds = totalSeconds % 60;

        return `${hours} ${text?.dashboard?.hours}, ${minutes} ${text?.dashboard?.minutes}, ${seconds} ${text?.dashboard?.seconds}`;
    }



    // TODO: CHECK IF THE TASK IS COMPLETE OR NOT AND FILTER IF ALL TASKS ARE COMPLETE IN THAT PROJECT
    const adminOrAssignedProjects = projects?.filter(project => {
        // Check if the user is an admin
        const isAdmin = project.adminEmployees?.some(employee => employee?.id === user?.id);

        // Check if the user is assigned to at least one task in the separate tasks array
        const isAssigned = tasks?.some(task => task.taskAssignedTo?.id === user?.id);

        // Include the project if the user is an admin or assigned to at least one task
        return isAdmin || isAssigned;
    });

    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });

    return (
        <StyledDiv>

            <Wrapper
                width={isDesktop ? '50%' : '100%'}
                flex={'1'}
                padding={isDesktop && '0 15px 0 0'}
                borderRight={isDesktop && `
                    ${theme === 'dark' ? colors.backgroundSecondary : colors.black} 1px solid
                `}
            >

                <EmployeesContainer
                    employees={employees}
                    userRole={userRole}
                    user={user}
                    projects={projects}
                    company={company}
                    fetchCompanyEmployees={fetchCompanyEmployees}
                    title={text?.dashboard?.employees}
                    isDashboard
                    setExpanded={setExpanded}
                    expanded={expanded}
                />

                <InventoryHomeContainer
                    user={user}
                    fetchUserDetails={fetchUserDetails}
                    isCompanyPremium={isCompanyPremium}
                    inventory={inventory}
                    fetchInventoryProducts={fetchInventoryProducts}
                    inventorysLoading={inventorysLoading}
                    setInventory={setInventory}
                    inventoryColumns={inventoryColumns}
                    setInventoryColumns={setInventoryColumns}
                    fetchAttributes={fetchAttributes}
                    projects={projects}
                    employees={employees}
                    title={text?.dashboard?.inventory}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />
            </Wrapper>
            <Wrapper
                width={isDesktop ? '50%' : '100%'}
                flex={'1'}
                padding={isDesktop && '0 0 0 15px'}
            >
                <ConditionalRender renderIf={isDesktop}>
                    <Wrapper
                        className='mt-md'
                        title={text?.dashboard?.timeClock}
                        button={
                            <CallToAction
                                type="button"
                                onClick={() => navigate(`/logs`)}
                                text={text?.timeTracker?.manageAllLogs}
                            />
                        }
                    >
                        <TimeTracker
                            disabled={!projectId}
                            projectId={projectId}
                            fetchInventoryProducts={fetchInventoryProducts}
                            inventory={inventory}
                            inventorysLoading={inventorysLoading}
                            projects={projects}
                            user={user}
                        />

                        <ConditionalRender renderIf={!isEmpty(runningTimesheets)}>
                            <CallToAction
                                className='mt-md'
                                onClick={() => setIsOpen(!isOpen)}
                                text={text?.dashboard?.viewRunning}
                                style={{
                                    alignSelf: 'center',
                                    display: 'flex',
                                    width: '100%',
                                }}
                                btnStyle={{
                                    color: 'white',
                                }}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={isEmpty(runningTimesheets)}>
                            <p className='mb-md'>
                                {text?.dashboard?.noEmployees}
                            </p>
                        </ConditionalRender>
                        <RunningTimeSheets
                            runningTimesheets={runningTimesheets}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                        />

                    </Wrapper>
                </ConditionalRender>

                <TasksContainer
                    projects={adminOrAssignedProjects.filter((x) => x?.projectStatus !== 'completed')}
                    adminProjects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                    user={user}
                    employees={employees}
                    tasks={
                        tasks?.filter((task) =>
                            adminOrAssignedProjects?.some((project) => project?._id === task?.taskProject?._id)
                        ).filter((x) => x?.projectStatus !== 'completed')
                    }
                    tasksIsLoading={tasksIsLoading}
                    fetchProjectTasks={fetchProjectTasks}
                    title={text?.dashboard?.tasks}
                    setExpanded={setExpanded}
                    expanded={expanded}
                />
                <ConditionalRender renderIf={!isEmpty(adminProjects)}>
                    <ProposalsContainer
                        title={'Proposals'}
                        projects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                        adminProjects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                        user={user}
                        clients={clients}
                        proposals={
                            proposals?.filter((proposal) =>
                                adminProjects?.some((project) => project?._id === proposal?.projectId?._id)
                            ).filter((x) => x?.projectStatus !== 'completed')
                        }
                        proposalsIsLoading={proposalsIsLoading}
                        fetchAllProposals={fetchAllProposals}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />
                </ConditionalRender>
            </Wrapper>

            {/* Expanded Components */}
            <ExpandedContainer
                expanded={expanded}
                setExpanded={setExpanded}
                user={user}
                clients={clients}
                adminProjects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                proposals={
                    proposals?.filter((proposal) =>
                        adminProjects?.some((project) => project?._id === proposal?.projectId?._id)
                    ).filter((x) => x?.projectStatus !== 'completed')
                }
                proposalsIsLoading={proposalsIsLoading}
                fetchAllProposals={fetchAllProposals}


                projects={adminOrAssignedProjects.filter((x) => x?.projectStatus !== 'completed')}
                employees={employees}
                tasks={
                    tasks?.filter((task) =>
                        adminOrAssignedProjects?.some((project) => project?._id === task?.taskProject?._id)
                    ).filter((x) => x?.projectStatus !== 'completed')
                }
                tasksIsLoading={tasksIsLoading}
                fetchProjectTasks={fetchProjectTasks}

                fetchUserDetails={fetchUserDetails}
                isCompanyPremium={isCompanyPremium}
                inventory={inventory}
                fetchInventoryProducts={fetchInventoryProducts}
                inventorysLoading={inventorysLoading}
                setInventory={setInventory}
                inventoryColumns={inventoryColumns}
                setInventoryColumns={setInventoryColumns}
                fetchAttributes={fetchAttributes}
            />

        </StyledDiv>
    )
}

export default WelcomeEmployeeContainer