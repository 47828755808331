import React, { useState, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import CallToAction from '../Core/CallToAction';
import TasksContainer from '../Tasks/TasksContainer';
import RenderImages from '../Core/RenderImages';
import { isEmpty } from 'lodash';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import ExpandedContainer from '../Expanded/ExpandedContainer';
import Wrapper from '../Core/Wrapper';
import { useLanguage } from '../context/LanguageContext';
import EmployeesContainer from '../Employees/EmployeesContainer';
import RenderFiles from '../Core/RenderFiles';
import TimeLogContainer from '../TimeLog/TimeLogContainer';
import FullCalendarContainer from '../Calendar/FullCalendar';
import colors from '../globalStyles.scss';
import useScreenSize from '../context/useScreenSize';
import { useTheme } from '../context/ThemeContext';
import {
    downloadProjectFile
} from '../utils/calls';
import { saveAs } from 'file-saver';
import ShowJustMap from '../Core/ShowJustMap';

const DetailsProjectEmployeeContainer = ({
    project,
    projects,
    user,
    employees,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchInventoryProducts,
    inventory,
    inventorysLoading,
    inventoryColumns,
    setInventoryColumns,
    fetchAttributes,
    projectId,
    autocompleteValue,
    designs,
    openCarousel,
    closeModal,
    setActiveIndex,
    activeIndex,
    isModalOpen,
    timeLogs,
    userRole,
    projectIsComplete,
    fetchTimeLogs,
    logsIsLoading,
    events,
    eventsIsLoading,
    fetchEvents,
    projectIsLoading,
    employeesIsLoading,
    company,
    fetchCompanyEmployees,
    fetchEventCategories,
    setHistoryStatus,
    historyStatus,
    fieldNames
}) => {
    const { text } = useLanguage()
    const { theme } = useTheme()
    const { isDesktop, isTablet } = useScreenSize()
    const renderFieldLabel = (key) => {
        return fieldNames?.find(field => key?.toLowerCase() === `${field?.inputType}-${field?.name?.toLowerCase()}`)?.label
    }

    const navigate = useNavigate()
    const location = useLocation();
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [expanded, setExpanded] = useState('');

    const EmployeeIsAdmin = user?.roleName === 'Employee' && project?.adminEmployees?.some((x) => x.id === user?._id)
    const id = projectId || queryString.parse(location.search)?.id


    const handleDownloadFile = async (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const res = await downloadProjectFile({ projectId: project?.id, file });
            if (res.status === 200) {
                const contentType = res.headers['content-type'];
                const blob = new Blob([res.data], { type: contentType });
                saveAs(blob, file.originalName);
            } else {
                throw new Error('Failed to download file');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    useMemo(() => {
        setSelectedEmployees(project?.assignedEmployees?.map((x) => x?.id))
    }, [project]);

    return (
        <>
            <div className='mb-lg tx-left'>
                <h4>
                    {text?.projects?.details?.details}
                </h4>
                <hr />
                <div className='flex items-center mb-md'>
                    <FontAwesomeIcon
                        onClick={() => setHistoryStatus(!historyStatus)}
                        style={{
                            color: theme === 'dark' ? colors.secondary : colors.blue,
                            cursor: 'pointer',
                            marginRight: '1em'
                        }}
                        icon={faHistory}
                    />
                    <ConditionalRender renderIf={project?.projectStatus === 'completed'}>
                        <p className='ml-md'>
                            {moment(project?.updatedAt).format('MM-DD-YYYY')}
                        </p>
                    </ConditionalRender>
                    {project?.projectStatus === 'completed' && (
                        <FontAwesomeIcon
                            style={{
                                color: theme === 'dark' ? colors.secondary : colors.green,
                                marginLeft: '1em'
                            }} icon={faCheck}
                        />
                    )}
                </div>
            </div>
            <div className={isDesktop ? 'desktop flex' : isTablet ? 'tablet' : 'phone'}
            >
                <Wrapper
                    flex={'1'}
                    padding={isDesktop && '0 15px 0 0'}
                    width={isDesktop ? '50%' : '100%'}
                    borderRight={isDesktop && `
                        ${theme === 'dark' ? colors.backgroundSecondary : colors.black} 1px solid
                    `}
                >
                    <Wrapper>
                        <FieldWrapper
                            name={text?.projects?.details?.name}
                            label={project?.projectName}
                        />
                        <FieldWrapper
                            name={text?.projects?.details?.category}
                            label={project?.projectCategory?.label}
                        />
                        <div className='flex flex-column mb-md'>
                            <ConditionalRender renderIf={!isEmpty(autocompleteValue)}>
                                <FieldWrapper
                                    noHr
                                    name={text?.projects?.details?.location}
                                />
                                <ShowJustMap
                                    location={project?.projectLocation}
                                />
                            </ConditionalRender>
                        </div>
                        <FieldWrapper
                            name={text?.tasks?.create?.description}
                            label={project?.projectDescription}
                        />
                    </Wrapper>
                    {/* Logs */}
                    <TimeLogContainer
                        user={user}
                        timeLogs={timeLogs?.filter((x) => !isEmpty(x?.dailyLog))}
                        id={projectId}
                        logsIsLoading={logsIsLoading}
                        fetchTimeLogs={fetchTimeLogs}
                        projectId={projectId}
                        projects={projects}
                        project={project}
                    />

                    {/* Assign employees */}
                    <EmployeesContainer
                        filteredEmployees={employees?.filter((x) => x?._id !== user?._id && selectedEmployees?.includes(x._id))}
                        employees={employees}
                        userRole={userRole}
                        user={user}
                        projects={projects}
                        company={company}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        project={project}
                        employeesIsLoading={employeesIsLoading}
                        title={text?.projects?.details?.employees}
                        checkboxSelection={false}
                        isDetailsProject
                        EmployeeIsAdmin={EmployeeIsAdmin}
                        projectIsComplete={projectIsComplete}
                        id={id}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />

                    < ConditionalRender renderIf={!isEmpty(tasks)}>
                        <TasksContainer
                            projects={projects}
                            user={user}
                            employees={employees}
                            tasks={tasks}
                            tasksIsLoading={tasksIsLoading}
                            fetchProjectTasks={fetchProjectTasks}
                            isProject
                            projectId={projectId}
                            title={text?.dashboard?.tasks}

                            setExpanded={setExpanded}
                            expanded={expanded}
                        />
                    </ConditionalRender>
                </Wrapper>

                <Wrapper
                    width={isDesktop ? '50%' : '100%'}
                    flex={'1'}
                    padding={isDesktop && '0 0 0 15px'}
                >
                    {/* Client */}
                    <ConditionalRender renderIf={!isEmpty(project?.client)}>
                        <Wrapper title={'Client'}>
                            <FieldWrapper
                                goto={`/clients/details?id=${project?.client?._id}`}
                                name={text?.client?.create?.name}
                                label={project?.client?.name}
                            />
                        </Wrapper>
                    </ConditionalRender>

                    {/* Design Images */}
                    <ConditionalRender renderIf={!isEmpty(designs)}>
                        <Wrapper title={text?.projects?.details?.designs}>
                            <div className='flex flex-column'>
                                <RenderImages
                                    images={designs}
                                    openModal={openCarousel}
                                    closeModal={closeModal}
                                    setActiveIndex={setActiveIndex}
                                    activeIndex={activeIndex}
                                    isModalOpen={isModalOpen}
                                    disabled
                                />
                            </div>
                        </Wrapper>
                    </ConditionalRender>
                    <InventoryHomeContainer
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        setInventoryColumns={setInventoryColumns}
                        fetchAttributes={fetchAttributes}
                        isProject
                        projectId={projectId}
                        project={project}
                        title={text?.projects?.details?.inventory}
                        noDataText={text?.projects?.details?.noinventory}
                        projectIsComplete={projectIsComplete}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />

                    <Wrapper
                        noDataText={isEmpty(project?.files) && text?.projects?.details?.noFilesFound}
                        title={text?.projects?.details?.filesTitle}
                    >
                        <RenderFiles
                            files={project?.files}
                            roleName={user?.roleName}
                            handleDownloadFile={handleDownloadFile}
                            userId={user?._id}
                            readOnly
                        />
                    </Wrapper>
                    {/* Events */}
                    <Wrapper
                        title={text?.calendar?.calendar}
                        button={
                            <CallToAction
                                text={text?.calendar?.btnViewCalendar}
                                type='button'
                                onClick={() => navigate(`/calendar`)}
                            />
                        }
                    >
                        <FullCalendarContainer
                            events={events}
                            eventsIsLoading={eventsIsLoading}
                            user={user}
                            projects={projects}
                            employees={employees}
                            employeesIsLoading={employeesIsLoading}
                            fetchEvents={fetchEvents}
                            isProject
                            projectId={projectId}
                            projectIsLoading={projectIsLoading}
                            fetchEventCategories={fetchEventCategories}
                        />
                    </Wrapper>

                    {/* Advance Fields */}
                    <ConditionalRender renderIf={!isEmpty(project?.advanceFields)}>
                        <Wrapper
                            title={text?.inventory?.details?.form?.title}
                        >
                            {
                                Object.entries(project?.advanceFields || {}).map(([key, value], index) => {
                                    const keyName = key.split('-')[0];
                                    console.log(keyName)
                                    if (keyName === 'number') {
                                        return (
                                            <FieldWrapper
                                                key={index}
                                                name={renderFieldLabel(key)}
                                                label={
                                                    <div className='flex flex-column'>
                                                        <span className='flex mb-sm'>{value?.value}</span>
                                                        <ConditionalRender renderIf={value?.notification?.isChecked}>
                                                            <FieldWrapper
                                                                name={`${text?.inventory?.details?.form?.nodifyMe} ${text?.inventory?.details?.form?.numberBellow}`}
                                                                label={value?.notification?.value}
                                                                noHr
                                                            />
                                                        </ConditionalRender>
                                                    </div>
                                                }
                                            />
                                        )
                                    } else if (keyName === 'date') {
                                        return (
                                            <FieldWrapper
                                                key={index}
                                                name={renderFieldLabel(key)}
                                                label={moment(value).format('MM-DD-YYYY hh:mm A')}
                                            />
                                        )
                                    } else if (keyName === 'location') {
                                        return (
                                            <div>
                                                <FieldWrapper
                                                    noHr
                                                    key={index}
                                                    name={renderFieldLabel(key)}
                                                />
                                                <ConditionalRender renderIf={!isEmpty(value)}>
                                                    <ShowJustMap
                                                        location={value}
                                                    />
                                                </ConditionalRender>
                                            </div>
                                        )
                                    } else if (keyName === 'phone') {
                                        return (
                                            <FieldWrapper
                                                key={index}
                                                name={`${renderFieldLabel(key)}`}
                                                label={<a
                                                    href={`tel:${value}`}
                                                >
                                                    {`+${value}`}
                                                </a>}
                                            />

                                        );
                                    }
                                    return (
                                        <FieldWrapper
                                            key={index}
                                            name={renderFieldLabel(key)}
                                            label={`${keyName === 'amount' ? '$' : ''}${value}`}
                                            isLink={keyName === 'link'}
                                        />
                                    );
                                })
                            }
                        </Wrapper>
                    </ConditionalRender>
                </Wrapper>

                <ExpandedContainer
                    expanded={expanded}
                    setExpanded={setExpanded}
                    projects={projects}
                    user={user}
                    employees={employees}
                    tasks={tasks}
                    tasksIsLoading={tasksIsLoading}
                    fetchProjectTasks={fetchProjectTasks}
                    isProject
                    projectId={projectId}

                    inventory={inventory}
                    fetchInventoryProducts={fetchInventoryProducts}
                    inventorysLoading={inventorysLoading}
                    inventoryColumns={inventoryColumns}
                    setInventoryColumns={setInventoryColumns}
                    fetchAttributes={fetchAttributes}
                    project={project}
                    title={text?.projects?.details?.inventory}
                    noDataText={text?.projects?.details?.noinventory}
                    projectIsComplete={projectIsComplete}
                />
            </div >
        </>
    )
}

export default DetailsProjectEmployeeContainer