import React, { useState } from 'react';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Form } from 'react-final-form';
import {
    login,
    forgotPassword,
    verifyPin,
    resendPin,
    submitClientPinByEmail,
    loginClient
} from '../utils/calls'
import FieldWithValidation from '../Core/FieldWithValidation'
import FormActions from '../Core/FormActions';
import CallToAction from '../Core/CallToAction';
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';
import './styles.scss'
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';

const LoginContainer = ({
    loginUser,
    clientInfo,
    clientIsLoading,
    isLoggedInAsClient,
    setClientIsLoading,
    setClientInfo,
    setIsLoggedInAsClient,
    clientLogin
}) => {
    const { text } = useLanguage();
    let formReference;
    const location = useLocation();

    let { client } = queryString.parse(location.search);

    const navigate = useNavigate();
    const [logginError, setLogginError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [wrongEmail, setWrongEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [pinSent, setPinSent] = useState(false);
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(1); // Step for 2FA
    const [resendPinMessage, setResendPinMessage] = useState('');
    const [isClientPortal, setIsClientPortal] = useState(client);
    const [requestPin, setRequestPin] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            const res = await login(values);
            if (res.data.success && res.data.message === '2FA required') {
                setStep(2); // Move to 2FA step
                setEmail(values.email);
            } else if (res.status === 200) {
                const user = res.data;
                loginUser(user.token, user._id, user.companyId);
                setLogginError(false);
                setLoading(false);
                navigate("/");
                formReference.change('email', null);
                setIsLoggedInAsClient(false)
                setResendPinMessage('');
                setStep(1);
            } else {
                setLoading(false);
                setLogginError(true);
            }
        } catch (error) {
            setLoading(false);
            setLogginError(true);
        }
        formReference.change('password', null);
    };

    const handleLoginClient = async (values) => {
        setClientIsLoading(true);
        try {
            const res = await loginClient(values);
            if (res.status === 200) {
                navigate("/");
                setIsLoggedInAsClient(true);
                formReference.change('email', null);
                clientLogin(res.data.clientId, res.data.companyId);
            } else {
                setIsLoggedInAsClient(false);
                setClientIsLoading(false);
            }
        } catch (error) {
            setIsLoggedInAsClient(false);
            setClientIsLoading(false);
        }
        formReference.change('pin', null);
    }

    const handleVerifyPin = async (values) => {
        setLoading(true);
        try {
            const res = await verifyPin({ email, pin: values.pin });
            if (res.status === 200) {
                const user = res.data;
                loginUser(user.token, user._id, user.companyId);
                setLogginError(false);
                setLoading(false);
                navigate("/");
                setResendPinMessage('');
                setStep(1);
                formReference.change('email', null);

            } else {
                setLoading(false);
                setLogginError(true);
            }
        } catch (error) {
            setLoading(false);
            setLogginError(true);
        }
        formReference.change('pin', null);

    };
    const handleResendPin = async () => {
        setLoading(true);
        try {
            const res = await resendPin({ email });
            if (res.data.success) {
                setResendPinMessage('PIN has been resent');
            } else {
                setLogginError(true);
            }
        } catch (error) {
            setLogginError(true);
        }
        setLoading(false);
    };

    const submitPasswordReset = async (values) => {
        const email = values?.email
        try {
            const res = await forgotPassword(email);
            if (res.status === 200) {
                setEmailSent(true);
                setWrongEmail(false)
            } else {
                setEmailSent(false);
                setWrongEmail(true)
            }
        } catch (error) {
            setEmailSent(false);
            setWrongEmail(true)
        }
        if (formReference) {
            formReference?.change('email', null);
            formReference?.change('password', null);
        }
    }

    const handleRequestClientPin = async (values) => {
        const email = values?.email
        try {
            const res = await submitClientPinByEmail(email);
            if (res.status === 200) {
                setPinSent(true);
                setWrongEmail(false)
            } else {
                setPinSent(false);
                setWrongEmail(true)
            }
        } catch (error) {
            setPinSent(false);
            setWrongEmail(true)
        }
        if (formReference) {
            formReference?.change('email', null);
        }

    }

    const emailValidator = composeValidators(
        isRequired(text?.login?.validation?.email?.required),
        isValidEmail(text?.login?.validation?.email?.valid)
    )

    const passwordValidator = composeValidators(
        isRequired(text?.login?.validation?.password?.required)
    )

    const clientPinValidator = composeValidators(
        isRequired(text?.login?.validation?.pin?.required)
    );

    const handleForgotPassword = () => {
        setShowForgotPassword(!showForgotPassword);
    };

    const handleRequestPin = () => {
        setRequestPin(!requestPin);
    }

    const handleClientPortal = () => {
        setIsClientPortal('yes');
    }

    const pinValidator = composeValidators(
        isRequired(text?.login?.validation?.pin?.required)
    );

    // if (loading) return <Loader count={1} />

    return (
        emailSent ? (
            <div style={{
                color: colors.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1'
            }}>
                {text?.login?.emailSent}
            </div>
        ) : pinSent ? (
            <div style={{
                color: colors.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1'
            }}>
                {text?.login?.pinSent}
            </div>
        ) : (
            <div className="LoginContainer wrapper">
                <ConditionalRender renderIf={isClientPortal !== 'yes'}>
                    <ConditionalRender renderIf={!showForgotPassword && step === 1}>
                        <Form
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting }) => {
                                formReference = form;

                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FieldWithValidation
                                            className='field-style'
                                            name='email'
                                            validate={emailValidator}
                                            label={text?.login?.fields?.email}
                                            component={'input'}
                                            type='email'
                                        />
                                        <FieldWithValidation
                                            className='field-style'
                                            name='password'
                                            togglePasswordVisibility={togglePasswordVisibility}
                                            showPassword={showPassword}
                                            validate={passwordValidator}
                                            label={text?.login?.fields?.password}
                                            component={'input'}
                                            type={showPassword ? 'text' : 'password'}
                                        />
                                        <FormActions
                                            type="submit"
                                            callToActionText={text?.login?.button}
                                            position={'center'}
                                            disabled={submitting}
                                            btnStyle={{
                                                border: `2px solid ${colors.black}`,
                                                color: colors.white,
                                                hoverBackgroundColor: colors.white,
                                                padding: '0.5em 1em',
                                                fontWeight: '600',
                                            }}
                                        />
                                        <CallToAction
                                            btnWidth='auto'
                                            btnHeight={'auto'}
                                            text={text?.login?.register}
                                            linkTo={'/register'}
                                            btnStyle={{
                                                padding: '0.3em 1em',
                                                border: `2px solid ${colors.white}`
                                            }}
                                            style={{
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'none',
                                                marginTop: '1em'
                                            }}
                                        />
                                        <div className='error small'>
                                            {
                                                logginError && text?.login?.validation?.email?.invalid
                                            }
                                        </div>
                                    </form>
                                );
                            }}
                        />
                        <CallToAction
                            btnWidth='auto'
                            btnHeight={'auto'}
                            btnStyle={{
                                padding: '0.3em 1em',
                                color: colors.black,
                                hoverBackgroundColor: colors.black,
                                hoverColor: colors.white
                            }}
                            style={{
                                alignSelf: 'center',
                                justifyContent: 'center',
                                backgroundColor: colors.white,
                                marginTop: '3em'
                            }}
                            text={text?.login?.forgotPassword}
                            onClick={handleForgotPassword}
                        />
                        <CallToAction
                            btnWidth='auto'
                            btnHeight={'auto'}
                            btnStyle={{
                                padding: '0.3em 1em',
                                color: colors.black,
                                hoverBackgroundColor: colors.black,
                                hoverColor: colors.white
                            }}
                            style={{
                                alignSelf: 'center',
                                justifyContent: 'center',
                                backgroundColor: colors.white,
                                marginTop: '3em'
                            }}
                            text={text?.login?.clientPortal}
                            onClick={handleClientPortal}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={step === 2}>
                        <Form
                            onSubmit={handleVerifyPin}
                            render={({ handleSubmit, form, submitting }) => {
                                formReference = form;

                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FieldWithValidation
                                            className='field-style'
                                            name='pin'
                                            validate={pinValidator}
                                            label={'Pin'}
                                            component={'input'}
                                            type='text'
                                        />
                                        <FormActions
                                            type="submit"
                                            callToActionText={'Verify'}
                                            position={'center'}
                                            disabled={submitting}
                                            btnStyle={{
                                                border: `2px solid ${colors.black}`,
                                                color: colors.white,
                                                hoverBackgroundColor: colors.white,
                                                padding: '0.5em 1em',
                                                fontWeight: '600',
                                            }}
                                        />
                                        <CallToAction
                                            btnWidth='auto'
                                            btnHeight={'auto'}
                                            text={'Resend Pin'}
                                            onClick={handleResendPin}
                                            btnStyle={{
                                                padding: '0.3em 1em',
                                                border: `2px solid ${colors.black}`
                                            }}
                                            style={{
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'none',
                                                marginTop: '1em'
                                            }}
                                        />
                                        <ConditionalRender renderIf={!isEmpty(resendPinMessage)}>
                                            <p className='small'>
                                                {resendPinMessage}
                                            </p>
                                        </ConditionalRender>
                                        <div className='error small'>
                                            {
                                                logginError && 'Invalid Pin'
                                            }
                                        </div>
                                    </form>
                                );
                            }}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={showForgotPassword}>
                        <Form
                            onSubmit={submitPasswordReset}
                            render={({ handleSubmit, submitting }) => (
                                <form onSubmit={handleSubmit}>
                                    <FieldWithValidation
                                        className='field-style'
                                        validate={emailValidator}
                                        label={text?.login?.fields?.email}
                                        name='email'
                                        component={'input'}
                                        type='email'
                                    />
                                    <FormActions
                                        type="submit"
                                        callToActionText={text?.login?.sendButton}
                                        position='center'
                                        disabled={submitting}
                                        btnStyle={{
                                            border: `2px solid ${colors.black}`,
                                            color: colors.white,
                                            hoverBackgroundColor: colors.white,
                                            padding: '0.5em 1em',
                                            fontWeight: '600',
                                        }}
                                    />
                                    <div className='error small'>
                                        {
                                            wrongEmail && text?.login?.validation?.email?.wrongEmail
                                        }
                                    </div>
                                </form>
                            )}
                        />
                        <CallToAction
                            btnWidth='auto'
                            btnHeight={'auto'}
                            btnStyle={{
                                padding: '0.3em 1em',
                                color: colors.black,
                                hoverBackgroundColor: colors.black,
                                hoverColor: colors.white
                            }}
                            style={{
                                alignSelf: 'center',
                                justifyContent: 'center',
                                backgroundColor: colors.white,
                                marginTop: '3em'
                            }}
                            text={text?.login?.back}
                            onClick={handleForgotPassword}
                        />
                    </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender renderIf={isClientPortal === 'yes' && !requestPin}>
                    <h2>{text?.login?.clientPortal}</h2>
                    <Form
                        onSubmit={handleLoginClient}
                        render={({ handleSubmit, form, submitting }) => {
                            formReference = form;

                            return (
                                <form onSubmit={handleSubmit}>
                                    <FieldWithValidation
                                        className='field-style'
                                        name='email'
                                        validate={emailValidator}
                                        label={text?.login?.fields?.email}
                                        component={'input'}
                                        type='email'
                                    />
                                    <FieldWithValidation
                                        className='field-style'
                                        name='pin'
                                        validate={clientPinValidator}
                                        label={text?.login?.pin}
                                        component={'input'}
                                        type='text'
                                    />
                                    <FormActions
                                        type="submit"
                                        callToActionText={text?.login?.button}
                                        position={'center'}
                                        disabled={submitting}
                                        btnStyle={{
                                            border: `2px solid ${colors.black}`,
                                            color: colors.white,
                                            hoverBackgroundColor: colors.white,
                                            padding: '0.5em 1em',
                                            fontWeight: '600',
                                        }}
                                    />
                                    <div className='error small'>
                                        {
                                            logginError && text?.login?.validation?.email?.invalid
                                        }
                                    </div>
                                </form>
                            );
                        }}
                    />
                    <CallToAction
                        btnWidth='auto'
                        btnHeight={'auto'}
                        btnStyle={{
                            padding: '0.3em 1em',
                            color: colors.black,
                            hoverBackgroundColor: colors.black,
                            hoverColor: colors.white
                        }}
                        style={{
                            alignSelf: 'center',
                            justifyContent: 'center',
                            backgroundColor: colors.white,
                            marginTop: '3em'
                        }}
                        text={text?.login?.requestPin}
                        onClick={handleRequestPin}
                    />
                    <CallToAction
                        btnWidth='auto'
                        btnHeight={'auto'}
                        btnStyle={{
                            padding: '0.3em 1em',
                            color: colors.black,
                            hoverBackgroundColor: colors.black,
                            hoverColor: colors.white
                        }}
                        style={{
                            alignSelf: 'center',
                            justifyContent: 'center',
                            backgroundColor: colors.white,
                            marginTop: '3em'
                        }}
                        text={text?.login?.backToLogin}
                        onClick={() => {
                            setIsClientPortal('no');
                            setRequestPin(false);
                            setShowForgotPassword(false);
                            setStep(1);
                            navigate('/login')
                        }}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={isClientPortal === 'yes' && requestPin}>
                    <h2>{text?.login?.clientPortal}</h2>
                    <Form
                        onSubmit={handleRequestClientPin}
                        render={({ handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <FieldWithValidation
                                    className='field-style'
                                    validate={emailValidator}
                                    label={text?.login?.fields?.email}
                                    name='email'
                                    component={'input'}
                                    type='email'
                                />
                                <FormActions
                                    type="submit"
                                    callToActionText={text?.login?.requestPin}
                                    position='center'
                                    disabled={submitting}
                                    btnStyle={{
                                        border: `2px solid ${colors.black}`,
                                        color: colors.white,
                                        hoverBackgroundColor: colors.white,
                                        padding: '0.5em 1em',
                                        fontWeight: '600',
                                    }}
                                />
                                <div className='error small'>
                                    {
                                        wrongEmail && text?.login?.validation?.email?.wrongEmail
                                    }
                                </div>
                            </form>
                        )}
                    />
                    <CallToAction
                        btnWidth='auto'
                        btnHeight={'auto'}
                        btnStyle={{
                            padding: '0.3em 1em',
                            color: colors.black,
                            hoverBackgroundColor: colors.black,
                            hoverColor: colors.white
                        }}
                        style={{
                            alignSelf: 'center',
                            justifyContent: 'center',
                            backgroundColor: colors.white,
                            marginTop: '3em'
                        }}
                        text={text?.login?.backToClientPortal}
                        onClick={() => {
                            setIsClientPortal('yes');
                            setRequestPin(false);
                            setShowForgotPassword(false);
                        }}
                    />
                </ConditionalRender>
            </div>
        )
    );
};

export default LoginContainer;
