// utils/auth.js
export const setToken = (token) => {
    localStorage.setItem("authToken", token);
};

export const getToken = () => {
    return localStorage.getItem("authToken");
};

export const removeToken = () => {
    localStorage.removeItem("authToken");
};

// -------------------------------------------
export const setUserId = (id) => {
    localStorage.setItem('userId', id)
}

export const getUserId = () => {
    return localStorage.getItem('userId')
}

export const removeUserId = () => {
    localStorage.removeItem("userId");
}
// -------------------------------------------
export const setCompanyId = (id) => {
    localStorage.setItem('companyId', id)
}

export const getCompanyId = () => {
    return localStorage.getItem('companyId')
}

export const removeCompanyId = () => {
    localStorage.removeItem("companyId");
}

export const removeAccessToken = () => {
    localStorage.removeItem("access_token");
};

export const setLoggedInAsClientId = (clientId) => {
    localStorage.setItem("loggedInAsClientId", clientId);
}

export const removeLoggedInAsClientId = () => {
    localStorage.removeItem("loggedInAsClientId");
}

export const getLoggedInAsClientId = () => {
    return localStorage.getItem("loggedInAsClientId");
}