import React from 'react';
import ConfirmModal from '../Core/ConfirmModal';
import { getTitleFromMilliseconds, getDeadlineStatus } from '../utils/helperFunctions';

import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import { status, priority } from '../utils/helperFunctions';
import useScreenSize from '../context/useScreenSize';
import moment from 'moment';
import { useTheme } from '../context/ThemeContext';
import colors from '../globalStyles.scss';

const TasksQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    noOverlay

}) => {
    const { text, formatDate } = useLanguage();
    const { isDesktop } = useScreenSize();
    const { theme } = useTheme();
    return (
        <ConfirmModal
            toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
            isOpen={isQuickViewOpen}
            noOverlay={noOverlay}
            shaddow
            width={isDesktop ? '80%' : '100%'}
            height={isDesktop ? '80%' : '100%'}
        >
            <div className='w-100'>
                <h4 className='mb-md'>{quickViewData?.taskTitle}</h4>
                <FieldWrapper
                    name={text?.logs?.totalTime}
                    label={getTitleFromMilliseconds(text, quickViewData?.totalTime)}
                />
                <ConditionalRender renderIf={quickViewData?.taskStatus === 'completed'}>
                    <FieldWrapper
                        name={text?.analytics?.dateCompleted}
                        label={moment(quickViewData?.dateCompleted).format('MM-DD-YYYY')}
                    />
                </ConditionalRender>
                <FieldWrapper
                    name={text?.tasks?.details?.description}
                    label={quickViewData?.taskDescription}
                />
                <ConditionalRender renderIf={quickViewData?.taskProject}>
                    <FieldWrapper
                        name={text?.tasks?.details?.project}
                        label={quickViewData?.taskProject?.projectName}
                    />
                </ConditionalRender>
                <FieldWrapper
                    name={text?.tasks?.details?.assignedTo}
                    label={quickViewData?.taskAssignedTo ? `${quickViewData?.taskAssignedTo?.firstName} ${quickViewData?.taskAssignedTo?.lastName}` : text?.tasks?.details?.unassigned}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.status}
                    label={status(text).find(p => p.value === quickViewData?.taskStatus)?.label}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.priority}
                    label={priority(text).find(p => p.value === quickViewData?.taskPriority)?.label}
                />
                <FieldWrapper
                    name={<p
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: getDeadlineStatus(text, quickViewData?.taskDeadline).color,
                            marginRight: '10px'
                        }}
                    />}
                    label={getDeadlineStatus(text, quickViewData?.taskDeadline)?.title}
                />
                <FieldWrapper
                    name={text?.tasks?.details?.deadline}
                    label={formatDate(quickViewData?.taskDeadline, 'MM-DD-YYYY')}
                />

                {/* TODOS */}
                <div style={{
                    display: 'flex',
                    flexDirection: isDesktop ? 'row' : 'column',
                    marginRight: '1em',
                }}>
                    <div style={{
                        width: isDesktop ? '50%' : '100%',
                        backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.darkGray,
                        marginRight: '1em',
                        marginBottom: isDesktop ? '0' : '1em',
                        padding: '0.5em'
                    }}>
                        <p
                            className='mb-md flex'
                            style={{
                                fontFamily: colors.openSans
                            }}
                        >
                            {text?.tasks?.create?.todos}
                        </p>
                        {
                            quickViewData?.taskTodos?.filter(todo => !todo?.completed).map(todo => (
                                <div key={todo?._id} className='flex align-center'>
                                    <p className='w-100' style={{
                                        marginLeft: '0.5em',
                                        marginBottom: '0.5em',
                                        padding: '0.5em',
                                        backgroundColor: theme === 'dark' ? colors.black : colors.lightGray,
                                    }}>
                                        {todo?.text}
                                    </p>
                                </div>
                            ))
                        }
                        {
                            (quickViewData?.taskTodos.length === 0 || quickViewData?.taskTodos.every(todo => todo.completed)) && (
                                <p className='mb-md'>
                                    {text?.tasks?.create?.noTodos}
                                </p>
                            )

                        }
                    </div>
                    {/* completed */}
                    <div style={{
                        width: isDesktop ? '50%' : '100%',
                        padding: '0.5em',
                        backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.darkGray,
                    }}>
                        <p className='mb-md flex'
                            style={{
                                fontFamily: colors.openSans
                            }}>
                            {text?.tasks?.create?.completed}
                        </p>
                        {
                            quickViewData?.taskTodos?.filter(todo => todo?.completed).map(todo => (
                                <div key={todo?._id} className='flex align-center'>
                                    <p className='w-100' style={{
                                        textDecoration: 'line-through',
                                        marginLeft: '0.5em',
                                        marginBottom: '0.5em',
                                        padding: '0.5em',
                                        backgroundColor: theme === 'dark' ? colors.black : colors.lightGray,
                                    }}>
                                        {todo?.text}
                                    </p>
                                </div>
                            ))
                        }
                        {
                            (quickViewData?.taskTodos.length === 0 || quickViewData?.taskTodos.every(todo => !todo.completed)) && (
                                <p className='mb-md'>
                                    {text?.tasks?.create?.noCompleted}
                                </p>
                            )

                        }
                    </div>
                </div>
            </div>
        </ConfirmModal>
    )
}

export default TasksQuickView
