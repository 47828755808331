import React, { useState, useEffect, useRef } from 'react'
import { useLanguage } from '../context/LanguageContext'
import CallToAction from '../Core/CallToAction'
import ArrowDown from '../Core/ArrowDown'
import people from '../images/people.png'
import book from '../images/book_icon.png'
import dollar from '../images/dollar_icon.png'
import web from '../images/web_icon.png'
import guy from '../images/guy.png'
import CustomAccordion from '../Core/CustomAccordion'
import reading from '../images/reading.png'
import { useNavigate } from "react-router-dom";
import ConditionalRender from '../Core/ConditionalRender';

import Wave from 'react-wavify'
import styled from 'styled-components';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Carousel } from 'react-responsive-carousel';
import useScreenSize from '../context/useScreenSize';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import colors from '../globalStyles.scss'

const StyledDiv = styled.div`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    scroll-behavior: smooth;

    #landing {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            width: 100%;
            height: 80vh;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
                .heading_wrapper {
                    margin: 1em;
                    .heading {
                        color: ${colors.white};
                        font-size: 5em;
                    }
                    .subheading {
                        color: ${colors.white};
                        font-size:2em;
                        display: flex;
                    }
                }
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 300px;
                    img {
                        width: 100%;
                    }
                }
        }
    }
    #tech {
        background-color: ${colors.white};
        .wrapper {
            margin-top: 50px;
            margin-bottom: 50px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text_wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 100%;
                    h3 {
                        color: ${colors.black};
                        font-size: 3em;
                        text-align: center;
                        margin: 1em;
                        font-weight: 600;
                    }
                }
        }
    }
    #discover {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 50px;
            margin-bottom: 50px;
            width: 100%;
            height: 100%;
            img {
                height: 100px;
                width: 100px;
                object-fit: cover;
            }
            h4 {
                font-size: 2em;
                margin-top: 1em;
                color: ${colors.white};
            }
            p {
                margin-top: 1em;
                font-size: 1.5em;
                color: ${colors.white};
                max-width: 50%;
            }
        }
    }
    #info {
            background-color: ${colors.white};
            .wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                margin-top: 50px;
                margin-bottom: 50px;
                    h3 {
                        color: ${colors.black};
                        font-size: 3em;
                        text-align: right;
                        font-weight: 600;
                        }
                    h4 {
                        color: ${colors.black};
                        font-size: 2em;
                    }
                    p {
                        font-size: 1.5em;
                        text-align: left;
                    }
                .text_wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        img {
                            width: 250px;
                            margin-top: 1em;
                            margin-bottom: 1em;
                        }
                    }
            }
    }

    #services {
        background-color: ${colors.black};
        .wrapper {
            width: 100%;
        }
            p {
                color: ${colors.white};
            }
    }

     #testimonial {
        background: linear-gradient(360deg, hsla(0, 0%, 0%, 1) 0%, hsla(198, 100%, 40%, 1) 100%);
        background-repeat: no-repeat;
        position: relative;
        .wrapper {
            margin-bottom: 50px;
            h4 {
                font-size: 2em;
                text-align: left;
                color: ${colors.white};
            }
            p {
                font-size: 1.2em;
                text-align: left;
                color: ${colors.white};
            }
        }
    }
    #terrain {
        flex-direction: column;
        background-color: ${colors.black};
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .wrapper {
            padding: 1em;
            display: flex;
            width: 70%;
            h1 {
                color: ${colors.white};
                font-size: 2em;
            }
            h2 {
                color: ${colors.white};
                font-size: 1.5em;
                font-weight: 600;
                margin-top: 1em;
            }
            h3 {
                color: ${colors.white};
                font-size: 1em;
                margin-top: 1em;
                font-weight: 600;
            }
        }
        .map {
            overflow: hidden;
            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
`

const HomeLandingContainerTablet = ({
    featuredResources,
    featuredResourcesIsLoading
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();


    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <StyledDiv className='scroll-container'>
            <ParallaxProvider
                scrollAxis={'vertical'}
            >
                <div id='landing' className='w-100'>
                    <div className='wrapper'>
                        <div className='heading_wrapper'>
                            <Parallax speed={20}>
                                <h1 className='heading'>{'ArdidFlow'}</h1>
                                <h2 className='subheading'>
                                    {text?.homeLandingContainer?.title}
                                </h2>
                            </Parallax>
                            <ArrowDown
                                color={colors.white}
                                onClick={() => handleClickScroll('wave1')}
                                className='mt-lg flex'
                                style={{
                                    color: colors.white,
                                    fontSize: '4em',
                                }}
                            />
                        </div>
                        <div className='img-wrapper'>
                            <Parallax speed={20}>
                                <img src={people} alt='people' />
                            </Parallax>
                        </div>
                    </div>
                    <Wave
                        id='wave1'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex', transform: 'rotate(360deg)', height: '50px' }}
                        options={{
                            height: 20,
                            amplitude: 15,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                </div>

                <div id='tech' className='w-100 mt-md'>
                    <div className='wrapper'>
                        <div className='text_wrapper'>
                            <h3>
                                {text?.homeLandingContainer?.tech?.title}
                            </h3>
                        </div>
                        <p style={{
                            fontSize: '1.5em',
                            maxWidth: '50%',
                            textAlign: 'center',
                        }}>
                            {text?.homeLandingContainer?.tech?.description}
                        </p>
                        <Parallax translateY={[50, -80]} speed={8} className='flex justify-center'>
                            <CallToAction
                                btnWidth='auto'
                                btnHeight={'auto'}
                                btnStyle={{
                                    padding: '0.3em 1em',
                                    border: `2px solid ${colors.blue}`,
                                    color: colors.black,
                                    hoverBackgroundColor: colors.white,
                                    fontSize: '2.5em',
                                }}
                                style={{
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: colors.white,
                                    marginTop: '3em'
                                }}
                                text={text?.homeLandingContainer?.tech?.button}
                                onClick={() => handleClickScroll('wave2')}
                            />
                        </Parallax>
                    </div>
                    <Wave
                        id='wave2'
                        className='wave'
                        fill={colors.black}
                        paused={false}
                        style={{ display: 'flex' }}
                        options={{
                            height: 15,
                            amplitude: 30,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                </div>
                <div id='discover'>
                    <div className='wrapper'>
                        <div className={'flex flex-column items-center'}>
                            <div className={'flex'}>
                                <img className='mr-md' src={web} alt={''} />
                                <h4>
                                    {text?.homeLandingContainer?.discover?.titleOne}
                                </h4>
                            </div>
                            <p className='flex justify-content-center align-items-center'>
                                {text?.homeLandingContainer?.discover?.descriptionOne}
                            </p>
                        </div>
                        <div className={'flex flex-column items-center'}>
                            <div className='flex'>
                                <img className='mr-md' src={book} alt={''} />
                                <h4>
                                    {text?.homeLandingContainer?.discover?.titleTwo}
                                </h4>
                            </div>
                            <p>
                                {text?.homeLandingContainer?.discover?.descriptionTwo}
                            </p>
                        </div>
                        <div className={'flex flex-column items-center'}>
                            <div className='flex'>
                                <img className='mr-md' src={dollar} alt={''} />
                                <h4>
                                    {text?.homeLandingContainer?.discover?.titleThree}
                                </h4>
                            </div>
                            <p>
                                {text?.homeLandingContainer?.discover?.descriptionThree}
                            </p>
                        </div>
                        <ArrowDown
                            onClick={() => handleClickScroll('wave3')}
                            className='mt-lg mb-lg flex'
                            style={{
                                color: colors.blue,
                                fontSize: '4em',
                                marginBottom: '50px'
                            }}
                        />
                        ' <Wave
                            id='wave3'
                            className='wave'
                            fill={colors.white}
                            paused={false}
                            style={{ display: 'flex' }}
                            options={{
                                height: 15,
                                amplitude: 30,
                                speed: 0.1,
                                points: 3
                            }}
                        />'
                    </div>
                </div>
                <div id='info'>
                    <div className='wrapper'>
                        <div className='ml-lg mr-lg'>
                            <div className='flex'>
                                <div className='flex-one'>
                                    <div className='text_wrapper'>
                                        <h3>
                                            {text?.homeLandingContainer?.info?.titleTop}
                                        </h3>
                                        <h3>
                                            {text?.homeLandingContainer?.info?.titleBottom}
                                        </h3>
                                        <div className='img-wrapper'>
                                            <img src={reading} alt='reading' />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-one pl-md'>
                                    <div className='mt-md'>
                                        <h4>
                                            {text?.homeLandingContainer?.info?.right?.titleOne}
                                        </h4>
                                        <p>
                                            {text?.homeLandingContainer?.info?.right?.descriptionOne}
                                        </p>
                                    </div>
                                    <div className='mt-md'>
                                        <h4>
                                            {text?.homeLandingContainer?.info?.right?.titleTwo}
                                        </h4>
                                        <p>
                                            {text?.homeLandingContainer?.info?.right?.descriptionTwo}
                                        </p>
                                    </div>
                                    <div className='mt-md'>
                                        <h4>
                                            {text?.homeLandingContainer?.info?.right?.titleThree}
                                        </h4>
                                        <p>
                                            {text?.homeLandingContainer?.info?.right?.descriptionThree}
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <Parallax translateY={[50, -80]} speed={8} className='flex justify-center'>
                                <CallToAction
                                    btnWidth='auto'
                                    btnHeight={'auto'}
                                    btnStyle={{
                                        padding: '0.3em 1em',
                                        border: `2px solid ${colors.blue}`,
                                        color: colors.black,
                                        hoverBackgroundColor: colors.white,
                                        fontSize: '2.5em',
                                    }}
                                    style={{
                                        alignSelf: 'flex-start',
                                        justifyContent: 'flex-start',
                                        backgroundColor: colors.white,
                                        marginTop: '3em',
                                        padding: '0'
                                    }}
                                    text={text?.homeLandingContainer?.info?.right?.button}
                                    onClick={() => navigate(`/register`)}
                                />
                            </Parallax>
                        </div>
                    </div>
                </div>

                <div id="services">
                    <Wave
                        id='wave3'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex', transform: 'rotate(-180deg)', height: '50px' }}
                        options={{
                            height: 10,
                            amplitude: 20,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                    <div className='wrapper'>
                        <div className='flex-one' />
                        <div className={'flex-two'}>
                            <CustomAccordion items={[
                                { title: text?.homeLandingContainer?.services?.projectManagement?.title, body: text?.homeLandingContainer?.services?.projectManagement?.body },
                                { title: text?.homeLandingContainer?.services?.employeeManagement?.title, body: text?.homeLandingContainer?.services?.employeeManagement?.body },
                                { title: text?.homeLandingContainer?.services?.timeTracker?.title, body: text?.homeLandingContainer?.services?.timeTracker?.body },
                                { title: text?.homeLandingContainer?.services?.chat?.title, body: text?.homeLandingContainer?.services?.chat?.body },
                                { title: text?.homeLandingContainer?.services?.analytics?.title, body: text?.homeLandingContainer?.services?.analytics?.body },
                                { title: text?.homeLandingContainer?.services?.calendar?.title, body: text?.homeLandingContainer?.services?.calendar?.body },
                            ]} />
                        </div>
                        <div className={'flex-two'}>
                            <CustomAccordion items={[
                                { title: text?.homeLandingContainer?.services?.dailyLogs?.title, body: text?.homeLandingContainer?.services?.dailyLogs?.body },
                                { title: text?.homeLandingContainer?.services?.proposals?.title, body: text?.homeLandingContainer?.services?.proposals?.body },
                                { title: text?.homeLandingContainer?.services?.clients?.title, body: text?.homeLandingContainer?.services?.clients?.body },
                                { title: text?.homeLandingContainer?.services?.tasks?.title, body: text?.homeLandingContainer?.services?.tasks?.body },
                                { title: text?.homeLandingContainer?.services?.notifications?.title, body: text?.homeLandingContainer?.services?.notifications?.body },
                                { title: text?.homeLandingContainer?.services?.inventory?.title, body: text?.homeLandingContainer?.services?.inventory?.body },
                            ]} />
                        </div>
                        <div className='flex-one' />
                    </div>

                    <p className='mb-md'>
                        {text?.homeLandingContainer?.services?.title}
                    </p>
                    <p className='mb-md'>
                        {text?.homeLandingContainer?.services?.description}
                    </p>
                </div>


                <div id='testimonial'>
                    <Wave
                        id='wave3'
                        className='wave'
                        fill={colors.black}
                        paused={false}
                        style={{ display: 'flex', transform: 'rotate(180deg)' }}
                        options={{
                            height: 50,
                            amplitude: 25,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                    <div className="wrapper flex items-center">
                        <div className='flex-one' />
                        <div style={{ flex: 1, minWidth: '50%' }}>
                            <h4>
                                {text?.homeLandingContainer?.testimonial?.title}
                            </h4>
                            <p className='mt-md' >
                                {text?.homeLandingContainer?.testimonial?.description}
                            </p>
                            <p className='mt-md' style={{ fontWeight: 'bold' }}>
                                {text?.homeLandingContainer?.testimonial?.author}
                            </p>
                        </div>
                        <img src={guy} alt="guy" style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            float: 'left',
                            shapeOutside: 'circle(50%)',
                            clipPath: 'circle(50%)',
                            width: '250px',
                            height: '250px',
                            marginLeft: '20px', // Add margin for spacing
                            marginBottom: '20px',
                            overflow: 'hidden'

                        }} />
                        <div className='flex-one' />
                    </div>
                </div>
                <div id='terrain' style={{ position: 'relative' }}>
                    <div className='wrapper flex flex-column' style={{
                        fontSize: '12px'
                    }}>
                        <div className='pl-md pr-md'>
                            <h1 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.title}
                            </h1>
                            <h2 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.description}
                            </h2>
                            <h3 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.registerToday}
                            </h3>
                        </div>
                        <CallToAction
                            btnWidth='auto'
                            btnHeight={'auto'}
                            btnStyle={{
                                padding: '0.3em 1em',
                                border: `2px solid ${colors.blue}`,
                                color: colors.white,
                                hoverBackgroundColor: colors.blue,
                                fontSize: '2.5em',

                            }}
                            style={{
                                alignSelf: 'flex-start',
                                justifyContent: 'flex-start',
                                backgroundColor: colors.black,
                                padding: '0',
                                marginTop: '3em',
                                alignSelf: 'center',


                            }}
                            text={text?.homeLandingContainer?.terrain?.button}
                            onClick={() => navigate(`/register`)}
                        />
                    </div>

                    <ConditionalRender isLoading={featuredResourcesIsLoading} renderIf={!isEmpty(featuredResources)}>
                        <div style={{
                            width: '100%',
                            padding: '1em',
                            height: '100%',
                        }}>
                            <Carousel
                                showThumbs={false}
                                showIndicators={false}
                                autoPlay
                                infiniteLoop
                                showStatus={false}
                                interval={10000}
                            >
                                {featuredResources.map((resource, index) => {
                                    return (
                                        <div key={index} style={{ width: '100%', textAlign: 'center' }}>
                                            <div style={{
                                                height: '400px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                backgroundColor: colors.backgroundSecondary,
                                            }}>
                                                <div
                                                    style={{
                                                        height: '100%',
                                                    }}
                                                    className={classnames({
                                                        'w-100': !isPhone,
                                                        'mb-md': isPhone,
                                                        'mr-md': !isPhone,
                                                    })}
                                                >
                                                    <img style={{
                                                        width: '100%',
                                                        maxHeight: '250px',
                                                        objectFit: 'cover'
                                                    }} src={resource?.images[0]?.url || 'https://i.ibb.co/XtFJ5jM/image-123650291.jpg'} alt="resource" />
                                                </div>
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    width: '100%',
                                                    left: 0,
                                                    right: 0,
                                                    height: 'auto',
                                                    backgroundColor: colors.backgroundSecondary,
                                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.20)',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}>
                                                    <h4 style={{
                                                        color: colors.white,
                                                        fontSize: '1.5em',
                                                        zIndex: 1,
                                                        flex: 1,
                                                        padding: '1em',
                                                    }}>
                                                        {resource.title}
                                                    </h4>
                                                    <CallToAction
                                                        text={text?.resources?.readMore}
                                                        onClick={() => navigate(`/resources/details?id=${resource._id}`)}
                                                        style={{
                                                            fontSize: '1.5em',
                                                            padding: '1em',
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            zIndex: 1,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </ConditionalRender>
                </div>
            </ParallaxProvider>
        </StyledDiv>
    )
}

export default HomeLandingContainerTablet
