import request from '../request';

/**
 * get produccts inventory request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getFieldNames = async (companyId) => {
    let companyUrl;
    if (companyId) {
        companyUrl = `/${companyId}`;
    } else {
        companyUrl = '';
    }
    try {
        const { data, status } = await request('GET', `/fields${companyUrl}`);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get fields: ${error.message}`);
    }
};

export default getFieldNames;
