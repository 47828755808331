import React, { useState, useEffect, useMemo } from 'react';
import queryString from "query-string";

import moment from 'moment';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { getEmployee } from '../utils/calls'
import CallToAction from './CallToAction';
import MultiFields from './MultiFields';
import ProgressBar from './ProgressBar';
import ConditionalRender from './ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import {
    updateLogs,
    getWeeklyTimeLog,
    updateDailyLog
} from '../utils/calls';
import SearchInput from './Inputs/SearchInput';
import HoverPopup from './HoverPopup';
import TimeEditForm from './Inputs/TimeEditForm';
import ConfirmModal from './ConfirmModal';
import { isEmpty } from 'lodash';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';

const CalendarView = styled.div`
  display: flex;
  flex-direction: column;
  .buttons-container {
    button {
        background: ${props => props.theme === 'dark' ? colors.secondary : colors.black};
        svg {
            color: ${props => props.theme === 'dark' ? colors.black : colors.white};
        }
        padding: 1rem;
        &:hover {
            background: ${colors.lightGray};
            svg {
                color: ${colors.black};
            }
        }
    }
    button.next {
        margin-right: 1em;
    }
  }
`;

const WeekView = styled.div`
  display: flex;
`;

const DayColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colors.darkGray};
  &:last-child {
    border-right: none;
}
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
`;

const DateHeader = styled.div`
  text-align: center;
  padding: 10px;
  background-color: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lightGray};
  span {
      font-family: ${colors.openSans};
  }
  display flex;
  align-items: center;
  justify-content: center;
`;

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CalendarLogs = ({
    projects,
    timeLogs,
    transformTimesToEvents,
    getTitleFromMilliseconds,
    fetchTimeLogs,
    logsIsLoading,
    user,
    tasks
}) => {
    const { theme } = useTheme();
    const { text, formatDate } = useLanguage();
    const { notify } = useToast();
    const { isDesktop } = useScreenSize();

    const [employee, setEmployee] = useState({});

    const [selectedDay, setSelectedDay] = useState({});
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [openDailyLog, setOpenDailyLog] = useState({});

    const handleOpenDailyLog = (open, dayValue) => {
        if (open) {
            setOpenDailyLog(dayValue);
        } else {
            setOpenDailyLog({});
        }
    };

    const handleSaveDailyLog = async ({ dailyLog }) => {
        const { id } = queryString.parse(location.search);

        try {
            const res = await updateDailyLog(dailyLog, openDailyLog?.clockIn, id);
            if (res.status === 200) {
                handleOpenDailyLog(false, {});
                fetchTimeLogs(id)
                notify(text?.notificationsUI?.timeLog?.saved, 'success');
            } else {
                notify(text?.notificationsUI?.timeLog?.error, 'error')
                console.error(res.message)
                handleOpenDailyLog(false, {});
            }

        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    useEffect(() => {
        const { id } = queryString.parse(location.search);
        const fetchEmployeeData = async () => {

            try {
                setIsLoading(true);
                const res = await getEmployee(id);
                if (res.status === 200) {
                    setEmployee(res.data);
                    setIsLoading(false);
                }
            } catch (error) {
                setError(text?.logs?.validations?.notAvailable);
                setIsLoading(false);
                console.error(error);
            }
        };
        if (id) {
            fetchEmployeeData();
        } else {
            setEmployee({})
        }
    }, [location.search]);



    useEffect(() => {
        const { id } = queryString.parse(location.search);
        fetchTimeLogs(id)
    }, [location.search])

    const [isOpen, setIsOpen] = useState(false)
    const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('week'));

    const nextDay = () => {
        setCurrentDay(currentDay.clone().add(1, 'days'));
    };

    const prevDay = () => {
        setCurrentDay(currentDay.clone().subtract(1, 'days'));
    };

    const [weeklyTotalTime, setWeeklyTotalTime] = useState(0)
    useEffect(() => {
        const { id } = queryString.parse(location.search);

        const fetchWeeklyTime = async () => {
            try {
                const res = await getWeeklyTimeLog({
                    currentWeekStart,
                    id
                });
                if (res.status === 200) {
                    setWeeklyTotalTime(res.data?.totalTime)
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchWeeklyTime()

    }, [currentWeekStart, timeLogs])


    const openModal = (day, key) => {
        const { id } = queryString.parse(location.search);
        const filteredTasks = tasks?.filter((x) => !x.isArchived)?.map((task) => {
            return {
                id: task._id,
                ...task,
            }
        })

        // Combine tasks from `day.tasks` and the global `tasks` array
        const combinedTasks = [
            ...(day?.tasks?.map((task) => {
                // Enrich day tasks with taskProject details if missing
                return {
                    ...task,
                    taskProject: task.taskProject || {
                        projectName: task.projectName || 'Untitled Project',
                        adminEmployees: task.adminEmployees || [],
                        assignedEmployees: task.assignedEmployees || [],
                    },
                };
            }) || []),
            ...filteredTasks, // Include tasks from the global `tasks` array
        ];

        // Deduplicate tasks based on `taskId`, ensuring a fallback for undefined taskIds
        const uniqueTasks = combinedTasks.reduce((acc, task) => {
            const taskKey = task.taskId || task._id; // Fallback to `_id` if `taskId` is not defined
            if (taskKey && !acc[taskKey]) {
                acc[taskKey] = task;
            }
            return acc;
        }, {});

        const allTasks = Object.values(uniqueTasks).map((task) => {
            const projectName = task.taskProject?.projectName || task.projectName || 'Untitled Project';
            const taskTitle = task.taskTitle || 'Untitled Task';
            const timeSpent = day?.tasks?.find((t) => t.taskId === task.taskId)?.timeSpent || task.timeSpent || 0;

            return {
                ...task,
                id: task.taskId || task._id,
                projectName,
                taskTitle,
                timeSpent,
            };
        });


        // Reduce tasks into the accumulator
        const newObj = allTasks.reduce((acc, task) => {
            const { projectName, taskTitle, timeSpent, taskId, taskProject } = task;

            if (id) {
                if (
                    (
                        taskProject?.adminEmployees?.some((x) => x.id === user?.id) &&
                        (taskProject?.assignedEmployees?.some((x) => x.id === id) || taskProject?.adminEmployees?.some((x) => x.id === id))
                    ) ||
                    (user?.roleName === 'Admin' && taskProject?.assignedEmployees?.some((x) => x.id === id))
                ) {
                    acc[`${projectName} - ${taskTitle}`] = {
                        id: taskId || task._id,
                        taskTitle,
                        projectName,
                        totalTime: timeSpent, // Include totalTime even if it is 0
                    };
                }
            } else {
                if (
                    user?.roleName === 'Admin' ||
                    (
                        taskProject?.adminEmployees?.some((x) => x.id === user?.id) ||
                        taskProject?.assignedEmployees?.some((x) => x.id === user?.id)
                    )
                ) {
                    acc[`${projectName} - ${taskTitle}`] = {
                        id: taskId || task._id,
                        taskTitle,
                        projectName,
                        totalTime: timeSpent,
                    };
                }
            }
            return acc;
        }, {});

        // Create the day object
        const createDay = !day
            ? {
                tasks: {
                    ...newObj,
                },
                clockIn: key,
            }
            : { ...day, tasks: newObj };

        setIsOpen(!isOpen);
        setSelectedDay(createDay);
    };

    const closeModal = () => {
        setIsOpen(false)
        setSelectedDay({})
    }

    const onSubmit = async (values) => {
        const { id } = queryString.parse(location.search);

        // Transform the values into the desired format
        const logs = Object.keys(values)?.reduce((acc, key) => {
            const [type, taskId] = key.split("_"); // Use taskId instead of projectId
            if (!acc[taskId]) {
                acc[taskId] = { hours: 0, minutes: 0 };
            }
            if (type === "hours") {
                acc[taskId].hours += parseInt(values[key], 10);
            } else if (type === "minutes") {
                acc[taskId].minutes += parseInt(values[key], 10);
            }
            return acc;
        }, {});

        const logsArray = Object.entries(logs).map(([taskId, timeData]) => ({
            taskId,
            totalTime: (timeData.hours * 60 + timeData.minutes) * 60000,
        }));

        try {
            // Use logsArray for the update
            const res = await updateLogs(logsArray, selectedDay?.clockIn, id);
            if (res.status === 200) {
                closeModal();
                fetchTimeLogs(id);
                notify(text?.notificationsUI?.timeLog?.saved, "success");
            }
        } catch (error) {
            notify(`Error: ${error.message}`, "error");
            console.error(error);
        }
    };

    const [filteredSelectedDay, setFilteredSelectedDay] = useState(selectedDay);
    useMemo(() => {
        setFilteredSelectedDay(selectedDay);
    }, [selectedDay])
    const [query, setQuery] = useState('');

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setQuery(query);

        if (selectedDay?.projects) {
            const filteredProjects = Object.keys(selectedDay.projects)
                ?.filter(key => key.toLowerCase().includes(query))
                ?.reduce((result, key) => {
                    result[key] = selectedDay.projects[key];
                    return result;
                }, {});

            setFilteredSelectedDay(prevState => ({
                ...prevState,
                projects: filteredProjects
            }));
        } else {
            setFilteredSelectedDay(prevState => ({
                ...prevState,
                projects: {}
            }));
        }
    };

    const [currentDay, setCurrentDay] = useState(moment().startOf('day'));


    const renderDay = () => {
        const now = new Date();

        // Calculate yesterday's date
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);

        const yesterdaysTime = new Date(yesterday.getTime() - yesterday.getTimezoneOffset() * 60000)
            .toISOString()
            .split('.')[0] + 'Z';

        const currentDayDate = new Date(currentDay);
        const dayKey = new Date(Date.UTC(
            currentDayDate.getUTCFullYear(),
            currentDayDate.getUTCMonth(),
            currentDayDate.getUTCDate(),
            0, 0, 0, 0
        )).toISOString();

        const dayValues = transformTimesToEvents(timeLogs)[dayKey];
        const isRunning = dayValues?.isRunning;

        // Compare with yesterday's date instead of today's date
        const isFutureDate = currentDayDate > new Date(yesterdaysTime);

        return (
            <DayColumn key={currentDayDate.toISOString().split('T')[0]} theme={theme}>
                <DateHeader theme={theme}>
                    <div className="flex items-center">
                        <span className="mr-sm">
                            {currentDayDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                        </span>
                        <ConditionalRender renderIf={!isRunning && !isFutureDate}>
                            <HoverPopup
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    background: 'transparent',
                                    border: 'none',
                                    hoverBackground: 'transparent',
                                    padding: '0',
                                }}
                                placement={'right'}
                                id={`day-${currentDayDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' }).replace(/\//g, '-')}`}
                                text={text?.logs?.edit}
                            >
                                <FontAwesomeIcon
                                    onClick={() => openModal(dayValues, dayKey)}
                                    icon={faPencil}
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.black,
                                        cursor: 'pointer',
                                    }}
                                />
                            </HoverPopup>
                        </ConditionalRender>
                    </div>
                </DateHeader>

                <div className="wrapper">
                    <ConditionalRender renderIf={isEmpty(dayValues)}>
                        <p>{text?.logs?.notTimeLogged}</p>
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(dayValues)}>
                        <div>
                            <CallToAction
                                className='mb-md'
                                type="button"
                                onClick={() => handleOpenDailyLog(true, dayValues)}
                                text={text?.logs?.dailyLogs}
                                btnHeight={'25px'}
                                style={{
                                    width: '100%',
                                }}
                            />
                            <p>{text?.logs?.totalTimeLogged}</p>
                            <p>{dayValues?.totalTimeText}</p>
                        </div>

                        <ConditionalRender renderIf={!isEmpty(dayValues?.tasks)}>
                            <hr />
                            <div className="flex flex-column mt-md">
                                {dayValues?.tasks.map((task, index) => (
                                    <div key={task.taskId || index} className="mb-md">
                                        <label className="b align-self-start">
                                            {task.taskTitle} ({task.projectName})
                                        </label>
                                        <ProgressBar
                                            value={((task.timeSpent / dayValues.totalTime) * 100).toFixed(2)}
                                            maxValue={100}
                                        />
                                        <p>{task.taskTotalTimeText}</p>
                                        <hr />

                                    </div>
                                ))}
                            </div>

                            <ConfirmModal
                                text={text?.logs?.modal?.button}
                                toggle={() => handleOpenDailyLog(false, dayValues)}
                                isOpen={!isEmpty(openDailyLog) && openDailyLog?.clockIn === dayKey}
                                btnStyles={{
                                    color: colors.blue,
                                }}
                                onSubmit={handleSaveDailyLog}
                                isForm
                                width={isDesktop ? '50%' : '100%'}
                                height={isDesktop ? '50%' : '100%'}
                            >
                                <div className="editor w-100">
                                    <MultiFields
                                        name="dailyLog"
                                        component="textarea"
                                        label={`${text?.logs?.modal?.title} ${moment.utc(openDailyLog?.clockIn).format('MM/DD/YYYY')}`}
                                        block
                                        initialValue={openDailyLog?.dailyLog || ''}
                                        showLightColors
                                    />
                                </div>
                            </ConfirmModal>
                        </ConditionalRender>
                    </ConditionalRender>
                </div>
            </DayColumn>
        );
    };


    return (
        <ConditionalRender isLoading={logsIsLoading && isLoading} renderIf={true}>
            <ConditionalRender renderIf={!isEmpty(employee)}>
                <div className='flex items-center'>
                    <h3 className='flex'>
                        {`${employee.firstName} ${employee.lastName}`}
                    </h3>
                    <ConditionalRender renderIf={employee.roleName === 'Admin'}>
                        <p className='ml-md'>
                            {'Administator'}
                        </p>
                    </ConditionalRender>
                </div>
            </ConditionalRender>
            <CalendarView theme={theme}>
                <div className={'flex justify-end mb-md buttons-container mt-md'}>
                    <button className='next' onClick={prevDay}>
                        <FontAwesomeIcon
                            icon={faCaretLeft} style={{
                                cursor: 'pointer'
                            }}
                        />
                    </button>
                    <button className='prev' onClick={nextDay}>
                        <FontAwesomeIcon
                            icon={faCaretRight} style={{
                                cursor: 'pointer'
                            }}
                        />
                    </button>
                </div>
                <WeekView>
                    {/* {renderWeek()} */}
                    {renderDay()}
                </WeekView>

                <ConfirmModal
                    onSubmit={onSubmit}
                    isForm
                    text={text?.logs?.editForm?.button}
                    toggle={closeModal}
                    isOpen={isOpen}
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                    btnStyles={{
                        color: colors.blue,
                    }}
                >
                    <div className="flex flex-column w-100">
                        <p className='mb-md'>
                            {text?.logs?.editForm?.archivedInfo}
                        </p>
                        <SearchInput
                            className="mb-md mr-md"
                            value={query}
                            onChange={handleSearchChange}
                            placeHolder={text?.tasks?.home?.filters?.search}
                        />
                        <ConditionalRender renderIf={isEmpty(filteredSelectedDay?.tasks)}>
                            <h4 className="flex justify-center items-center">{text?.logs?.noTasks}</h4>
                        </ConditionalRender>
                        {Object.entries(filteredSelectedDay?.tasks || {}).map(([taskName, taskDetails]) => {
                            return (
                                <TimeEditForm
                                    key={taskDetails?.id}
                                    id={taskDetails?.id}
                                    initialTimeInMilliseconds={taskDetails?.totalTime}
                                    label={taskName}
                                    clockIn={selectedDay?.clockIn}
                                />
                            );
                        })}
                    </div>
                </ConfirmModal>
            </CalendarView>
        </ConditionalRender>
    );
};

export default CalendarLogs;
