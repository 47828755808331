import React, { useContext, useEffect, useState } from 'react';
import { TimerContext } from '../context/TimeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from './ConfirmModal';
import CallToAction from './CallToAction';
import { GlobalContext } from '../context/GlobalContext';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import classNames from 'classnames';
import MultiFields from './MultiFields';
import CreateNewTaskContainer from '../Tasks/CreateNewTaskContainer';
import {
    updateDailyLog,
    getTimesheetById,
    getTimesheetByTaskId
} from '../utils/calls';
import styled from 'styled-components';
import useScreenSize from '../context/useScreenSize';
import { Form, FormSpy } from 'react-final-form';
import ConditionalRender from './ConditionalRender';
import { isEmpty } from 'lodash';
import SelectInput from './SelectInput';

const StyledDiv = styled.div`
    display: ${props => props.isFlex ? 'flex' : 'block'};
    justify-content: space-between;
    items-center;
    margin-top: 1em;

    .TimeTracker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time {
            font-family: ${colors.roboto};
            margin-right: 1rem;
        }
    }
`


const TimeTracker = ({
    isFlex = true,
    projects,
    user,
    showLightColors
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useTheme();
    const { text, formatDate } = useLanguage();
    const { isDesktop } = useScreenSize();

    // Access the timer state and functions from the context
    const {
        isRunning,
        elapsedTime,
        handleStart,
        handlePause,
        projectId,
        setProjectId,
        timesheet,
        setTimesheet,
        fetchTimeLogs,
        taskId,
        setIsRunning,
        setElapsedTime,
        updateLocalStorage,
        initializeTimerState,
        setTaskId,
        tasksForTimeIsLoading,
        tasksForTime,
        getProjectTasksForTimeTracking,
    } = useContext(TimerContext);
    const { fetchProjectTasks, tasks, employees, handleGetProject, isProject } = useContext(GlobalContext);
    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });

    const [initialProject, setInitialProject] = useState();
    const [initialTask, setInitialTask] = useState();

    const [dailyLog, setDailyLog] = useState(timesheet?.dailyLog)
    const disabled = !projectId || !taskId

    useEffect(() => {
        if (projectId) {
            const project = projects?.find(p => p._id === projectId);
            if (project) {
                setInitialProject({
                    label: project?.projectName,
                    value: project?._id
                });

                // Fetch tasks for the selected project
                getProjectTasksForTimeTracking(projectId);
            }
        }
    }, [projectId, projects]);

    const [isRefreshing, setIsRefreshing] = useState(false);

    const refreshTimeTracker = () => {
        setIsRefreshing(true);
        sessionStorage.removeItem('timeTracker');
        if (user) {
            initializeTimerState();
            setIsRefreshing(false);
        }
    };

    const cleanUpTimerState = () => {
        if (user) {
            initializeTimerState();
        }
    };

    useEffect(() => {
        refreshTimeTracker();

        return () => cleanUpTimerState();
        // eslint-disable-next-line
    }, []);

    const handleButtonClick = () => {
        refreshTimeTracker();
    };

    const [addTaskIsOpen, setAddTaskIsOpen] = useState(false);

    const handleAddTaskToggle = () => {
        setAddTaskIsOpen(!addTaskIsOpen)
    }

    useEffect(() => {
        if (tasksForTime?.length && taskId) {
            const findTask = tasksForTime.find(t => t._id === taskId);
            if (findTask) {
                setInitialTask({
                    label: findTask?.taskTitle,
                    value: findTask?._id
                });
            }
        }
    }, [tasksForTime, taskId]);

    const handleFetchTimeSheet = async (taskId) => {
        const now = new Date();
        // Adjust to local time
        const todaysTime = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';
        try {
            const res = await getTimesheetByTaskId({ taskId, todaysTime });
            if (res.status === 200) {
                const findId = res?.data?.taskTimes?.find(t => t.taskId === taskId)
                if (findId) {
                    updateLocalStorage(
                        false,
                        findId?.timeSpent,
                        findId?.projectId,
                        res?.data?._id,
                        findId?.taskId
                    )
                    setElapsedTime(findId?.timeSpent)
                    setIsRunning(findId?.isRunning)
                } else {
                    setElapsedTime(0)
                    setIsRunning(false)
                }
            }
        } catch (error) {
            console.error('Error fetching timesheet:', error);
        }
    }

    useEffect(() => {
        setDailyLog(timesheet?.dailyLog)
    }, [timesheet?.dailyLog, projectId])

    const handleChange = (e) => {
        setProjectId(e?.value)
        setTaskId(null)
        getProjectTasksForTimeTracking(e?.value)
        setElapsedTime(0)
        setIsRunning(false)
    }

    const handleTaskChange = (e) => {
        setTaskId(e?.value)
        handleFetchTimeSheet(e?.value)
    }

    const handleSaveDailyLog = async ({ dailyLog }) => {
        try {
            const res = await updateDailyLog(dailyLog, timesheet?.clockIn);
            if (res.status === 200) {
                const { data, status } = await getTimesheetById(timesheet?._id);
                if (status === 200) {
                    setTimesheet(data)
                    setDailyLog(data.dailyLog)
                }
                handleOpenDailyLog();
                const isAdmin = projects?.some(project => project._id === projectId &&
                    (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin'));
                fetchTimeLogs(null, projectId, isAdmin)
            } else {
                console.error(res.message)
                handleOpenDailyLog()
            }

        } catch (error) {
            console.error(error)
        }
    }

    const handleOpenDailyLog = () => {
        setIsOpen(!isOpen)
    }

    const formatTime = (time) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(seconds).padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    };

    const iconColor = theme === 'light' ? colors.black : colors.secondary;

    return (
        <ConditionalRender renderIf={true} isLoading={isRefreshing}>
            <Form
                onSubmit={() => null}
                render={({ handleSubmit, form }) => (
                    <form className='w-100' onSubmit={handleSubmit}>
                        <div className='flex flex-column'>
                            <FontAwesomeIcon
                                icon={faRefresh}
                                style={{
                                    display: "flex",
                                    alignSelf: "flex-end",
                                    fontSize: '2em',
                                    color: colors.blue,
                                    cursor: 'pointer',
                                }}
                                onClick={handleButtonClick}
                            />
                            <p style={{
                                color: showLightColors ? colors.black : colors.white
                            }} className='mb-sm flex'>
                                {text?.logs?.selectProject}
                            </p>
                            <SelectInput
                                name='selectProject'
                                isClearable={false}
                                isValidNewOption={() => false}
                                isSearchable
                                initialValue={initialProject}
                                menuPlacement={'bottom'}
                                options={projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                    return {
                                        label: x?.projectName,
                                        value: x?._id
                                    }
                                })}
                                onChange={(e) => {
                                    handleChange(e)
                                    form.change('selectTask', null)
                                }}
                                disabled={isRunning}
                                style={{
                                    width: '100%'
                                }}
                            />
                            <FormSpy subscription={{ values: true }}>
                                {({ values }) => {
                                    const selectProject = values?.selectProject?.value;

                                    return (
                                        <ConditionalRender renderIf={!isEmpty(selectProject)}>
                                            <ConditionalRender isLoading={tasksForTimeIsLoading} renderIf={isEmpty(tasksForTime)}>
                                                <p className='mt-md'>
                                                    {
                                                        adminProjects?.some(project => project._id === selectProject && (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin')) ? text?.logs?.noTasks : text?.logs?.noTaskEmployee
                                                    }
                                                </p>
                                            </ConditionalRender>
                                            <ConditionalRender isLoading={tasksForTimeIsLoading} renderIf={isEmpty(tasksForTime)}>
                                                <ConditionalRender renderIf={
                                                    adminProjects?.some(project => project._id === selectProject && (project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin'))
                                                }>
                                                    <CallToAction
                                                        className='flex-one mt-md'
                                                        text={text?.tasks?.home?.createNew}
                                                        type='button'
                                                        onClick={() => handleAddTaskToggle()}
                                                    />
                                                    <ConditionalRender renderIf={addTaskIsOpen}>
                                                        <CreateNewTaskContainer
                                                            isOpen={addTaskIsOpen}
                                                            toggle={handleAddTaskToggle}
                                                            projects={projects}
                                                            adminProjects={adminProjects}
                                                            user={user}
                                                            employees={employees}
                                                            fetchProjectTasks={fetchProjectTasks}
                                                            isProject={isProject}
                                                            projectId={projectId}
                                                            tasks={tasks}
                                                            handleGetProject={handleGetProject}
                                                        />
                                                    </ConditionalRender>
                                                </ConditionalRender>
                                            </ConditionalRender>
                                            <ConditionalRender isLoading={tasksForTimeIsLoading} renderIf={!isEmpty(tasksForTime)}>
                                                <p style={{
                                                    color: showLightColors ? colors.black : colors.white
                                                }} className='mb-sm flex mt-md'>
                                                    {text?.logs?.selectTask}
                                                </p>
                                                <SelectInput
                                                    name='selectTask'
                                                    isClearable={false}
                                                    menuPlacement={'bottom'}
                                                    isValidNewOption={() => false}
                                                    isSearchable
                                                    initialValue={initialTask}
                                                    options={tasksForTime?.map((x) => {
                                                        return {
                                                            label: x?.taskTitle,
                                                            value: x?._id,
                                                            isDisabled: x?.isRunning
                                                        }
                                                    })}
                                                    onChange={(e) => handleTaskChange(e)}
                                                    disabled={isRunning}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                />
                                            </ConditionalRender>

                                        </ConditionalRender>
                                    )
                                }}
                            </FormSpy>
                            <StyledDiv isFlex={isFlex}>
                                <div className='w-100'>
                                    <div className='TimeTracker mb-md'>
                                        <p className={classNames({
                                            time: true,
                                            'black': showLightColors
                                        })}>
                                            {formatTime(elapsedTime)}
                                        </p>
                                        {!isRunning && (
                                            <FontAwesomeIcon
                                                onClick={() => !disabled && handleStart()}
                                                icon={faPlay}
                                                style={{
                                                    color: disabled ? colors.lightGray : iconColor,
                                                    fontSize: '2rem',
                                                    cursor: disabled ? 'default' : 'pointer'
                                                }}
                                            />
                                        )}
                                        {isRunning && (
                                            <FontAwesomeIcon
                                                onClick={!disabled && handlePause}
                                                icon={faStop}
                                                style={{
                                                    color: disabled ? colors.lightGray : iconColor,
                                                    fontSize: '2rem',
                                                    cursor: disabled ? 'default' : 'pointer'
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className='log mb-md'>
                                        <CallToAction
                                            type="button"
                                            onClick={handleOpenDailyLog}
                                            text={text?.timeTracker?.dailyLogTitle}
                                            style={{
                                                alignSelf: 'start'
                                            }}
                                            disabled={!timesheet}
                                        />
                                    </div>
                                </div>
                                <ConfirmModal
                                    text={text?.timeTracker?.modal?.button}
                                    toggle={() => setIsOpen(!isOpen)}
                                    isOpen={isOpen}
                                    btnStyles={{
                                        color: colors.blue
                                    }}
                                    projectId={projectId}
                                    onSubmit={handleSaveDailyLog}
                                    isForm
                                    width={isDesktop ? '50%' : '100%'}
                                    height={isDesktop ? '50%' : '100%'}
                                >
                                    <div className="editor w-100">
                                        <MultiFields
                                            name="dailyLog"
                                            component="textarea"
                                            label={`${text?.timeTracker?.modal?.dailyLog} ${formatDate(timesheet?.clockIn)}`}
                                            block
                                            initialValue={dailyLog}
                                        />
                                    </div>
                                </ConfirmModal>
                            </StyledDiv>
                        </div>
                    </form>
                )}
            />
        </ConditionalRender>
    );
};


export default TimeTracker;
