import React from 'react'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import classnames from 'classnames'


const StyledSelectableBox = styled.div`
        margin-top: 0.5em;
        padding: ${props => props.padding || '1em'};
        background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.white) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray)};
        p, label {
        color: ${(props) => props.theme === 'light' ? colors.black : colors.white};
        }
        transition: background-color 0.1s;
        &:hover {
                background-color: ${props => props.theme === 'dark' ? colors.backgroundOverlay : colors.lightGray};
            }

`

const SelectableBox = ({
    children,
    className,
    onClick,
    showLightColors = false,
    ...rest
}) => {
    const { theme } = useTheme();

    return (
        <StyledSelectableBox
            {...rest}
            theme={showLightColors ? 'dark' : theme}
            showLightColors={showLightColors}
            onClick={onClick && onClick}
            className={classnames(className, {
                'SelectableBox': true,
                'pointer': onClick,
            })}
        >
            {children}
        </StyledSelectableBox >
    )
}

export default SelectableBox