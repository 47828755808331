import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import CallToAction from '../Core/CallToAction'
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import SearchInput from '../Core/Inputs/SearchInput';
import SelectableBox from '../Core/SelectableBox';
import { useLanguage } from '../context/LanguageContext';
import PaginatedData from '../Core/PaginatedData';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlusCircle, faClock, faEllipsis, faPause, faCheck } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import HoverPopup from '../Core/HoverPopup';
import useScreenSize from '../context/useScreenSize';
import { useTheme } from '../context/ThemeContext';
import { status } from '../utils/helperFunctions';


const StyledDiv = styled.div`
    width: 100%;
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .menu_wrapper {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 2px solid ${(props) => props.theme === 'dark' ? colors.primary : colors.backgroundSecondary};
        padding-top: 1rem;
        .callToAction__wrapper {
            margin-top: 1em;
            width: 33.3%;
        }
    }
`


const LandingProjectContainer = ({
    projects,
    user,
    setCollapsed
}) => {
    const navigate = useNavigate();
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const { theme } = useTheme();

    const [projectList, setProjectList] = useState(projects);
    const [pendingProjectList, setPendingProjectList] = useState(projects);
    const [inProgressProjectList, setInProgressProjectList] = useState(projects);
    const [pausedProjectList, setPausedProjectList] = useState(projects);
    const [completedProjectList, setCompletedProjectList] = useState(projects);
    const [showFilters, setShowFilters] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [searchPendingQuery, setSearchPendingQuery] = useState('');
    const [searchInProgressQuery, setSearchInProgressQuery] = useState('');
    const [searchPausedQuery, setSearchPausedQuery] = useState('');
    const [searchCompletedQuery, setSearchCompletedQuery] = useState('');

    useEffect(() => {
        setPendingProjectList(
            projects?.filter(x => x?.projectStatus === 'pending')
        )
        setInProgressProjectList(
            projects?.filter(x => x?.projectStatus === 'in-progress')
        )
        setPausedProjectList(
            projects?.filter(x => x?.projectStatus === 'paused')
        )
        setCompletedProjectList(
            projects?.filter(x => x?.projectStatus === 'completed')
        )

    }, [projects])

    const pendingProjects = pendingProjectList?.filter(x => x?.projectStatus === 'pending');
    const inProgressProjects = inProgressProjectList?.filter(x => x?.projectStatus === 'in-progress');
    const pausedProjects = pausedProjectList?.filter(x => x?.projectStatus === 'paused');
    const completedProjects = completedProjectList?.filter(x => x?.projectStatus === 'completed');


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchPendingChange = (e) => {
        setSearchPendingQuery(e.target.value);
    }

    const handleSearchInProgressChange = (e) => {
        setSearchInProgressQuery(e.target.value);
    }

    const handleSearchPausedChange = (e) => {
        setSearchPausedQuery(e.target.value);
    }

    const handleSearchCompletedChange = (e) => {
        setSearchCompletedQuery(e.target.value);
    }

    const [projectStatus, setProjectStatus] = useState('');

    useEffect(() => {
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            const filteredProjects = projects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setProjectList(filteredProjects);
        } else {
            setProjectList(projects);
        }
    }, [searchQuery, projects]);

    useEffect(() => {
        if (searchPendingQuery) {
            const query = searchPendingQuery.toLowerCase();
            const filteredProjects = pendingProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setPendingProjectList(filteredProjects);
        } else {
            setPendingProjectList(projects);
        }

        if (searchInProgressQuery) {
            const query = searchInProgressQuery.toLowerCase();
            const filteredProjects = inProgressProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setInProgressProjectList(filteredProjects);
        } else {
            setInProgressProjectList(projects);
        }

        if (searchPausedQuery) {
            const query = searchPausedQuery.toLowerCase();
            const filteredProjects = pausedProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setPausedProjectList(filteredProjects);
        } else {
            setPausedProjectList(projects);
        }

        if (searchCompletedQuery) {
            const query = searchCompletedQuery.toLowerCase();
            const filteredProjects = completedProjects.filter((project) => {
                const projectName = `${project?.projectName}`.toLowerCase();
                return projectName.includes(query);
            });
            setCompletedProjectList(filteredProjects);
        } else {
            setCompletedProjectList(projects);
        }

    }, [projects, searchPendingQuery, searchInProgressQuery, searchPausedQuery, searchCompletedQuery]);

    return (
        <StyledDiv className='flex flex-column' theme={theme}>
            <div className='menu_wrapper'>
                <h4 className='mb-md'>{text?.projects?.details?.inventoryModal?.projects}</h4>
                <div className='flex items-center mb-sm w-100 flex-wrap'>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'pending')}>
                        <CallToAction
                            onClick={() => setProjectStatus('pending')}
                            text={status(text).find(x => x.value === 'pending')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faEllipsis}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'in-progress')}>
                        <CallToAction
                            onClick={() => setProjectStatus('in-progress')}
                            text={status(text).find(x => x.value === 'in-progress')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faClock}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'paused')}>
                        <CallToAction
                            onClick={() => setProjectStatus('paused')}
                            text={status(text).find(x => x.value === 'paused')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faPause}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'completed')}>
                        <CallToAction
                            onClick={() => setProjectStatus('completed')}
                            text={status(text).find(x => x.value === 'completed')?.label}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faCheck}
                                />
                            }
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                        <CallToAction
                            onClick={() => {
                                navigate('/project/create')
                                if (!isDesktop) {
                                    setCollapsed(true)
                                }
                            }}
                            text={text?.projects?.landing?.createNew}
                            hasIcon={
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.white : colors.blue,
                                        fontSize: '1.8em',
                                    }}
                                    icon={faPlusCircle}
                                />
                            }
                        />
                    </ConditionalRender>
                </div>
                <div className='flex flex-column align-start mt-md '>
                    <h5 className='mb-sm'>{text?.projects?.landing?.lastUpdated}</h5>
                    <ul className='w-100'>
                        {
                            projects
                                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by updatedAt date in descending order
                                ?.slice(0, 1)
                                ?.map?.((x) => {
                                    return (
                                        <NavLink
                                            className='w-100'
                                            key={x?.id}
                                            to={`/project/details?id=${x?.id}`}
                                            onClick={() => {
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                            }}>
                                            <SelectableBox
                                                padding='0.5em'
                                                key={x?._id}
                                            >
                                                {x?.projectName}
                                            </SelectableBox>
                                        </NavLink>
                                    )
                                })
                        }
                    </ul>
                </div>
            </div>

            <ConditionalRender renderIf={projectStatus === 'pending'}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setProjectStatus('')
                    }}
                    isOpen={projectStatus === 'pending'}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <div className='flex flex-column mt-md w-100'>
                        <h4 className='mb-md'>{text?.projects?.landing?.pending}</h4>
                        <div className='flex flex-column'>
                            <HoverPopup
                                style={{
                                    marginRight: '1rem',
                                    alignSelf: 'flex-start'
                                }}
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'}
                                id='toggle-filters-popup-employee'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>

                            <ConditionalRender renderIf={showFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        style={{
                                            marginBottom: '1rem'
                                        }}
                                        value={searchPendingQuery}
                                        onChange={handleSearchPendingChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />

                                </div>
                            </ConditionalRender>
                        </div>
                        <PaginatedData
                            className='mt-md'
                            data={pendingProjects}
                            itemsPerPage={5}
                            noDataText={text?.employees?.landing?.notFound}
                            ItemComponent={({ item }) => {
                                return (
                                    <SelectableBox
                                        className='flex justify-space-between'
                                        showLightColors
                                        onClick={() => {
                                            navigate(`/project/details?id=${item?.id}`)
                                            setSearchPendingQuery('')
                                            setProjectStatus('')
                                            if (!isDesktop) {
                                                setCollapsed(true)
                                            }
                                            setShowFilters(false)
                                        }}
                                    >
                                        <p>
                                            {item?.projectName}
                                        </p>
                                        <p>
                                            {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                        </p>
                                    </SelectableBox>
                                )
                            }}
                        />
                    </div>
                </ConfirmModal>
            </ConditionalRender>
            <ConditionalRender renderIf={projectStatus === 'in-progress'}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setProjectStatus('')
                    }}
                    isOpen={projectStatus === 'in-progress'}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <div className='flex flex-column mt-md w-100'>
                        <h4 className='mb-md'>{text?.projects?.landing?.inProgress}</h4>
                        <div className='flex flex-column'>
                            <HoverPopup
                                style={{
                                    marginRight: '1rem',
                                    alignSelf: 'flex-start'
                                }}
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'}
                                id='toggle-filters-popup-employee'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>

                            <ConditionalRender renderIf={showFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        style={{
                                            marginBottom: '1rem'
                                        }}
                                        value={searchInProgressQuery}
                                        onChange={handleSearchInProgressChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />

                                </div>
                            </ConditionalRender>
                        </div>
                        <PaginatedData
                            className='mt-md'
                            data={inProgressProjects}
                            itemsPerPage={5}
                            noDataText={text?.employees?.landing?.notFound}
                            ItemComponent={({ item }) => {
                                return (
                                    <SelectableBox
                                        className='flex justify-space-between'
                                        showLightColors
                                        onClick={() => {
                                            navigate(`/project/details?id=${item?.id}`)
                                            setSearchInProgressQuery('')
                                            setProjectStatus('')
                                            if (!isDesktop) {
                                                setCollapsed(true)
                                            }
                                            setShowFilters(false)
                                        }}
                                    >
                                        <p>
                                            {item?.projectName}
                                        </p>
                                        <p>
                                            {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                        </p>
                                    </SelectableBox>
                                )
                            }}
                        />
                    </div>
                </ConfirmModal>
            </ConditionalRender>
            <ConditionalRender renderIf={projectStatus === 'paused'}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setProjectStatus('')
                    }}
                    isOpen={projectStatus === 'paused'}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <div className='flex flex-column mt-md w-100'>
                        <h4 className='mb-md'>{text?.projects?.landing?.paused}</h4>
                        <div className='flex flex-column'>
                            <HoverPopup
                                style={{
                                    marginRight: '1rem',
                                    alignSelf: 'flex-start'
                                }}
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'}
                                id='toggle-filters-popup-employee'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>

                            <ConditionalRender renderIf={showFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        style={{
                                            marginBottom: '1rem'
                                        }}
                                        value={searchPausedQuery}
                                        onChange={handleSearchPausedChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />

                                </div>
                            </ConditionalRender>
                        </div>
                        <PaginatedData
                            className='mt-md'
                            data={pausedProjects}
                            itemsPerPage={5}
                            noDataText={text?.employees?.landing?.notFound}
                            ItemComponent={({ item }) => {
                                return (
                                    <SelectableBox
                                        className='flex justify-space-between'
                                        showLightColors
                                        onClick={() => {
                                            navigate(`/project/details?id=${item?.id}`)
                                            setSearchPausedQuery('')
                                            setProjectStatus('')
                                            if (!isDesktop) {
                                                setCollapsed(true)
                                            }
                                            setShowFilters(false)
                                        }}
                                    >
                                        <p>
                                            {item?.projectName}
                                        </p>
                                        <p>
                                            {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                        </p>
                                    </SelectableBox>
                                )
                            }}
                        />
                    </div>
                </ConfirmModal>
            </ConditionalRender>
            <ConditionalRender renderIf={projectStatus === 'completed'}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setProjectStatus('')
                    }}
                    isOpen={projectStatus === 'completed'}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <div className='flex flex-column mt-md w-100'>
                        <h4 className='mb-md'>{text?.projects?.landing?.completed}</h4>
                        <div className='flex flex-column'>
                            <HoverPopup
                                style={{
                                    marginRight: '1rem',
                                    alignSelf: 'flex-start'
                                }}
                                onClick={() => setShowFilters(!showFilters)}
                                placement={'top'}
                                id='toggle-filters-popup-employee'
                                text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                            >
                                <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                            </HoverPopup>

                            <ConditionalRender renderIf={showFilters}>
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        style={{
                                            marginBottom: '1rem'
                                        }}
                                        value={searchCompletedQuery}
                                        onChange={handleSearchCompletedChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />

                                </div>
                            </ConditionalRender>
                        </div>
                        <PaginatedData
                            className='mt-md'
                            data={completedProjects}
                            itemsPerPage={5}
                            noDataText={text?.employees?.landing?.notFound}
                            ItemComponent={({ item }) => {
                                return (
                                    <SelectableBox
                                        className='flex justify-space-between'
                                        showLightColors
                                        onClick={() => {
                                            navigate(`/project/details?id=${item?.id}`)
                                            setSearchCompletedQuery('')
                                            setProjectStatus('')
                                            if (!isDesktop) {
                                                setCollapsed(true)
                                            }
                                            setShowFilters(false)
                                        }}
                                    >
                                        <p>
                                            {item?.projectName}
                                        </p>
                                        <p>
                                            {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                        </p>
                                    </SelectableBox>
                                )
                            }}
                        />
                    </div>
                </ConfirmModal>
            </ConditionalRender>

            {/* <div className='flex flex-column'>
                <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                    <CallToAction onClick={() => {
                        navigate('/project/create')
                        if (!isDesktop) {
                            setCollapsed(true)
                        }
                    }}
                        style={{
                            alignSelf: 'center',
                            marginTop: '1rem',
                            display: 'flex',
                            width: '100%',
                        }}
                        text={text?.projects?.landing?.createNew}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={projects?.some((x) => x?.projectStatus === 'completed')}>
                    <CallToAction
                        className='mt-md'
                        onClick={() => setShowCompleted(!showCompleted)}
                        text={text?.projects?.landing?.showCompleted}
                        style={{
                            alignSelf: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                        btnStyle={{
                            color: 'white',
                        }}
                    />
                </ConditionalRender>
            </div> */}
            {/* <ConditionalRender renderIf={!isEmpty(activeProjects)}>
                <CallToAction
                    className='mt-md'
                    onClick={() => setShowInProgress(!showInProgress)}
                    text={text?.projects?.landing?.showInProgress}
                    style={{
                        alignSelf: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                    btnStyle={{
                        color: 'white',
                    }}
                />
                <div className='flex flex-column align-start mt-md '>
                    <h5 className='mb-sm'>{text?.projects?.landing?.lastUpdated}</h5>
                    <ul className='w-100'>
                        {
                            projects
                                ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by updatedAt date in descending order
                                ?.slice(0, 1)
                                ?.map?.((x) => {
                                    return (
                                        <NavLink
                                            className='w-100'
                                            key={x?.id}
                                            to={`/project/details?id=${x?.id}`}
                                            onClick={() => {
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                            }}>
                                            <SelectableBox
                                                padding='0.5em'
                                                key={x?._id}
                                            >
                                                {x?.projectName}
                                            </SelectableBox>
                                        </NavLink>
                                    )
                                })
                        }
                    </ul>
                </div>
            </ConditionalRender> */}




            {/* <ConditionalRender renderIf={showCompleted}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setShowCompleted(!showCompleted)
                        setSearchCompletedQuery('')
                        setShowFilters(false)
                    }}
                    isOpen={showCompleted}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <>
                        <div className='flex flex-column mt-md w-100'>
                            <h4 className='mb-md'>{text?.projects?.landing?.completed}</h4>
                            <div className='flex flex-column'>
                                <HoverPopup
                                    style={{
                                        marginRight: '1rem',
                                        alignSelf: 'flex-start'
                                    }}
                                    onClick={() => setShowFilters(!showFilters)}
                                    placement={'top'}
                                    id='toggle-filters-popup-employee'
                                    text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                                >
                                    <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                                </HoverPopup>

                                <ConditionalRender renderIf={showFilters}>
                                    <div className='flex flex-column filters mt-md'>
                                        <SearchInput
                                            style={{
                                                marginBottom: '1rem'
                                            }}
                                            value={searchCompletedQuery}
                                            onChange={handleSearchCompletedChange}
                                            placeHolder={text?.employees?.landing?.search}
                                        />

                                    </div>
                                </ConditionalRender>
                            </div>
                            <PaginatedData
                                className='mt-md'
                                data={completedProjects}
                                itemsPerPage={5}
                                noDataText={text?.employees?.landing?.notFound}
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className='flex justify-space-between'
                                            showLightColors
                                            onClick={() => {
                                                navigate(`/project/details?id=${item?.id}`)
                                                setShowCompleted(!showCompleted)
                                                setSearchCompletedQuery('')
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                                setShowFilters(false)
                                            }}
                                        >
                                            <p>
                                                {item?.projectName}
                                            </p>
                                            <p>
                                                {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                            </p>
                                        </SelectableBox>
                                    )
                                }}
                            />
                        </div>
                    </>
                </ConfirmModal>
            </ConditionalRender>

            <ConditionalRender renderIf={showInProgress}>
                <ConfirmModal
                    text={text?.inventory?.create?.modal?.button}
                    toggle={() => {
                        setShowInProgress(!showInProgress)
                        setSearchQuery('')
                        setShowFilters(false)
                    }}
                    isOpen={showInProgress}
                    height={isDesktop ? '80%' : '100%'}
                    width={isDesktop ? '80%' : '100%'}
                >
                    <>
                        <div className='flex flex-column mt-md w-100'>
                            <h4 className='mb-md'>{text?.projects?.landing?.showInProgress}</h4>
                            <div className='flex flex-column'>
                                <HoverPopup
                                    style={{
                                        marginRight: '1rem',
                                        alignSelf: 'flex-start'
                                    }}
                                    onClick={() => setShowFilters(!showFilters)}
                                    placement={'top'}
                                    id='toggle-filters-popup-employee'
                                    text={isDesktop && text?.inventory?.landing?.table?.showFilters}
                                >
                                    <FontAwesomeIcon icon={faFilter} style={{ color: showFilters ? colors.yellow : colors.white }} />
                                </HoverPopup>

                                <ConditionalRender renderIf={showFilters}>
                                    <div className='flex flex-column filters mt-md'>
                                        <SearchInput
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            placeHolder={text?.projects?.landing?.search}
                                        />

                                    </div>
                                </ConditionalRender>
                            </div>
                            <PaginatedData
                                className='mt-md'
                                data={activeProjects}
                                itemsPerPage={5}
                                noDataText={text?.employees?.landing?.notFound}
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className={'flex justify-space-between'}
                                            showLightColors
                                            onClick={() => {
                                                navigate(`/project/details?id=${item?.id}`)
                                                setShowInProgress(!showInProgress)
                                                setSearchQuery('')
                                                if (!isDesktop) {
                                                    setCollapsed(true)
                                                }
                                                setShowFilters(false)
                                            }}
                                        >
                                            <p>
                                                {item?.projectName}
                                            </p>
                                            <p>
                                                {moment(item?.updatedAt).format('MM/DD/YYYY')}
                                            </p>
                                        </SelectableBox>
                                    )
                                }}
                            />
                        </div>
                    </>
                </ConfirmModal>
            </ConditionalRender> */}
        </StyledDiv>
    )
}

export default LandingProjectContainer