import React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import HoverPopup from '../HoverPopup';
import { Link } from 'react-router-dom'
import ConditionalRender from '../ConditionalRender';
import { useTheme } from '../../context/ThemeContext'


import colors from '../../globalStyles.scss'

const StyledCheckBox = styled.div`
display: flex;
align-items: center;

input.form-check-input.custom-check {
    background-color: ${props =>
        props.checked ?
            (props.isDelete ? colors.red : (props.theme === 'dark' ? colors.backgroundSecondary : colors.black)) :
            (props.theme === 'dark' ? colors.backgroundSecondary : colors.black)};
    border-color: ${props => props.theme === 'dark' ? colors.white : colors.black};
    border-color: ${(props) => props.showLightColors && colors.white};
    margin: 0;
    margin-left: 5px;
    cursor: pointer;
    &:focus {
        box-shadow: none;
    }
    width: ${props => props?.checkStyle?.width || '15px'};
}
`

const CheckBox = ({
    label,
    checked,
    checkStyle,
    style,
    isDelete,
    goto,
    text,
    id,
    placement = 'right',
    disabledPopUp,
    className,
    showLightColors,
    ...custom
}) => {
    const navigate = useNavigate();
    const { theme } = useTheme();


    return (
        <StyledCheckBox
            checkStyle={checkStyle}
            style={style}
            checked={checked}
            isDelete={isDelete}
            className={className}
            theme={theme}
            showLightColors={showLightColors}
        >
            <Input
                type="checkbox"
                checked={checked}
                className='custom-check'
                {...custom}
            />
            <ConditionalRender renderIf={label && !goto}>
                <span style={{ marginLeft: '5px' }}>
                    <label htmlFor={id}>{label}</label>
                </span>
            </ConditionalRender>
            <ConditionalRender renderIf={label && goto}>
                <ConditionalRender renderIf={disabledPopUp}>
                    <Link to={goto} style={{ marginLeft: '5px' }}>
                        {label}
                    </Link>
                </ConditionalRender>
                <ConditionalRender renderIf={!disabledPopUp}>
                    <HoverPopup style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'transparent',
                        border: 'none',
                        hoverBackground: 'transparent',
                        hoverColor: colors.blue,
                        color: colors.blue
                    }}
                        placement={placement}
                        id={`checkbox_${id}`}
                        text={text}
                        onClick={() => navigate(goto)}
                    >
                        <label htmlFor={id}>{label}</label>
                    </HoverPopup>
                </ConditionalRender>
            </ConditionalRender>
        </StyledCheckBox >
    )
}

export default CheckBox
