import request from '../request';

/**
 * Sends a inventory request to the API.
 * @param {Object} clientId - The client data to send in the request.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const submitClientPinByEmail = async (email) => {
    try {
        const { data, status } = await request('POST', '/client/submit-pin-by-email', { email });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to submit pin to client: ${error.message}`);
    }
};

export default submitClientPinByEmail;
