import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import colors from '../globalStyles.scss';
import classnames from 'classnames';
import { useTheme } from '../context/ThemeContext'
import ConditionalRender from './ConditionalRender';


const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: ${(props) => props.wrapperWidth || 'auto'};
    button, a {
        cursor: pointer;
        min-height: ${(props) => props.btnHeight || '40px'};
        width: ${(props) => props.btnWidth || '100%'};
        background-color: ${props => props.disabled ? colors.lightGray : (props.theme === 'dark' ? (props?.style?.backgroundColor || colors.secondary) : (props.style?.backgroundColor || colors.blue))};
        border: none;
        color: ${props =>
        props.disabled ? colors.black :
            props.theme === 'dark' ? colors.black :
                (props.style?.color || colors.white)};
        font-size: ${(props) => props.btnStyle?.fontSize || '1em'};
        cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
        transition: background-color 0.3s;
        letter-spacing: 2px;
        font-family: ${colors.openSans};
        padding: ${(props) => props.btnStyle?.padding || '0 1em'};
        ...${(props) => props.btnStyle}
    }
    button:hover, a:hover {
        background-color: ${(props) => props.disabled ? colors.lightGray : (props.btnStyle?.hoverBackgroundColor || colors.darkGray)};
        box-shadow: 1px 2px 8px ${colors.lightBlue};
        color: ${(props) => props.btnStyle?.hoverColor || colors.black};
        color: ${(props) => props.disabled ? colors.white : (props.btnStyle?.hoverColor || colors.black)};
        color: ${props => props.theme === 'dark' ? colors.black : props.btnStyle?.hoverColor || colors.black};
        box-shadow: ${(props) => props.disabled ? 'none' : '1px 2px 8px ' + colors.lightBlue};
    }
    }

    ...${(props) => props.style}
`;

const CallToAction = ({
    disabled,
    text,
    type = 'button',
    onClick,
    style,
    btnStyle,
    btnHeight,
    btnWidth,
    className,
    wrapperClassName = 'callToAction__wrapper',
    linkTo,
    wrapperWidth,
    hasIcon
}) => {
    const { theme } = useTheme();

    return (
        <StyledButton
            className={classnames(wrapperClassName, {
                disabled: disabled
            })}
            btnWidth={btnWidth}
            btnStyle={btnStyle}
            style={style}
            disabled={disabled}
            btnHeight={btnHeight}
            theme={theme}
            wrapperWidth={wrapperWidth}
        >
            <ConditionalRender renderIf={!hasIcon}>
                {linkTo ? (
                    <Link to={linkTo} className={className} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {text}
                    </Link>
                ) : (
                    <button
                        className={className}
                        onClick={onClick && onClick}
                        type={type}
                        disabled={disabled ? disabled : null}
                    >
                        {text}
                    </button>
                )}
            </ConditionalRender>
            <ConditionalRender renderIf={hasIcon}>
                <div
                    className={`icon-button flex flex-column ${className}`}
                    type={type}
                    onClick={onClick && onClick}
                >
                    {hasIcon}
                    <p className='mt-sm' style={{
                        textAlign: 'center',
                        fontSize: '0.8em',
                        fontFamily: colors.openSans,
                    }}>
                        {text}
                    </p>
                </div>
            </ConditionalRender>
        </StyledButton>
    )
}

export default CallToAction;
