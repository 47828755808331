import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components'
import colors from '../globalStyles.scss'

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: ${colors.black};
  '&:before': {
    display: 'none'
  }
`;


const CustomAccordion = ({ items, panelOpen = false }) => {
    const [expanded, setExpanded] = React.useState(panelOpen);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {items.map((item, index) => (
                <StyledAccordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{
                            fontSize: '1.5em', // Change the size of the icon
                            color: '#000'
                        }} />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0, textAlign: 'left', fontWeight: 'bold', fontFamily: colors.roboto }}>
                            {item.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={{ textAlign: 'left' }}>
                            {item.body}
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
            ))
            }
        </div >
    );
};

export default CustomAccordion;
