import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from './ConditionalRender';
import styled from 'styled-components'
import { useLanguage } from '../context/LanguageContext'
import colors from '../globalStyles.scss'
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import CallToAction from './CallToAction';

const StyledDiv = styled.div`
.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1%;
    margin-top: 1em;

}
.image-wrapper {
    position: relative;
    padding: 0.5em;
    background: ${colors.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
        object-fit: cover;
        width: 100%;
        height: 200px;
        cursor: pointer;
    }
    .remove {
        position: absolute;
        top: 0;
        right: 0;
        background: ${colors.red};
        svg {
            color: ${colors.white};
            font-size: 1rem;
        }
    }

    /* Ensure all images maintain the same width */
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
        grid-column: span 1;
    }
}
`

const RenderImages = ({
    images,
    roleName,
    removeImage,
    disabled,
    openModal,
    closeModal,
    setActiveIndex,
    activeIndex,
    isModalOpen
}) => {
    const { text } = useLanguage();

    return (
        <StyledDiv>
            <div className='uploaded-images'>
                {images?.map((image, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => openModal(index)}
                            className='image-wrapper'
                        >
                            <img
                                src={image?.url}
                                alt={image?.originalName}
                                className='uploaded-image'
                            />
                            <ConditionalRender renderIf={!disabled}>
                                <button
                                    className='remove'
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeImage && removeImage(index);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faClose} />
                                </button>
                            </ConditionalRender>
                        </div>
                    )
                })}
            </div>


            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: colors.backgroundOverlay,
                    },
                    content: {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        outline: "none",
                        padding: "20px",
                        width: '100vw',
                        height: '100vh',
                        background: 'transparent',
                        display: 'flex',
                        flexDirection: 'column'
                    },
                }}
            >
                <Carousel
                    selectedItem={activeIndex}
                    showThumbs={false}
                    showStatus={false}
                    dynamicHeight={true}
                    onChange={(index) => setActiveIndex(index)}
                >
                    {images?.map((image, index) => (
                        <div key={index} style={{ height: '100%', width: '100%', textAlign: 'center' }}>
                            <img
                                src={image.url}
                                alt={`Uploaded ${index + 1}`}
                                style={{
                                    height: '80vh',
                                    width: '80vw',
                                    objectFit: 'contain'
                                }}
                            />
                        </div>
                    ))}
                </Carousel>
                <CallToAction
                    text={text?.modal?.close}
                    onClick={closeModal}
                    style={{
                        marginTop: '15px',
                        alignSelf: 'start'
                    }}
                />
            </Modal>
        </StyledDiv>
    )
}

export default RenderImages